export default {
    "name": "RegionHelperServiceOfferData",
    "kind": "HoudiniFragment",
    "hash": "420665e22344bec7532e4a92bd83055b3bbc1871eaaeee18984e10bc23603549",

    "raw": `fragment RegionHelperServiceOfferData on ServiceOffer {
  id
  active
  service {
    id
    name
  }
  regionId
  chargeMinimum
  chargeMinimum
  chargePerSquareFoot
  chargeFixed
  costPercent
  serviceId
  timeRange
  __typename
}
`,

    "rootType": "ServiceOffer",
    "stripVariables": [],

    "selection": {
        "fields": {
            "id": {
                "type": "PositiveInt",
                "keyRaw": "id",
                "visible": true
            },

            "active": {
                "type": "Boolean",
                "keyRaw": "active",
                "visible": true
            },

            "service": {
                "type": "Service",
                "keyRaw": "service",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "regionId": {
                "type": "PositiveInt",
                "keyRaw": "regionId",
                "visible": true
            },

            "chargeMinimum": {
                "type": "String",
                "keyRaw": "chargeMinimum",
                "visible": true
            },

            "chargePerSquareFoot": {
                "type": "String",
                "keyRaw": "chargePerSquareFoot",
                "visible": true
            },

            "chargeFixed": {
                "type": "String",
                "keyRaw": "chargeFixed",
                "nullable": true,
                "visible": true
            },

            "costPercent": {
                "type": "String",
                "keyRaw": "costPercent",
                "visible": true
            },

            "serviceId": {
                "type": "PositiveInt",
                "keyRaw": "serviceId",
                "visible": true
            },

            "timeRange": {
                "type": "String",
                "keyRaw": "timeRange",
                "visible": true
            },

            "__typename": {
                "type": "String",
                "keyRaw": "__typename",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    }
};

"HoudiniHash=465233c37eabbf33e94236b9bc0243aa93bdffc9e57ed547c14b4d1d21f2bee4";