import template from './customer.ractive.html'

//Ractive components
import makeButton from '@isoftdata/button'
import makeNavTabBar from '@isoftdata/nav-tab-bar-component'

export default ({ stateRouter }) => {
	stateRouter.addState({
		name: 'app.customer',
		route: 'customer',
		querystringParameters: [ 'residenceId', 'scheduleType' ],
		defaultChild: 'upcoming-orders',
		template: {
			template,
			components: {
				itButton: makeButton(),
				navTabBar: makeNavTabBar(stateRouter),
			},
		},
		resolve(_data, parameters) {
			return Promise.resolve({
				childStates: [
					{
						title: 'Upcoming',
						stateName: 'app.customer.upcoming-orders',
					},
					{
						title: 'Subscriptions',
						stateName: 'app.customer.recurring-order',
					},
					{
						title: 'History',
						stateName: 'app.customer.order-history',
					},
				],
				scheduleType: parameters.scheduleType,
			})
		},
		activate(_activateContext) {
			//const { domApi: ractive } = _activateContext
		},
	})
}
