import {
	graphql,
	LoadProviderAvailableServicesStore,
	LoadProviderUserRegionsStore,
	ProviderAvailableJobsClaimJobStore,
	type LoadProviderAvailableServices$result,
	type LoadProviderClaimableJobs$result,
	type LoadProviderUserRegions$result,
} from '$houdini'
import formatCityStateZip from './format/format-city-state-zip'
import { getLabelForMarker } from './google-maps-helper'
import LoadProviderJobsQuery from './graphql/provider-jobs-query'
import type { WritableDeep } from 'type-fest'

// const labels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'

export type AvailableJobsService = Exclude<LoadProviderAvailableServices$result['provider']['authorizedServices'], null>[number]['service']
export type AvailableJobsRegion = LoadProviderUserRegions$result['getUserRegions'][number]['region']
export type JobsWithmarkers = WritableDeep<FormattedJob> & { markerLabel: google.maps.MarkerLabel | string; position: { latitude: number | null; longitude: number | null } }

const providerAvailableJobsServicesQuery: LoadProviderAvailableServicesStore = graphql`
	query LoadProviderAvailableServices($providerId: PositiveInt!) {
		provider(id: $providerId) {
			authorizedServices {
				service {
					id
					name
					description
					active
					codeName
				}
			}
		}
	}
`

const providersAvailableRegionsQuery: LoadProviderUserRegionsStore = graphql`
	query LoadProviderUserRegions($userId: PositiveInt!) {
		getUserRegions(userId: $userId) {
			region {
				id
				name
			}
		}
	}
`

const providerAvailableJobsClaimJobMutation: ProviderAvailableJobsClaimJobStore = graphql`
	mutation ProviderAvailableJobsClaimJob($jobClaim: NewJobClaim!) {
		claimJob(jobClaim: $jobClaim) {
			scheduledAt
			job {
				id
				jobStatus
			}
		}
	}
`
export async function LoadProviderAvailableServicesHelper(providerId: number) {
	const { data } = await providerAvailableJobsServicesQuery.fetch({
		variables: {
			providerId,
		},
	})

	if (!data?.provider?.authorizedServices) {
		throw new Error('Failed to load provider services')
	}

	return data.provider.authorizedServices
}

export async function LoadProviderUserRegionsHelper(userId: number) {
	const { data } = await providersAvailableRegionsQuery.fetch({
		variables: {
			userId,
		},
	})

	if (!data?.getUserRegions) {
		throw new Error('Failed to load provider regions')
	}

	return data.getUserRegions
}

export async function claimJobHelper(jobId: number, scheduledAt: Date) {
	try {
		const { data } = await providerAvailableJobsClaimJobMutation.mutate({
			jobClaim: {
				jobId,
				scheduledAt,
			},
		})
		if (!data?.claimJob) {
			throw new Error('Failed to claim job')
		}
		return data.claimJob
	} catch (err) {
		console.error(err)
	}
}

export type FormattedJob = LoadProviderClaimableJobs$result['claimableJobs']['data'][0] & {
	residence: {
		formattedCityStateZip: string
	}
}

export function formatJobsForAvailableJobs(jobs: LoadProviderClaimableJobs$result['claimableJobs']['data']): FormattedJob[] {
	return (
		jobs.map(job => {
			return {
				...job,
				residence: {
					...job.residence,
					formattedCityStateZip: formatCityStateZip({
						city: job.residence.city,
						state: job.residence.state,
						zip: job.residence.zip,
					}),
				},
			}
		}) ?? []
	)
}

export async function getJobPins(jobs: LoadProviderClaimableJobs$result['claimableJobs']['data']) {
	const { AdvancedMarkerElement } = (await google.maps.importLibrary('marker')) as google.maps.MarkerLibrary // This is how google wants it done
	return jobs.map(job => {
		const lat = job.residence.latitude ?? 0
		const lng = job.residence.longitude ?? 0
		const marker = new AdvancedMarkerElement({
			position: new google.maps.LatLng(lat, lng),
		})
		return {
			id: job.id,
			marker,
			infoWindow: new google.maps.InfoWindow({
				content: job.residence.street,
			}),
			animation: null,
		}
	})
}

export function getJobsWithMarkers(jobs: FormattedJob[]): JobsWithmarkers[] {
	return (
		jobs.map((job, index) => {
			return {
				markerLabel: getLabelForMarker(index),
				position:
					job.residence.latitude && job.residence.longitude
						? {
								latitude: job.residence.latitude,
								longitude: job.residence.longitude,
						  }
						: {
								latitude: null,
								longitude: null,
						  },
				...job,
			}
		}) ?? []
	)
}

export async function loadFormattedJobsWithmarkers(filterByServiceIds: number[], filterByRegionIds: number[]) {
	const jobs = await LoadProviderJobsQuery({
		filter: {
			serviceId: filterByServiceIds,
			regionIds: filterByRegionIds,
		},
	})
	const formattedJobs = formatJobsForAvailableJobs(jobs)
	const jobsWithmarkers = getJobsWithMarkers(formattedJobs)
	return jobsWithmarkers
}
