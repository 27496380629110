import component from './Management.svelte'
import { graphql } from '$houdini'
import { AppContext } from 'types/common'
import { formatProviderData } from 'utility/provider-management-helper'

export default ({ mediator, stateRouter }: AppContext) => {
	stateRouter.addState({
		name: 'app.admin.provider.management',
		route: 'management',
		querystringParameters: [],
		defaultParameters: {},
		data: {},
		template: {
			svelte: true,
			component,
			options: {},
		},

		async resolve(_data, _parameters) {
			const pageNumber = 1
			const pageSize = 12

			const { data } = await providerManagementQuery.fetch({
				variables: {
					ProviderPagination: {
						pageNumber,
						pageSize,
					},
				},
			})
			if (!data) {
				throw new Error('No data')
			}

			const regions = data.regions.data
			const services = data.services.data
			const initialProviders = data.providers.data
			const providerPaginationInfo = data.providers.info ?? { totalItems: 0, totalPages: 1 }

			const providerCache = {
				pages: [
					{
						providers: formatProviderData(initialProviders),
						retrivalTime: new Date(),
					},
				],
			}

			return {
				regions,
				services,
				providerCache,
				providerPaginationInfo,
				pageSize,
			}
		},
	})
}

const providerManagementQuery = graphql(`
	query LoadProviderManagement($ProviderPagination: PaginatedInput) {
		regions(pagination: { pageNumber: 1, pageSize: 0 }) {
			data {
				id
				name
				status
			}
		}
		services(pagination: { pageNumber: 1, pageSize: 0 }) {
			data {
				id
				name
				codeName
				description
				active
			}
		}
		providers(pagination: $ProviderPagination) {
			data {
				id
				homeRegion {
					id
					name
					status
				}
				city
				country
				county
				state
				street
				zip
				profilePictureFile {
					id
					path
				}
				status
				latestOnboardingApplication {
					id
					status
				}
				userAccount {
					id
					firstName
					lastName
					fullName
					email
					created
					mobile
					status
					authorizedRegions {
						id
						name
						status
					}
				}
				authorizedServices {
					id
					service {
						id
						name
						codeName
						description
						active
					}
				}
			}
			info {
				totalItems
				totalPages
			}
		}
	}
`)
