<script lang="ts">
	import Button from '@isoftdata/svelte-button'
	import Table, { Td, type Column } from '@isoftdata/svelte-table'
	import DateRange from '@isoftdata/svelte-date-range'
	import Modal from '@isoftdata/svelte-modal'
	import Input from '@isoftdata/svelte-input'
	import Select from '@isoftdata/svelte-select'
	import { upsert } from '@isoftdata/utility-array'
	import {
		selectRegionWithProviders,
		saveRegionHelper,
		createNewRegionAreaHelper,
		loadRegionHelperSpecificRegion,
		type RegionWithMetricsAndProviders,
		type RegionWithMetrics,
		loadRegionsWithAllTimeData,
	} from 'utility/region-helper'
	import AreaTable from './AreaTable.svelte'
	import ServicesTable from './ServicesTable.svelte'
	import ProvidersTable from './ProvidersTable.svelte'
	import timeZoneList from 'utility/time-zone'
	import stateList from 'utility/states'
	import countryList from 'utility/countries'
	import ServiceOfferModal from './ServiceOfferModal.svelte'
	import type { WritableDeep } from 'type-fest'
	import type { rangeFromDates } from '@isoftdata/utility-date-time'
	import { toDisplay } from 'utility/format/format-percent'
	import formatCurrency from 'utility/format/format-currency'
	import { parseISO } from 'date-fns'
	import type { LoadRegionHelperServices$result } from '$houdini/index'

	export let dates: { from: string; to: string }
	export let range: ReturnType<typeof rangeFromDates> & undefined
	export let regions: WritableDeep<RegionWithMetrics[]> = []
	export let services: LoadRegionHelperServices$result['services']['data']

	const regionStatusList = [
		{ value: 'ACTIVE', label: 'Active' },
		{ value: 'INACTIVE', label: 'Inactive' },
	]

	let selectedRegion: RegionWithMetricsAndProviders | null = null
	let loadingRegionData = false
	let selectedRegionId: number | undefined = undefined

	let regionModalShow: boolean = false
	let editingRegion: boolean = false
	let regionModalRegionId: number | null = null
	let regionName: string = ''
	let regionDescription: string = ''
	let regionTimeZone: string | null = null
	let regionLatitude: number | null = null
	let regionLongitude: number | null = null
	let regionStatus: RegionWithMetrics['status'] = 'ACTIVE'
	let regionSourceRegion: RegionWithMetrics | null = null
	let regionCloningRegion: boolean = false
	let regionErrorMessage: string = ''

	let regionAreaModalShow: boolean = false
	let city: string = ''
	let zip: string = ''
	let state: string = 'NE'
	let country: string = 'US'
	let regionAreaLatitude: number | null = null
	let regionAreaLongitude: number | null = null
	let regionAreaDescription: string = ''
	let regionAreaRegionId: number | undefined = undefined
	let regionAreaErrorMessage: string = ''

	let serviceOfferShow: boolean = false
	let editingServiceOffer: boolean = false
	let serviceOfferId: number | undefined = undefined
	let chargePerSquareFoot: number | null = null
	let chargeMinimum: number | null = null
	let chargeFixed: number | null = null
	let timeRange: string = 'SunriseSunset'
	let serviceId: number | undefined = undefined
	let regionId: number | undefined = undefined
	let serviceOfferErrorMessage: string = ''

	let columns: Column[] = [
		{ property: 'name', name: 'Name' },
		{ property: 'description', name: 'Description' },
		{ property: 'regionMetricData[numberOfQuotes]', name: 'Quotes', title: 'Number of address quoted in the selected period', numeric: true },
		{ property: 'regionAllTimeMetricData[numberOfQuotes]', name: 'A.T. Quotes', title: 'Total number of address that can be quoted', numeric: true },
		{ property: 'regionMetricData[numberOfJobsCompleted]', name: 'Jobs', title: 'Number of jobs completed in the selected period', numeric: true },
		{ property: 'regionAllTimeMetricData[numberOfJobsCompleted]', name: 'A.T. Jobs', title: 'Cumulative number of jobs completed', numeric: true },
		{ property: 'regionMetricData[conversion]', name: 'Conversion', title: 'Percentage of parcels that have jobs in the selected period', numeric: true },
		{ property: 'regionAllTimeMetricData[conversion]', name: 'A.T. Conversion', title: 'Number of parcels that have jobs / Number of parcels created', numeric: true },
		{ property: 'regionMetricData[numberOfPerformingProviders]', name: 'Providers', title: 'Providers who completed at least one job in the selected period', numeric: true },
		{ property: 'regionMetricData[numberOfActiveProviders]', name: 'Active Providers', title: 'Total number of active providers', numeric: true },
		{ property: 'regionMetricData[revenue]', name: 'Revenue', title: 'Revenue collected in the selected period', sortType: 'ALPHA_NUM', numeric: true },
		{ property: 'regionAllTimeMetricData[revenue]', name: 'A.T. Revenue', title: 'Total revenue collected in the region lifetime', sortType: 'ALPHA_NUM', numeric: true },
		{ property: 'status', name: 'Status' },
	]

	async function selectRegion(id: number) {
		if (selectedRegion?.id === id) {
			selectedRegion = null
			selectedRegionId = undefined
		} else {
			selectedRegionId = id
			loadingRegionData = true
			try {
				selectedRegion = await selectRegionWithProviders(id, regions, dates)
			} catch (error) {
				// TODO: Show mediator toast later
				console.error(error)
			} finally {
				loadingRegionData = false
			}
		}
	}

	function openNewRegionModal(): void {
		regionModalShow = true
		editingRegion = false
		regionModalRegionId = null
		regionName = ''
		regionDescription = ''
		regionTimeZone = null
		regionLatitude = null
		regionLongitude = null
		regionStatus = 'ACTIVE'
		regionSourceRegion = null
		regionCloningRegion = false
	}

	function copyRegion(sourceRegion: RegionWithMetrics): void {
		regionModalShow = true
		editingRegion = false
		regionModalRegionId = null
		regionName = ''
		regionDescription = ''
		regionTimeZone = null
		regionLatitude = null
		regionLongitude = null
		regionStatus = 'ACTIVE'
		regionSourceRegion = sourceRegion
		regionCloningRegion = true
	}

	async function openEditRegionModal(regionId: number): Promise<void> {
		try {
			const region = await loadRegionHelperSpecificRegion(regionId)
			if (region) {
				regionModalShow = true
				editingRegion = true
				regionModalRegionId = region.id
				regionName = region.name
				regionDescription = region.description
				regionTimeZone = region.timeZone
				regionLatitude = region.latitude
				regionLongitude = region.longitude
				regionStatus = region.status
				regionSourceRegion = null
				regionCloningRegion = false
			}
		} catch (error: any) {
			// TODO: Show mediator toast later
			console.error(error)
		}
	}

	function addRegionArea(): void {
		regionAreaModalShow = true
		regionAreaRegionId = selectedRegionId
		city = ''
		zip = ''
		state = 'NE' // maybe don't default anymore
		country = 'US'
		regionAreaLatitude = null
		regionAreaLongitude = null
		regionAreaDescription = ''
	}

	function openAddServiceOfferModal(): void {
		serviceOfferShow = true
		editingServiceOffer = false
		serviceOfferId = undefined
		chargePerSquareFoot = null
		chargeMinimum = null
		chargeFixed = null
		timeRange = 'SunriseSunset'
		serviceId = undefined
		regionId = selectedRegionId
	}

	function serviceOfferSaved(e: CustomEvent): void {
		const regionToUpdate = regions.find(region => region.id === e.detail.regionId)
		if (regionToUpdate?.offeredServices) {
			regionToUpdate.offeredServices = upsert(regionToUpdate.offeredServices, 'id', e.detail)
			regions = regions
		}
	}

	async function saveRegion(): Promise<void> {
		try {
			const savedRegion = await saveRegionHelper({
				id: regionModalRegionId,
				name: regionName,
				editingRegion,
				description: regionDescription,
				timeZone: regionTimeZone,
				latitude: regionLatitude,
				longitude: regionLongitude,
				status: regionStatus,
				sourceRegion: regionSourceRegion,
				cloningRegion: regionCloningRegion,
			}, dates.from, dates.to)

			
			if (savedRegion) {
				const regionIndex = regions.findIndex(region => region.id === savedRegion.id)
				if (regionIndex > -1) {
					regions[regionIndex] = savedRegion
				} else {
					regions = [...regions, savedRegion]
				}
				await selectRegion(savedRegion.id)
				const regionRow = document.getElementById(`region-id-${savedRegion.id}`)
				if (regionRow) {
					regionRow.scrollIntoView({ behavior: 'smooth', block: 'center' })
				}
			}
		} catch(error: any) {
			regionErrorMessage = error.message
			console.error(error)
		} finally {
			regionModalShow = false
			editingRegion = false
			regionModalRegionId = null
			regionName = ''
			regionDescription = ''
			regionTimeZone = null
			regionLatitude = null
			regionLongitude = null
			regionStatus = 'ACTIVE'
			regionSourceRegion = null
			regionCloningRegion = false
		}
	}

	async function createNewRegionArea(): Promise<void> {
		try {
			const newRegionArea = await createNewRegionAreaHelper({
				regionId: regionAreaRegionId,
				city,
				zip,
				state,
				country,
				description: regionAreaDescription,
			})

			if (newRegionArea) {
				const selectedRegionIndex = regions.findIndex(region => region.id === selectedRegionId)
				if (selectedRegionIndex > -1 && regions[selectedRegionIndex].containedCities) {
					regions[selectedRegionIndex].containedCities = upsert(regions[selectedRegionIndex].containedCities, 'id', newRegionArea)
				}
				regions = regions
			}
		} catch (error: any) {
			regionAreaErrorMessage = error.message
			console.error(error)
		} finally {
			regionAreaModalShow = false
			city = ''
			zip = ''
			state = 'NE'
			country = 'US'
			regionAreaLatitude = null
			regionAreaLongitude = null
			regionAreaDescription = ''
		}
	}

	async function loadRegionData(): Promise<void> {
		selectedRegion = null
		selectedRegionId = undefined
		loadingRegionData = true
		try {
			regions = await loadRegionsWithAllTimeData(parseISO(dates.from), parseISO(dates.to))
		} catch (error) {
			// TODO: Show mediator toast later
			console.error(error)
		}
		loadingRegionData = false
	}
</script>

<div class="container-fluid mt-3">
	<div class="d-flex justify-content-end">
		<Button
			iconClass="plus"
			on:click={openNewRegionModal}
		>
			Add New Region</Button
		>
	</div>
	<Table
		responsive
		showFilterLabel
		{columns}
		rows={regions}
		filterEnabled={true}
		filterLabel="Filter Regions"
		let:row
	>
		<svelte:fragment slot="no-rows">
			<tr>
				<td colspan="13">
					<p>No regions found</p>
				</td>
			</tr>
		</svelte:fragment>
		<svelte:fragment slot="header">
			<div class="d-flex align-items-baseline">
				<DateRange
					rangeLabel="Analytics Date Range"
					colClass="col-12 col-sm-4 mb-1"
					bind:dates
					bind:range
					on:change={loadRegionData}
				/>
			</div>
		</svelte:fragment>
		<tr
			id="region-id-{row.id}"
			class="cursor-pointer"
			class:table-dark={selectedRegionId === row.id}
			on:click={() => {
				selectRegion(row.id)
			}}
		>
			<Td
				property="name"
				class="font-weight-bold">{row.name}</Td
			>
			<Td property="description">{row.description}</Td>
			<Td property="regionMetricData[numberOfQuotes]">{row.regionMetricData.numberOfQuotes}</Td>
			<Td property="regionAllTimeMetricData[numberOfQuotes]">{row.regionAllTimeMetricData?.numberOfQuotes ?? 0}</Td>
			<Td property="regionMetricData[numberOfJobsCompleted]">{row.regionMetricData.numberOfJobsCompleted}</Td>
			<Td property="regionAllTimeMetricData[numberOfJobsCompleted]">{row.regionAllTimeMetricData?.numberOfJobsCompleted ?? 0}</Td>
			<Td property="regionMetricData[conversion]">{toDisplay(row.regionMetricData.conversion)}%</Td>
			<Td property="regionAllTimeMetricData[conversion]">{row.regionAllTimeMetricData?.conversion ? `${toDisplay(row.regionAllTimeMetricData.conversion)}%` : '0%'}</Td>
			<Td property="regionMetricData[numberOfPerformingProviders]">{row.regionMetricData.numberOfPerformingProviders}</Td>
			<Td property="regionMetricData[numberOfActiveProviders]">{row.regionMetricData.numberOfActiveProviders}</Td>
			<Td property="regionMetricData[revenue]">{formatCurrency(row.regionMetricData.revenue)}</Td>
			<Td property="regionAllTimeMetricData[revenue]">{row.regionAllTimeMetricData?.revenue ? formatCurrency(row.regionAllTimeMetricData.revenue) : '$0'}</Td>
			<Td property="status">
				{#if row.status === 'ACTIVE'}
					<div class="text-success">{row.status}</div>
				{:else}
					<div class="text-danger">{row.status}</div>
				{/if}
			</Td>
		</tr>
		{#if selectedRegionId === row.id}
			{#if loadingRegionData}
				<div class="d-flex justify-content-left">
					<div
						class="spinner-border text-primary"
						role="status"
					>
						<span class="sr-only">Loading...</span>
					</div>
				</div>
			{:else}
				<tr>
					<td colspan={columns.length}>
						<div class="card">
							<div class="card-header d-flex justify-content-between py-1 px-2">
								<h5 class="mb-0">{row.name}</h5>
								<div>
									<Button
										iconClass="copy"
										size="xs"
										outline
										color="success"
										on:click={() => copyRegion(row)}
									>
										Create New Region with these Service Offers
									</Button>
									<Button
										iconClass="pen"
										size="xs"
										on:click={() => openEditRegionModal(row.id)}>Edit Region</Button
									>
								</div>
							</div>
							<div class="card-body p-2">
								<div class="card">
									<div class="card-header d-flex justify-content-between py-1 px-2">
										<h5 class="mb-0">Areas in this Region</h5>
										<Button
											iconClass="plus"
											size="xs"
											outline
											color="success"
											on:click={addRegionArea}>Add New Area</Button
										>
									</div>
									<div class="card-body p-0">
										<AreaTable containedCities={row.containedCities ?? []} />
									</div>
								</div>
								<div class="card mt-2">
									<div class="card-header d-flex justify-content-between py-1 px-2">
										<h5 class="mb-0">Services Offered</h5>
										<Button
											iconClass="plus"
											size="xs"
											outline
											color="success"
											on:click={openAddServiceOfferModal}>Add Service Offer</Button
										>
									</div>
									<div class="card-body p-0">
										<ServicesTable
											{services}
											bind:serviceOfferShow
											bind:editingServiceOffer
											bind:serviceId
											bind:serviceOfferId
											bind:chargePerSquareFoot
											bind:chargeMinimum
											bind:chargeFixed
											bind:timeRange
											bind:regionId
											bind:serviceOfferErrorMessage
											regionServices={row.offeredServices ?? []}
											selectedRegionId={row.id}
											on:serviceOfferSaved={serviceOfferSaved}
										/>
									</div>
								</div>
								<div class="card mt-2">
									<div class="card-header d-flex justify-content-between py-1 px-2">
										<h5>Providers in Region</h5>
									</div>
									<div class="card-body p-0">
										<ProvidersTable providers={selectedRegion?.providers ?? []} />
									</div>
								</div>
							</div>
						</div>
					</td>
				</tr>
			{/if}
		{/if}
	</Table>
</div>

<Modal
	bind:show={regionModalShow}
	title="Add New Region"
	cancelButtonText="Cancel"
	confirmButtonText={editingRegion ? 'Confirm' : 'Create Region'}
	confirmButtonDisabled={!regionName || !regionTimeZone || !regionLatitude || !regionLongitude}
	on:confirm={() => saveRegion()}
	on:close={() => (regionModalShow = false)}
>
	<div class="form-row">
		<div class="col-6">
			<Input
				label="Name"
				bind:value={regionName}
				type="text"
				required
				validation={{
					value: regionName
				}}
			/>
		</div>
		<div class="col-6">
			<Select
				label="Time-Zone"
				bind:value={regionTimeZone}
				showEmptyOption={false}
				required
				validation={{
					value: regionTimeZone
				}}
			>
				{#each timeZoneList as timeZone}
					<option value={timeZone}>{timeZone}</option>
				{/each}
			</Select>
		</div>
		<div class="col-6">
			<Input
				label="Latitude"
				bind:value={regionLatitude}
				type="number"
				required
				validation={{
					value: regionLatitude
				}}
			/>
		</div>
		<div class="col-6">
			<Input
				label="Longitude"
				bind:value={regionLongitude}
				type="number"
				required
				validation={{
					value: regionLongitude
				}}
			/>
		</div>
		<div class="col-12">
			<Input
				label="Description"
				bind:value={regionDescription}
				type="text"
			/>
		</div>
		<div class="col-6">
			<Select
				label="Region Status"
				bind:value={regionStatus}
				showEmptyOption={false}
			>
				{#each regionStatusList as status}
					<option value={status.value}>{status.label}</option>
				{/each}
			</Select>
		</div>
	</div>
	{#if regionErrorMessage}
		<span class="text-danger text-smaller font-weight-bold">{regionErrorMessage}</span>
	{/if}
</Modal>

<Modal
	bind:show={regionAreaModalShow}
	title="Add New Area"
	cancelButtonText="Cancel"
	confirmButtonText="Add Region Area"
	on:confirm={() => createNewRegionArea()}
	on:close={() => (regionAreaModalShow = false)}
>
	<div class="form-row">
		<div class="col-6">
			<Input
				label="City"
				bind:value={city}
				type="text"
			/>
		</div>
		<div class="col-6">
			<Input
				label="Zip Code"
				bind:value={zip}
				type="text"
			/>
		</div>
		<div class="col-6">
			<Select
				label="State"
				bind:value={state}
				showEmptyOption={false}
			>
				{#each stateList as state}
					<option value={state}>{state}</option>
				{/each}
			</Select>
		</div>
		<div class="col-6">
			<Select
				label="Country"
				bind:value={country}
				showEmptyOption={false}
			>
				{#each countryList as country}
					<option value={country}>{country}</option>
				{/each}
			</Select>
		</div>
		<div class="col-12">
			<Input
				label="Description"
				bind:value={regionAreaDescription}
				type="text"
			/>
		</div>
		<span class="text-info text-smaller">*The more fields are filled, the more specific the region area is.</span>
	</div>
	{#if regionAreaErrorMessage}
		<span class="text-danger text-smaller font-weight-bold">{regionAreaErrorMessage}</span>
	{/if}
</Modal>

<ServiceOfferModal
	bind:show={serviceOfferShow}
	bind:editingService={editingServiceOffer}
	bind:serviceId={serviceId}
	bind:serviceOfferId={serviceOfferId}
	bind:chargePerSquareFoot={chargePerSquareFoot}
	bind:chargeMinimum={chargeMinimum}
	bind:chargeFixed={chargeFixed}
	bind:timeRange={timeRange}
	bind:regionId={regionId}
	bind:serviceOfferErrorMessage={serviceOfferErrorMessage}
	{services}
	on:serviceOfferSaved={serviceOfferSaved}
/>
