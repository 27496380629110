import template from './account.ractive.html'
import makeNavTabBar from '@isoftdata/nav-tab-bar-component'

export default ({ stateRouter }) => {
	stateRouter.addState({
		name: 'app.account',
		route: 'account',
		defaultChild: 'profile',
		template: {
			template,
			components: {
				navTabBar: makeNavTabBar(stateRouter),
			},
		},
		resolve(_data, _parameters) {
			const session = JSON.parse(localStorage.getItem('session'))

			const childStates = [
				{
					title: 'Profile',
					stateName: 'app.account.profile',
				},
				{
					title: 'Notifications',
					stateName: 'app.account.notifications',
				},
			]

			if (session?.customer) {
				childStates.push({
					title: 'Payment Methods',
					stateName: 'app.account.payment-method',
				})
			}

			return Promise.resolve({
				childStates,
			})
		},
	})
}
