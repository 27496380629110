import { datesFromRange, rangeFromDates } from '@isoftdata/utility-date-time'
import component from './Region.svelte'

import { AppContext } from 'types/common'
import { loadRegionHelperServices, loadRegionsWithAllTimeData } from 'utility/region-helper'
import { formatISO, parseISO } from 'date-fns'

export default function ({ stateRouter }: AppContext) {
	stateRouter.addState({
		name: 'app.admin.region',
		route: 'region',
		template: {
			component,
			svelte: true,
			options: {},
		},
		async resolve(_data, _parameters) {
			const from = parseISO(datesFromRange('Previous Month', true).from) ?? null
			const to = parseISO(datesFromRange('Previous Month', true).to) ?? null

			const services = await loadRegionHelperServices({
				pagination: {
					pageNumber: 1,
					pageSize: 0,
				},
			})

			const regionsWithMetrics = await loadRegionsWithAllTimeData(from, to)

			return Promise.resolve({
				regions: regionsWithMetrics,
				services,
				dates: {
					from: formatISO(from, { representation: 'date' }),
					to: formatISO(to, { representation: 'date' }),
				},
				range: rangeFromDates(from, to) || 'Custom',
			})
		},
	})
}
