import type { WritableDeep } from 'type-fest'
import { graphql, ProviderManagementProvidersQueryStore, type LoadProviderManagement$result, type ProviderManagementProvidersQuery$result, type ProviderStatus$options } from '$houdini'
import formatImageFileUrl from './format/format-image-file-url'

export type ProviderManagementService = LoadProviderManagement$result['services']['data'][0]

export type ProviderManagementRegion = LoadProviderManagement$result['regions']['data'][0]

export type ProviderManagementProvider = WritableDeep<
	LoadProviderManagement$result['providers']['data'][0] & {
		profilePictureFile: {
			id: number
			path: string
			url: string | null
		} | null
	}
>

export type ProviderManagementCache = {
	pages: {
		providers: ProviderManagementProvider[]
		retrivalTime: Date
	}[]
}

export const detachFileFromProviderManagementMutation = graphql(`
	mutation DetachFileFromProviderManagementMutationProviderManagement($providerId: PositiveInt!, $fileId: PositiveInt!) {
		detachFilesFromProvider(providerId: $providerId, fileId: $fileId)
	}
`)

export const updateProviderStatusMutation = graphql(`
	mutation UpdateProviderStatusMutationProviderManagement($providerId: PositiveInt!, $status: ProviderStatus!) {
		setProviderStatus(providerId: $providerId, status: $status) {
			id
		}
	}
`)

export const updateProviderRegionMutation = graphql(`
	mutation SetUserRegionPermission($userRegion: EditUserPermissions!) {
		setUserRegionPermission(userRegion: $userRegion)
	}
`)

export const updateProviderServiceStatusMutation = graphql(`
	mutation UpdateProviderServiceStatusMutationProviderManagement($certifiedServicesPermission: CertifiedServicesPermission!) {
		setProviderService(certifiedServicesPermission: $certifiedServicesPermission) {
			id
			status
			authorizedServices {
				id
				service {
					id
					name
					codeName
					description
					active
				}
			}
		}
	}
`)

export const providersDataQuery: ProviderManagementProvidersQueryStore = graphql(`
	query ProviderManagementProvidersQuery($pagination: PaginatedInput, $filter: ProviderFilter) {
		providers(pagination: $pagination, filter: $filter) {
			data {
				id
				homeRegion {
					id
					name
					status
				}
				city
				country
				county
				state
				street
				zip
				profilePictureFile {
					id
					path
				}
				status
				latestOnboardingApplication {
					id
					status
				}
				userAccount {
					id
					firstName
					lastName
					fullName
					email
					created
					mobile
					status
					authorizedRegions {
						id
						name
						status
					}
				}
				authorizedServices {
					id
					service {
						id
						name
						codeName
						description
						active
					}
				}
			}
			info {
				totalItems
				totalPages
			}
		}
	}
`)

export function formatProviderData(providers: LoadProviderManagement$result['providers']['data']): ProviderManagementProvider[] {
	return providers.map(provider => {
		return {
			...provider,
			profilePictureFile: provider.profilePictureFile
				? {
						id: provider.profilePictureFile.id,
						path: provider.profilePictureFile.path,
						url: formatImageFileUrl(provider.profilePictureFile.path),
				  }
				: null,
		}
	})
}

export async function getProviderData(
	pagination: { page: number; pageSize: number },
	filter: { status: ProviderStatus$options | null; regionId: number | null; serviceIds: number[] | null; search: string },
): Promise<ProviderManagementProvidersQuery$result> {
	const res = await providersDataQuery.fetch({
		variables: {
			pagination: {
				pageNumber: pagination.page,
				pageSize: pagination.pageSize,
			},
			filter: {
				status: filter.status,
				regionId: filter.regionId,
				serviceIds: filter.serviceIds,
				search: filter.search,
			},
		},
	})
	if (!res.data) {
		throw new Error('Failed to fetch provider data')
	}
	return res.data
}
