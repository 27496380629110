import component from './Profile.svelte'
import { getSession } from 'stores/session'

import { AppContext } from 'types/common'
import { getUserProfileSession } from 'utility/profile-helper'
import { loadCustomerResidences } from 'utility/graphql/load-customer-residences'
import type { CustomerResidencesQuery$result } from '$houdini/index'

export default ({ stateRouter }: AppContext) => {
	stateRouter.addState({
		name: 'app.account.profile',
		route: 'profile',
		template: {
			svelte: true,
			component,
			options: {},
		},
		async resolve(_data, _parameters) {
			const session = getSession()

			if (!session || !session.userAccountId) {
				stateRouter.go('login')
				return {}
			}

			const userAccount = await getUserProfileSession()

			let customerResidences: CustomerResidencesQuery$result['residences']['data'] = []

			if (session.customer) {
				customerResidences = (await loadCustomerResidences(session.userAccountId)) ?? []
			}

			return Promise.resolve({
				userAccount,
				isProvider: !!session.provider,
				isCustomer: !!session.customer,
				customerResidences,
			})
		},
	})
}
