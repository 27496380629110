import template from './order-created.ractive.html'
import formatCityStateZip from 'utility/format/format-city-state-zip'
import formatDateString from 'utility/format/format-date-string'
import formatCurrency from 'utility/format/format-currency'
import makeButton from '@isoftdata/button'

export default ({ mediator, stateRouter }) => {
	stateRouter.addState({
		name: 'app.order-created',
		route: 'order-created',
		querystringParameters: [ 'orderId' ],
		template: {
			template,
			components: {
				itButton: makeButton(),
			},
			formatCityStateZip,
			formatDateString,
			formatCurrency,
		},
		async resolve(_data, parameters) {
			const jobQuery = `#graphql
				query Job($jobId: PositiveInt!) {
					job(id: $jobId) {
						id
						residence {
							street
							city
							state
							zip
						}
						specialInstructions
						requestedSchedule
						service {
							name
						}
						transaction {
							total
						}
						answers {
							id
							answer
						}
					}
				}
			`

			const { job } = await mediator.call('apiFetch', jobQuery, { jobId: parameters.orderId })

			return {
				job,
			}
		},
		activate(_activateContext) {
			//const { domApi: ractive } = activateContext
		},
	})
}
