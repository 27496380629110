import type { WritableDeep } from 'type-fest'
import {
	graphql,
	LoadZonesStore,
	LoadRegionsStore,
	EditZoneStore,
	CreateZoneStore,
	LoadStatesStore,
	type LoadZones$result,
	type LoadRegions$result,
	type CreateZone$input,
	type EditZone$input,
	type LoadStates$result,
	type LoadZones$input,
	type LoadRegions$input,
} from '$houdini'

export type ZoneManagementZone = WritableDeep<LoadZones$result['zones']['data'][number]>

export type ZoneManagementRegion = WritableDeep<LoadRegions$result['regions']['data'][number]>

export type ZoneManagementState = WritableDeep<LoadStates$result['states']['data'][number]>

export type ZoneModalType = {
	show: boolean
	state: string | null
	name: string
	description: string | null
	selectedRegionIds: number[]
	editing: boolean
}

const loadZonesQuery: LoadZonesStore = graphql`
	query LoadZones($pagination: PaginatedInput, $filter: ZoneFilter) {
		zones(pagination: $pagination, filter: $filter) {
			data {
				id
				name
				state
				description
				regions {
					id
					name
				}
			}
		}
	}
`

const loadRegionsQuery: LoadRegionsStore = graphql`
	query LoadRegions($pagination: PaginatedInput) {
		regions(pagination: $pagination) {
			data {
				id
				name
				description
				latitude
				longitude
				timeZone
				status
				zoneId
			}
		}
	}
`

const loadStatesQuery: LoadStatesStore = graphql`
	query LoadStates {
		states {
			data {
				stateCode
				stateName
				stateAbbreviation
				country
			}
		}
	}
`

const newZoneMutation: CreateZoneStore = graphql`
	mutation CreateZone($newZone: NewZone!, $regionIds: [PositiveInt!]) {
		newZone(newZone: $newZone, regionIds: $regionIds) {
			id
			description
			name
			state
			regions {
				id
				name
			}
		}
	}
`

const editZoneMutation: EditZoneStore = graphql`
	mutation EditZone($updateZone: UpdateZone!, $regionIds: [PositiveInt!]) {
		updateZone(updateZone: $updateZone, regionIds: $regionIds) {
			id
			description
			name
			state
			regions {
				id
				name
			}
		}
	}
`

export async function loadZones(filter?: LoadZones$input['filter'], pagination?: LoadZones$input['pagination']) {
	const zones = await loadZonesQuery.fetch({
		variables: {
			filter,
			pagination,
		},
	})

	if (!zones.data?.zones.data) {
		return []
	}

	return zones.data.zones.data
}

export async function loadRegions(pagination?: LoadRegions$input['pagination']) {
	const regions = await loadRegionsQuery.fetch({
		variables: {
			pagination,
		},
	})

	if (!regions.data?.regions.data) {
		return []
	}

	return regions.data.regions.data
}

export async function loadStates() {
	const states = await loadStatesQuery.fetch()

	if (!states.data?.states.data) {
		return []
	}

	return states.data.states.data
}

export async function createZone(newZone: CreateZone$input) {
	const zone = await newZoneMutation.mutate(newZone)

	if (!zone.data?.newZone) {
		throw new Error('Failed to create zone')
	}

	return zone.data.newZone
}

export async function editZone(editZone: EditZone$input) {
	const zone = await editZoneMutation.mutate(editZone)

	if (!zone.data?.updateZone) {
		throw new Error('Failed to update zone')
	}

	return zone.data.updateZone
}
