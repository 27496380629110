import {
	graphql,
	LoadRegionHelperProvidersStore,
	LoadRegionHelperRegionsStore,
	MakeRegionStore,
	MakeServiceOfferStore,
	ChangeRegionStore,
	LoadAllTimeRegionStore,
	AddAreaToRegionAdminStore,
	ChangeServiceOfferStore,
	LoadRegionHelperServicesStore,
	UpdateServiceOfferStatusStore,
	LoadAllTimeRegionsStore,
	LoadRegionHelperSpecificRegionStore,
	LoadRegionHelperSpecificServiceOfferStore,
	type LoadRegionHelperProviders$input,
	type LoadRegionHelperRegions$input,
	type LoadRegionHelperRegions$result,
	type LoadRegionHelperServices$input,
	type LoadAllTimeRegions$input,
	type LoadRegionHelperProviders$result,
} from '$houdini'
import { parseISO } from 'date-fns'

export type RegionHelperRegion = LoadRegionHelperRegions$result['regions']['data'][number]
export interface RegionWithMetrics extends RegionHelperRegion {
	regionAllTimeMetricData?: RegionHelperRegion['regionMetricData']
}
export interface RegionWithMetricsAndProviders extends RegionWithMetrics {
	providers: LoadRegionHelperProviders$result['providers']['data']
}

graphql`
	fragment RegionHelperServiceOfferData on ServiceOffer {
		id
		active
		service {
			id
			name
		}
		regionId
		chargeMinimum
		chargeMinimum
		chargePerSquareFoot
		chargeFixed
		costPercent
		serviceId
		timeRange
	}
`

graphql`
	fragment RegionHelperRegionAreaData on RegionArea {
		id
		city
		state
		zip
		country
		description
	}
`

graphql`
	fragment RegionHelperRegionMetricData on RegionMetricData {
		numberOfActiveProviders
		numberOfQuotes
		numberOfJobsCompleted
		numberOfPerformingProviders
		conversion
		revenue
	}
`

const regionHelperProvidersQuery: LoadRegionHelperProvidersStore = graphql`
	query LoadRegionHelperProviders($filter: ProviderFilter, $startDate: Date, $endDate: Date, $regionId: PositiveInt) {
		providers(filter: $filter) {
			data {
				id
				profilePictureFile {
					id
					path
				}
				userAccount {
					email
					mobile
					status
					fullName
					authorizedRegions {
						id
					}
					lastLoginDate
				}
				providerMetricData(startDate: $startDate, endDate: $endDate, regionId: $regionId) {
					lastJobDate
					numberOfJobsCompleted
					numberOfJobsCompletedInRegion
					paidOut
					paidOutInRegion
				}
			}
		}
	}
`

const regionHelperNewRegionMutation: MakeRegionStore = graphql`
	mutation MakeRegion($newRegion: NewRegion!, $startDate: Date, $endDate: Date) {
		newRegion(newRegion: $newRegion) {
			id
			name
			description
			latitude
			longitude
			timeZone
			status
			containedCities {
				...RegionHelperRegionAreaData
			}
			offeredServices {
				...RegionHelperServiceOfferData
			}
			regionMetricData(startDate: $startDate, endDate: $endDate) {
				...RegionHelperRegionMetricData
			}
		}
	}
`

const regionHelperNewServiceOfferMutation: MakeServiceOfferStore = graphql`
	mutation MakeServiceOffer($newServiceOffer: NewServiceOffer!) {
		newServiceOffer(newServiceOffer: $newServiceOffer) {
			...RegionHelperServiceOfferData
		}
	}
`

const regionHelperChangeServiceOfferMutation: UpdateServiceOfferStatusStore = graphql`
	mutation UpdateServiceOfferStatus($changeServiceOfferStatus: ChangeServiceOfferStatus!) {
		changeServiceOfferStatus(changeServiceOfferStatus: $changeServiceOfferStatus) {
			...RegionHelperServiceOfferData
		}
	}
`

const regionHelperAllTimeRegionQuery: LoadRegionHelperRegionsStore = graphql`
	query LoadRegionHelperRegions($pagination: PaginatedInput, $startDate: Date, $endDate: Date) {
		regions(pagination: $pagination) {
			data {
				id
				name
				description
				latitude
				longitude
				timeZone
				status
				containedCities {
					...RegionHelperRegionAreaData
				}
				offeredServices {
					...RegionHelperServiceOfferData
				}
				regionMetricData(startDate: $startDate, endDate: $endDate) {
					...RegionHelperRegionMetricData
				}
			}
		}
	}
`

const editRegionMutation: ChangeRegionStore = graphql`
	mutation ChangeRegion($editRegion: EditRegion!, $startDate: Date, $endDate: Date) {
		editRegion(editRegion: $editRegion) {
			id
			name
			description
			latitude
			longitude
			timeZone
			status
			containedCities {
				...RegionHelperRegionAreaData
			}
			offeredServices {
				...RegionHelperServiceOfferData
			}
			regionMetricData(startDate: $startDate, endDate: $endDate) {
				...RegionHelperRegionMetricData
			}
		}
	}
`

const editServiceOfferMutation: ChangeServiceOfferStore = graphql`
	mutation ChangeServiceOffer($editServiceOffer: EditServiceOffer!) {
		editServiceOffer(editServiceOffer: $editServiceOffer) {
			...RegionHelperServiceOfferData
		}
	}
`

const addAreaToRegionMutation: AddAreaToRegionAdminStore = graphql`
	mutation AddAreaToRegionAdmin($newRegionArea: NewRegionArea!) {
		addAreaToRegion(newRegionArea: $newRegionArea) {
			...RegionHelperRegionAreaData
		}
	}
`

const regionHelperNewAllTimeRegionQuery: LoadAllTimeRegionStore = graphql`
	query LoadAllTimeRegion($regionId: PositiveInt!, $startDate: Date, $endDate: Date) {
		region(id: $regionId) {
			id
			name
			description
			latitude
			longitude
			timeZone
			status
			containedCities {
				...RegionHelperRegionAreaData
			}
			offeredServices {
				...RegionHelperServiceOfferData
			}
			regionMetricData(startDate: $startDate, endDate: $endDate) {
				...RegionHelperRegionMetricData
			}
		}
	}
`

const regionHelperNewAllTimeRegionsQuery: LoadAllTimeRegionsStore = graphql`
	query LoadAllTimeRegions($pagination: PaginatedInput, $startDate: Date, $endDate: Date) {
		regions(pagination: $pagination) {
			data {
				id
				name
				description
				latitude
				longitude
				timeZone
				status
				containedCities {
					...RegionHelperRegionAreaData
				}
				offeredServices {
					...RegionHelperServiceOfferData
				}
				regionMetricData(startDate: $startDate, endDate: $endDate) {
					...RegionHelperRegionMetricData
				}
			}
		}
	}
`

const regionHelperServicesQuery: LoadRegionHelperServicesStore = graphql`
	query LoadRegionHelperServices($pagination: PaginatedInput) {
		services(pagination: $pagination) {
			data {
				id
				name
			}
		}
	}
`

const regionHelperSpecificServiceOfferQuery: LoadRegionHelperSpecificServiceOfferStore = graphql`
	query LoadRegionHelperSpecificServiceOffer($serviceOfferId: PositiveInt!) {
		serviceOffer(id: $serviceOfferId) {
			...RegionHelperServiceOfferData
		}
	}
`

const regionHelperSpecificRegionQuery: LoadRegionHelperSpecificRegionStore = graphql`
	query LoadRegionHelperSpecificRegion($regionId: PositiveInt!) {
		region(id: $regionId) {
			id
			name
			description
			latitude
			longitude
			timeZone
			status
		}
	}
`

export async function loadRegionHelperSpecificServiceOffer(serviceOfferId: number) {
	const { data } = await regionHelperSpecificServiceOfferQuery.fetch({ variables: { serviceOfferId } })

	if (!data?.serviceOffer) {
		return null
	}

	return data.serviceOffer
}

export async function loadRegionHelperSpecificRegion(regionId: number) {
	const { data } = await regionHelperSpecificRegionQuery.fetch({ variables: { regionId } })

	if (!data?.region) {
		return null
	}

	return data.region
}

export async function loadRegionHelperProviders(variables: LoadRegionHelperProviders$input) {
	const { data } = await regionHelperProvidersQuery.fetch({ variables })

	if (!data?.providers.data) {
		return []
	}

	return data.providers.data
}

export async function loadRegions(variables: LoadRegionHelperRegions$input) {
	const { data } = await regionHelperAllTimeRegionQuery.fetch({ variables })

	if (!data?.regions.data) {
		return []
	}

	return data.regions.data
}

export async function loadRegionsAllTime(variables: LoadAllTimeRegions$input) {
	const { data } = await regionHelperNewAllTimeRegionsQuery.fetch({ variables })

	if (!data?.regions.data) {
		return []
	}

	return data.regions.data
}

export async function loadRegionHelperServices(variables: LoadRegionHelperServices$input) {
	const { data } = await regionHelperServicesQuery.fetch({ variables })

	if (!data?.services.data) {
		return []
	}

	return data.services.data
}

interface SaveRegionProps {
	id?: number | null
	name: string
	description: string
	latitude: number | null
	longitude: number | null
	timeZone: string | null
	status: RegionHelperRegion['status']
	editingRegion: boolean
	cloningRegion: boolean
	sourceRegion?: RegionHelperRegion | null
}

export async function saveRegionHelper(
	{ id, name, description, latitude, longitude, timeZone, status, editingRegion, cloningRegion, sourceRegion }: SaveRegionProps,
	startDate: string,
	endDate: string
) {
	try {
		const regionToSave = {
			name,
			description,
			latitude,
			longitude,
			timeZone: timeZone ?? null,
			status,
		}
		let savedRegion: RegionWithMetrics | undefined
		if (editingRegion && id) {
			const { data } = await editRegionMutation.mutate({
				editRegion: {
					id,
					...regionToSave,
				},
			})
			savedRegion = data?.editRegion
		} else {
			const { data } = await regionHelperNewRegionMutation.mutate({
				newRegion: regionToSave,
				startDate: parseISO(startDate),
				endDate: parseISO(endDate),
			})
			savedRegion = data?.newRegion
		}

		if (!savedRegion) {
			throw new Error('Failed to save region')
		}

		const { data } = await regionHelperNewAllTimeRegionQuery.fetch({
			variables: {
				regionId: savedRegion?.id,
				startDate: null,
				endDate: null,
			},
		})

		if (!data?.region) {
			throw new Error('Failed to get region metrics')
		}

		savedRegion = {
			...savedRegion,
			regionAllTimeMetricData: data?.region.regionMetricData,
		}

		// If we are cloning the region we also need to make the service offers

		if (cloningRegion && sourceRegion) {
			const clonedRegion = sourceRegion
			for (const serviceOffer of clonedRegion.offeredServices ?? []) {
				try {
					const { data } = await regionHelperNewServiceOfferMutation.mutate({
						newServiceOffer: {
							regionId: savedRegion?.id,
							serviceId: serviceOffer.serviceId,
							chargePerSquareFoot: serviceOffer.chargePerSquareFoot,
							chargeMinimum: serviceOffer.chargeMinimum,
							chargeFixed: serviceOffer.chargeFixed,
							timeRange: serviceOffer.timeRange,
							active: serviceOffer.active,
						},
					})

					if (!data?.newServiceOffer) {
						throw new Error('Failed to clone service offer')
					}

					savedRegion.offeredServices?.push(data.newServiceOffer)
				} catch (error) {
					console.error(error)
				}
			}
		}

		return savedRegion
	} catch (error) {
		console.error(error)
	}
}

interface CreateRegionAreaProps {
	city: string | null
	zip: string | number | null
	state: string
	country: string
	description: string
	regionId: number | undefined
}

export async function createNewRegionAreaHelper({ city, zip, state, country, description, regionId }: CreateRegionAreaProps) {
	try {
		if (!regionId) {
			throw new Error('Region ID is required')
		}
		const { data } = await addAreaToRegionMutation.mutate({
			newRegionArea: {
				city,
				zip: zip ? zip.toString() : null,
				state,
				country,
				regionId,
				description,
			},
		})

		if (!data?.addAreaToRegion) {
			throw new Error('Failed to add region area')
		}

		return data.addAreaToRegion
	} catch (error) {
		console.error(error)
	}
}

interface SaveServiceOfferProps {
	serviceId: number | undefined
	serviceOfferId: number | undefined
	chargePerSquareFoot: number | null
	chargeMinimum: number | null
	chargeFixed: number | null
	timeRange: string
	regionId: number | undefined
	editingService: boolean
}

export async function saveServiceOfferHelper({ serviceId, serviceOfferId, chargePerSquareFoot, chargeMinimum, chargeFixed, timeRange, regionId, editingService }: SaveServiceOfferProps) {
	if (!(!!serviceId && ((chargePerSquareFoot !== null && chargeMinimum !== null) || chargeFixed !== null))) {
		console.error('Missing required fields')
		return
	}
	if (editingService && serviceOfferId) {
		try {
			const { data } = await editServiceOfferMutation.mutate({
				editServiceOffer: {
					id: serviceOfferId,
					chargeMinimum: chargeMinimum ? `${chargeMinimum}` : '0.00',
					chargePerSquareFoot: chargePerSquareFoot ? `${chargePerSquareFoot}` : '0.00000',
					chargeFixed: chargeFixed ? `${chargeFixed}` : null,
					timeRange,
				},
			})

			if (!data?.editServiceOffer) {
				throw new Error('Failed to edit service offer')
			}

			return data.editServiceOffer
		} catch (error) {
			console.error(error)
		}
	} else {
		try {
			if (!regionId) {
				throw new Error('Region ID is required')
			}
			if (!serviceId) {
				throw new Error('Service ID is required')
			}
			const { data } = await regionHelperNewServiceOfferMutation.mutate({
				newServiceOffer: {
					regionId,
					serviceId,
					chargeMinimum: chargeMinimum ? `${chargeMinimum}` : '0.00',
					chargePerSquareFoot: chargePerSquareFoot ? `${chargePerSquareFoot}` : '0.00000',
					chargeFixed: chargeFixed ? `${chargeFixed}` : null,
					timeRange,
					active: true,
				},
			})

			if (!data?.newServiceOffer) {
				throw new Error('Failed to save service offer')
			}

			return data.newServiceOffer
		} catch (error) {
			console.error(error)
		}
	}
}

export async function updateServiceOfferStatus(id: number, active: boolean) {
	try {
		const { data } = await regionHelperChangeServiceOfferMutation.mutate({
			changeServiceOfferStatus: {
				id,
				active,
			},
		})

		if (!data?.changeServiceOfferStatus) {
			throw new Error('Failed to change service offer status')
		}

		return data.changeServiceOfferStatus
	} catch (error) {
		console.error(error)
	}
}

export async function selectRegionWithProviders(regionId: number, regions: RegionWithMetrics[], dates: { from: string; to: string }): Promise<RegionWithMetricsAndProviders | null> {
	const region = regions.find(region => region.id === regionId)
	if (!region) {
		return null
	}

	const providers: LoadRegionHelperProviders$result['providers']['data'] = await loadRegionHelperProviders({
		filter: {
			regionId,
		},
		regionId,
		startDate: dates.from ? parseISO(dates.from) : null,
		endDate: dates.to ? parseISO(dates.to) : null,
	})

	return {
		...region,
		providers,
	}
}

export async function loadRegionsWithAllTimeData(from: Date, to: Date) {
	const regions = await loadRegionsAllTime({
		pagination: {
			pageNumber: 1,
			pageSize: 0,
		},
		startDate: from,
		endDate: to,
	})

	if (!regions) {
		return []
	}

	const regionsAllTime = await loadRegionsAllTime({
		pagination: {
			pageNumber: 1,
			pageSize: 0,
		},
		startDate: from,
		endDate: to,
	})

	if (!regionsAllTime) {
		return []
	}

	const regionsWithMetrics = regions.map(region => {
		return {
			...region,
			regionAllTimeMetricData: regionsAllTime.find(allTimeRegion => allTimeRegion.id === region.id)?.regionMetricData,
		}
	})

	return regionsWithMetrics
}
