export default {
    "name": "LoadRegions",
    "kind": "HoudiniQuery",
    "hash": "a52fd78ee90ff071735cbdf7eee2397b2120689cc386ff31b919e93fcf03804e",

    "raw": `query LoadRegions($pagination: PaginatedInput) {
  regions(pagination: $pagination) {
    data {
      id
      name
      description
      latitude
      longitude
      timeZone
      status
      zoneId
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "regions": {
                "type": "RegionResponse",
                "keyRaw": "regions(pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Region",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "description": {
                                        "type": "String",
                                        "keyRaw": "description",
                                        "visible": true
                                    },

                                    "latitude": {
                                        "type": "Latitude",
                                        "keyRaw": "latitude",
                                        "visible": true
                                    },

                                    "longitude": {
                                        "type": "Longitude",
                                        "keyRaw": "longitude",
                                        "visible": true
                                    },

                                    "timeZone": {
                                        "type": "TimeZone",
                                        "keyRaw": "timeZone",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "status": {
                                        "type": "RegionStatus",
                                        "keyRaw": "status",
                                        "visible": true
                                    },

                                    "zoneId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "zoneId",
                                        "nullable": true,
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "pagination": "PaginatedInput"
        },

        "types": {
            "PaginatedInput": {
                "pageNumber": "PositiveInt",
                "pageSize": "NonNegativeInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=4210a70aeeccc228da4eff35e4c7566d0d85e5f098edb0e32ca27e3df4818884";