import template from './failed-payment-job.ractive.html'
import { getFailedPaymentJobs } from 'utility/admin-job-alerts'
import { stringToBoolean } from '@isoftdata/utility-string'
import { parseISO } from 'date-fns'

//Ractive components
import makeButton from '@isoftdata/button'
import makeTable from '@isoftdata/table'
import makeSelect from '@isoftdata/select'

const absoluteDateTimeFormatter = date => new Intl.DateTimeFormat('en-US', { dateStyle: 'short', timeStyle: 'short' }).format(date)

let jobStatusFilterList = [
	{ value: true, label: 'Resolved Jobs only' },
	{ value: false, label: 'Failed Jobs Only' },
	{ value: null, label: 'Failed & Resolved Jobs' },
]

export default function({ mediator, stateRouter }) {
	stateRouter.addState({
		name: 'app.admin.manage-jobs.failed-payment-job',
		route: 'failed-payment-job',
		querystringParameters: [ 'showResolvedJob' ],
		defaultParameters: {
			showResolvedJob: false,
		},
		template: {
			template,
			components: {
				itButton: makeButton(),
				itTable: makeTable(),
				itSelect: makeSelect({ twoway: true, lazy: false }),
			},
			data: {},
			computed: {
				computedJobs() {
					const failedPaymentJobs = this.get('jobs')
					return failedPaymentJobs.map(failedPaymentJob => {
						// we want to show the oldest transaction payment log at the top of the list
						const transactionPayments = failedPaymentJob?.transactionPayments.reverse().map(transactionPayment => {
							return {
								...transactionPayment,
								created: absoluteDateTimeFormatter(transactionPayment.created),
							}
						})
						return {
							...failedPaymentJob,
							transactionPayments,
							transactionId: failedPaymentJob?.id,
							jobId: failedPaymentJob?.job?.id,
							serviceName: failedPaymentJob?.job?.service?.name,
							customerName: failedPaymentJob?.customer?.userAccount?.fullName,
							customerEmail: failedPaymentJob?.customer?.userAccount?.email,
						}
					})
				},
			},
			async selectJob(jobId) {
				const selectedJobId = this.get('selectedJobId')
				await this.set('selectedJobId', selectedJobId === jobId ? null : jobId)
			},
			async resolveJob(transactionId) {
				const resolveTransactionStatusQuery = `#graphql
					mutation ToggleTransactionStatus($transactionId: PositiveInt!) {
						toggleTransactionStatus(transactionId: $transactionId) {
							id
							total
							paymentStatus
							invoiceStatus
							job {
								id
								service {
									name
								}
							}
							customer {
								userAccount {
									fullName
									email
								}
							}
							transactionPayments {
								created
								gatewayMessage
								transactionType
								transactionPaymentClassification
							}
						}
					}
				`

				try {
					const { toggleTransactionStatus } = await mediator.call('apiFetch', resolveTransactionStatusQuery, {
						transactionId,
					})
					this.upsert('jobs', 'id', toggleTransactionStatus)
					const failedJobCount = this.get('failedJobCount')
					this.get('adminJobAlertStore').update(store => {
						return {
							...store,
							failedJobCount: failedJobCount <= 0 ? 0 : failedJobCount - 1,
						}
					})
				} catch (err) {
					console.error(err)
				}
			},
		},
		async resolve(_data, parameters) {
			const getResolvedJobs = parameters?.showResolvedJob === null ? null : stringToBoolean(parameters?.showResolvedJob)
			const failedPaymentJobs = await getFailedPaymentJobs(getResolvedJobs)

			let noJobMessage = ''

			if (getResolvedJobs === null) {
				noJobMessage = 'failed or resolved'
			} else if (getResolvedJobs === true) {
				noJobMessage = 'resolved'
			} else {
				noJobMessage = 'failed'
			}

			return {
				jobs: failedPaymentJobs,
				selectedJobId: null,
				getResolvedJobs,
				jobStatusFilterList,
				noJobMessage,
			}
		},
		activate(activateContext) {
			const { domApi: ractive } = activateContext

			const failedJobUnsub = ractive.get('adminJobAlertStore').subscribe(newAdminJobAlert => {
				ractive.set('failedJobCount', newAdminJobAlert.failedJobCount)
			})

			ractive.observe('getResolvedJobs', getResolvedJobs => {
				stateRouter.go(null, { showResolvedJob: getResolvedJobs }, { inherit: true, replace: true })
			}, { init: false })

			activateContext.on('destroy', () => {
				failedJobUnsub?.()
			})
		},
	})
}
