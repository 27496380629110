<script lang="ts">
	import Table, { Td } from '@isoftdata/svelte-table'
	import Button from '@isoftdata/svelte-button'
	import AnswerTable from './AnswserTable.svelte'
	import Modal from '@isoftdata/svelte-modal'
	import Input from '@isoftdata/svelte-input'
	import Select from '@isoftdata/svelte-select'
	import TextArea from '@isoftdata/svelte-textarea'
	import toTitleCase from 'to-title-case'
	import { tagTypeList } from 'utility/tag-type-list'
	import type { WritableDeep } from 'type-fest'
	import { QuestionDataType, type ChangeQuestion$input, type LoadQuestions$result, type LoadTags$result } from '$houdini'
	import {
		loadTags,
		saveQuestion as saveQuestionHelper,
		deleteQuestion as deleteQuestionHelper,
		deleteAnswer as deleteAnswerHelper,
		saveAnswer as saveAnswerHelper,
		type Question,
		type Answer,
	} from 'utility/question-helper'
	import { getContext } from 'svelte'
	import type { Mediator } from 'types/common'

	const mediator = getContext<Mediator>('mediator')

	export let questions: WritableDeep<Question>[]

	let tags: LoadTags$result['tags']['data'] = []
	let selectedQuestionId: number | null = null

	const defaultQuestionDataType: ChangeQuestion$input['editQuestion']['questionDataType'] = 'CHOICE'

	let questionModalShow: boolean = false
	let questionModalEditing: boolean = false
	let question: string = ''
	let description: string = ''
	let questionId: number | null = null
	let questionDataType: ChangeQuestion$input['editQuestion']['questionDataType'] = defaultQuestionDataType
	let questionErrorMessage: string | null = null

	let answerModalShow: boolean = false
	let answerModalEditing: boolean = false
	let isMakingNewTag: boolean = true
	let answer: string = ''
	let answerId: number | undefined = undefined
	let tagCode: string | null = null
	let tagName: string | null = null
	let tagDescription: string = ''
	let tagPrompt: string = ''
	let tagEntityType: LoadTags$result['tags']['data'][number]['entityType'] = 'RESIDENCE'
	let tagOptionList: LoadTags$result['tags']['data'] = []
	let selectedTagId: number | null = null
	let answerErrorMessage: string | null = null

	function openQuestionModal() {
		questionModalShow = true
		questionModalEditing = false
		question = ''
		description = ''
		questionId = null
		questionDataType = defaultQuestionDataType
		questionErrorMessage = null
	}

	function openEditQuestionModal(oldQuestion: LoadQuestions$result['questions']['data'][number]) {
		questionModalShow = true
		questionModalEditing = true
		question = oldQuestion.question
		description = oldQuestion.description ?? ''
		questionId = oldQuestion.id
		questionDataType = oldQuestion.questionDataType
		questionErrorMessage = null
	}

	function getTagOptions(question: LoadQuestions$result['questions']['data'][number], tags: LoadTags$result['tags']['data'], editing: boolean) {
		return tags.reduce((acc, tag) => {
			if (editing) {
				if (tag.id === selectedTagId) {
					acc.push(tag)
					return acc
				}
			}
			if (question.answers?.some(answer => answer.tagId === tag.id)) {
				acc.push(tag)
			}

			return acc
		}, new Array<LoadTags$result['tags']['data'][number]>())
	}

	async function openAddAnswerModal(question: LoadQuestions$result['questions']['data'][number]) {
		tags = await loadTags(question)
		answerModalShow = true
		answerModalEditing = false
		isMakingNewTag = true
		answer = ''
		answerId = undefined
		tagCode = null
		tagName = null
		tagDescription = ''
		tagPrompt = ''
		tagEntityType = 'RESIDENCE'
		tagOptionList = getTagOptions(question, tags, false)
		selectedTagId = null
		answerErrorMessage = null
	}

	async function openEditAnswerModal(oldAnswer: Answer) {
		answerErrorMessage = null
		const question = questions.find(question => question.id === oldAnswer.questionId)
		if (!question) {
			answerErrorMessage = 'Question not found'
			answerModalShow = true // Show the error message
			throw new Error('Question not found')
		}
		tags = await loadTags(question)
		answerModalShow = true
		answerModalEditing = true
		isMakingNewTag = false
		answer = oldAnswer.answer
		answerId = oldAnswer.id
		tagCode = oldAnswer.tag.code
		tagName = oldAnswer.tag.name
		tagDescription = oldAnswer.tag.description ?? ''
		tagPrompt = oldAnswer.tag.prompt ?? ''
		tagEntityType = oldAnswer.tag.entityType
		tagOptionList = getTagOptions(question, tags, true)
		selectedTagId = oldAnswer.tagId
	}

	async function deleteQuestion(id: number, question: string) {
		if (confirm(`Are you sure you want to delete the question: ${question}`)) {
			try {
				await deleteQuestionHelper(id)

				const questionIndex = questions.findIndex(question => question.id === id)
				questions.splice(questionIndex, 1)
				questions = questions
			} catch (error: unknown) {
				mediator.call('showError', error as Error, { message: `Error deleting question "${question}"`, title: 'Error Deleting Question' })
			}
		}
	}

	async function deleteAnswer(answer: Exclude<LoadQuestions$result['questions']['data'][number]['answers'], null>[number]) {
		if (confirm(`Are you sure you want to delete the answer: ${answer.answer}`)) {
			try {
				await deleteAnswerHelper(answer.id)

				const question = questions.find(question => question.id === answer.questionId)
				if (question && question.answers) {
					const answerIndex = question.answers.findIndex(a => a.id === answer.id)
					question.answers.splice(answerIndex, 1)
					question.answers = question.answers
				}
				questions = questions
			} catch (error: unknown) {
				mediator.call('showError', error as Error, { message: `Error deleting answer "${answer.answer}"`, title: 'Error Deleting Answer' })
			}
		}
	}

	async function saveQuestion() {
		try {
			const savedQuestion = await saveQuestionHelper({
				questionId,
				question,
				description,
				questionDataType,
				editing: questionModalEditing,
			})

			if (questionModalEditing) {
				const index = questions.findIndex(question => question.id === savedQuestion.id)
				questions[index] = savedQuestion
			} else {
				questions.push(savedQuestion)
			}
			questions = questions
			questionModalShow = false
		} catch (error: unknown) {
			questionErrorMessage = (error as Error).message
		}
	}

	async function saveAnswer() {
		try {
			if (!selectedQuestionId) {
				throw new Error('No question selected')
			}
			const savedAnswer = await saveAnswerHelper(
				{
					answerId,
					editing: answerModalEditing,
					answer,
					selectedTagId,
					tagCode,
					tagName,
					tagDescription,
					tagPrompt,
					tagEntityType,
				},
				selectedQuestionId,
			)

			const questionIndex = questions.findIndex(question => question.id === selectedQuestionId)
			if (questionIndex === -1) {
				throw new Error('Question not found')
			}

			const question = questions[questionIndex]

			if (answerModalEditing) {
				if (question.answers?.length) {
					const answerIndex = question.answers.findIndex(answer => answer.id === savedAnswer.id)
					if (answerIndex === -1) {
						throw new Error('Answer not found')
					}
					question.answers[answerIndex] = savedAnswer
				}
			} else {
				question.answers?.push(savedAnswer)
			}

			questions[questionIndex] = question

			questions = questions

			answerModalShow = false
		} catch (error: unknown) {
			answerErrorMessage = (error as Error).message
		}
	}
</script>

<div class="container-flud mt-3">
	<div class="d-flex justify-content-end">
		<Button
			iconClass="plus"
			on:click={() => {
				openQuestionModal()
			}}
		>
			Add New Question</Button
		>
	</div>
	<Table
		responsive
		rows={questions}
		columns={[
			{ property: 'question', name: 'Question' },
			{ property: 'description', name: 'Description' },
			{ property: 'questionDataType', name: 'Type' },
			{ property: 'actions', name: '', sortType: false, align: 'right' },
		]}
		filterEnabled
		showFilterLabel
		filterLabel="Fileter Questions"
		let:row
	>
		<tr
			class="cursor-pointer"
			class:table-dark={row.id === selectedQuestionId}
			on:click={() => {
				if (selectedQuestionId === row.id) {
					selectedQuestionId = null
				} else {
					selectedQuestionId = row.id
				}
			}}
		>
			<Td property="question">{row.question}</Td>
			<Td property="description">{row.description}</Td>
			<Td property="questionDataType">{toTitleCase(row.questionDataType)}</Td>
			<Td
				property="actions"
				stopPropagation
			>
				<Button
					size="sm"
					color="primary"
					iconClass="pen"
					on:click={() => {
						openEditQuestionModal(row)
					}}
				></Button>
				<Button
					size="sm"
					color="danger"
					iconClass="trash"
					on:click={() => {
						deleteQuestion(row.id, row.question)
					}}
				></Button>
			</Td>
		</tr>
		{#if selectedQuestionId === row.id}
			<tr>
				<td colspan="4">
					<div class="card">
						<div class="card-header d-flex justify-content-between py-1 px-2">
							<h5>Answers</h5>
							<Button
								size="xs"
								color="primary"
								outline
								iconClass="plus"
								on:click={() => {
									openAddAnswerModal(row)
								}}
								disabled={row.questionDataType === 'BOOLEAN' && !!row.answers && row.answers.length > 0}
							>
								Add Answer</Button
							>
						</div>
						<div class="card-body p-2">
							{#if row.answers}
								<AnswerTable
									answers={row.answers}
									on:openEditAnswerModal={e => {
										openEditAnswerModal(e.detail)
									}}
									on:deleteAnswer={e => {
										deleteAnswer(e.detail)
									}}
								/>
							{:else}
								<div class="text-center">No Answers Found</div>
							{/if}
						</div>
					</div>
				</td>
			</tr>
		{/if}
	</Table>
</div>

<Modal
	bind:show={questionModalShow}
	title={questionModalEditing ? 'Update Question' : 'Add New Question'}
	cancelButtonText="Cancel"
	confirmButtonText={questionModalEditing ? 'Confirm' : 'Create Question'}
	confirmButtonDisabled={!question}
	on:confirm={saveQuestion}
	on:close={() => {
		questionModalShow = false
	}}
>
	<div class="form-row">
		<div class="col-12">
			<TextArea
				id="questionTextArea"
				label="Question"
				labelClass="py-0 mb-2"
				textAreaStyle="min-height: 83px;"
				placeholder="Write your question here..."
				bind:value={question}
			/>
		</div>
		<div class="col-12">
			<TextArea
				id="descriptionTextArea"
				label="Description"
				labelClass="py-0 mb-2"
				textAreaStyle="min-height: 83px;"
				placeholder="Information about this question(admin-only)..."
				bind:value={description}
			/>
		</div>
		<div class="col-12">
			<Select
				label="Question Data Type"
				bind:value={questionDataType}
				showEmptyOption={false}
			>
				{#each Object.keys(QuestionDataType) as questionDataType}
					<option value={questionDataType}>{toTitleCase(questionDataType)}</option>
				{/each}
			</Select>
		</div>
		{#if questionErrorMessage}
			<div
				style="opacity: 1;"
				class="signin-message-box text-center alert alert-danger"
				role="alert"
			>
				<i class="fas fa-exclamation-circle"></i> <strong style="font-size: x-small;">{questionErrorMessage}</strong>
			</div>
		{/if}
	</div>
</Modal>

<Modal
	bind:show={answerModalShow}
	title={answerModalEditing ? 'Update Answer' : 'Add New Answer'}
	cancelButtonText="Cancel"
	confirmButtonText="Save Answer"
	confirmButtonDisabled={!answer || (!selectedTagId && !(answer && tagCode && tagName))}
	on:confirm={saveAnswer}
	on:close={() => {
		answerModalShow = false
	}}
>
	<div class="form-row">
		<div class="col-12 col-sm-6">
			<Input
				id="answerInput"
				label="Answer Text"
				bind:value={answer}
				required
				validation={{
					value: answer,
				}}
			/>
		</div>
		<div class="col-12 col-sm-6">
			<Select
				label="Tag"
				bind:value={selectedTagId}
				emptyText="New Tag"
				showsEmptyOption
			>
				{#each tags as tag}
					<option value={tag.id}>{tag.name}</option>
				{/each}
			</Select>
		</div>
		{#if !selectedTagId}
			<div class="col-6">
				<Input
					id="tagCode"
					label="Tag Code"
					bind:value={tagCode}
					type="text"
					required
					validation={{
						value: tagCode,
					}}
				/>
			</div>
			<div class="col-6">
				<Input
					id="tagName"
					label="Tag Name"
					bind:value={tagName}
					type="text"
					required
					validation={{
						value: tagName,
					}}
				/>
			</div>
			<div class="col-12">
				<Input
					id="tagDescription"
					label="Tag Description"
					bind:value={tagDescription}
					type="text"
				/>
			</div>
			<div class="col-12">
				<TextArea
					id="promptTextArea"
					label="Prompt"
					labelClass="py-0 mb-2"
					textAreaStyle="min-height: 83px;"
					placeholder="A admin only description about the tag..."
					bind:value={tagPrompt}
				/>
			</div>
			<div class="col-6">
				<Select
					label="Tag Type"
					bind:value={tagEntityType}
					showEmptyOption={false}
					disabled={true}
				>
					{#each tagTypeList as tagType}
						<option value={tagType.value}>{tagType.label}</option>
					{/each}
				</Select>
			</div>
		{/if}
		{#if answerErrorMessage}
			<div
				style="opacity: 1;"
				class="signin-message-box text-center alert alert-danger"
				role="alert"
			>
				<i class="fas fa-exclamation-circle"></i> <strong style="font-size: x-small;">{answerErrorMessage}</strong>
			</div>
		{/if}
	</div>
</Modal>
