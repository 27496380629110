<script lang="ts">
	import Modal from '@isoftdata/svelte-modal'
	import Input from '@isoftdata/svelte-input'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import Select from '@isoftdata/svelte-select'
	import type { ZoneManagementRegion, ZoneManagementState, ZoneManagementZone, ZoneModalType } from 'utility/zones-helper'

	export let title = ''
	export let confirmButtonText = ''
	export let regions: ZoneManagementRegion[] = []
	export let states: ZoneManagementState[] = []
	export let zones: ZoneManagementZone[] = []

	export let zoneModal: ZoneModalType = {
		show: false,
		state: '',
		name: '',
		description: '',
		selectedRegionIds: new Array<number>(),
		editing: false,
	}
	let justOpened = false

	let initialSelectedRegionIds: Array<number> = []

	$: if (zoneModal.show && !justOpened) {
		initialSelectedRegionIds = zoneModal.selectedRegionIds
		justOpened = true
	} else if (!zoneModal.show) {
		justOpened = false
	}
</script>

<Modal
	bind:show={zoneModal.show}
	on:close={() => (zoneModal.show = false)}
	on:confirm
	confirmButtonDisabled={!zoneModal.state || !zoneModal.name}
	{title}
	{confirmButtonText}
	modalSize="lg"
>
	<div class="form-row">
		<div class="col-6">
			<Input
				autofocus
				labelProps={{
					required: true,
				}}
				label="Name"
				bind:value={zoneModal.name}
			/>
		</div>
		<div class="col-6">
			<Select
				label="State"
				bind:value={zoneModal.state}
			>
				{#each states as state}
					<option value={state.stateAbbreviation}>{state.stateName}</option>
				{/each}
			</Select>
		</div>
		<div class="col-12">
			<Input
				label="Description"
				bind:value={zoneModal.description}
			/>
		</div>
		<div class="col-12">
			<span class="font-weight-bold">Regions</span>
			<div class="text-danger">Regions already assigned to a zone will be reassigned if moved</div>
			{#if regions.length === 0}
				<p class="text-danger">No regions found</p>
			{/if}
			<div style="max-height: 500px; overflow-y: auto;">
				{#each regions as region}
					{@const assingedElsewhere = zoneModal.selectedRegionIds.includes(region.id)}
					<div class="d-flex justify-content-start">
						<Checkbox
							showLabel={false}
							checked={assingedElsewhere}
							disabled={initialSelectedRegionIds.includes(region.id) && zoneModal.editing}
							on:change={() => {
								if (assingedElsewhere) {
									zoneModal.selectedRegionIds = zoneModal.selectedRegionIds.filter(id => id !== region.id)
								} else {
									zoneModal.selectedRegionIds = [...zoneModal.selectedRegionIds, region.id]
								}
							}}
						/>
						{#if region.zoneId && !assingedElsewhere}
							{@const assignedZoneId = regions.find(r => r.zoneId === region.zoneId)?.zoneId}
							<span class="text-danger">[{region.name}] - Assigned to [{zones.find(z => z.id === assignedZoneId)?.name ?? 'Unknown'}]</span>
						{:else}
							<span>{region.name}</span>
						{/if}
					</div>
				{/each}
			</div>
		</div>
	</div>
</Modal>
