import template from './payment-method.ractive.html'
import { klona } from 'klona'

//Ractive components
import makeNewCardEntry from 'components/credit-card-entry'
import makeCardPicker from 'components/card-picker'
import makeButton from '@isoftdata/button'
import makeModal from '@isoftdata/modal'

const defaultCreditCardModalState = {
	updateCard: false,
	show: false,
	selectedCardId: null,
}

export default function({ mediator, stateRouter }) {
	stateRouter.addState({
		name: 'app.account.payment-method',
		route: 'payment-method',
		template: {
			template,
			components: {
				newCardEntry: makeNewCardEntry(),
				cardPicker: makeCardPicker(),
				itButton: makeButton(),
				itModal: makeModal(),
			},
			computed: {},
			async openCreditCardModal(updateCard, cardId) {
				if (updateCard) {
					await this.set({
						creditCardModal: {
							...klona(defaultCreditCardModalState),
							updateCard,
							selectedCardId: cardId,
							show: true,
						},
					})
				} else {
					await this.set({
						creditCardModal: {
							...klona(defaultCreditCardModalState),
							show: true,
						},
					})
				}
			},
			async addCardToWallet(cardInfo) {
				//They want to save the card to their wallet for future use
				const addCardToCustomerWalletMutation = `#graphql
					mutation AddCardToCustomerWallet($newCard: NewCustomerCard!) {
						addCardToCustomerWallet(newCard: $newCard) {
							id
							lastFour
							expirationMonth
							expirationYear
							cardType
						}
					}
				`

				try {
					const { addCardToCustomerWallet } = await mediator.call('apiFetch', addCardToCustomerWalletMutation, {
						newCard: {
							lastFour: parseInt(cardInfo.cardLast4, 10),
							expirationMonth: parseInt(cardInfo.expiryMonth, 10),
							expirationYear: parseInt(cardInfo.expiryYear, 10),
							token: cardInfo.token,
							cardType: cardInfo.cardType,
						},
					})

					this.push('cards', addCardToCustomerWallet)
					this.set({ creditCardModal: klona(defaultCreditCardModalState) })
				} catch (err) {
					console.error(err)
					alert(err?.message || 'An unknown error occurred while adding your card to your wallet. Please contact LawnHiro support.')
				}
			},
			async updateCardInWallet(cardInfo) {
				const editCardInCustomerWalletMutation = `#graphql
					mutation EditCardInCustomerWallet($cardInfo: editCustomerCard!) {
						editCardInCustomerWallet(cardInfo: $cardInfo) {
							id
							lastFour
							expirationMonth
							expirationYear
							cardType
						}
					}
				`
				const id = this.get('creditCardModal.selectedCardId')

				try {
					const { editCardInCustomerWallet } = await mediator.call('apiFetch', editCardInCustomerWalletMutation, {
						cardInfo: {
							lastFour: parseInt(cardInfo.cardLast4, 10),
							expirationMonth: parseInt(cardInfo.expiryMonth, 10),
							expirationYear: parseInt(cardInfo.expiryYear, 10),
							token: cardInfo.token,
							cardType: cardInfo.cardType,
							id,
						},
					})

					this.upsert('cards', 'id', editCardInCustomerWallet)
					this.set({ creditCardModal: klona(defaultCreditCardModalState) })
				} catch (err) {
					console.error(err)
					alert(err?.message || 'An unknown error occurred while adding your card to your wallet. Please contact LawnHiro support.')
				}
			},
		},
		async resolve() {
			const activeWalletQuery = `#graphql
				query ActiveWallet {
					session {
						customer {
							activeWallet {
								id
								lastFour
								expirationMonth
								expirationYear
								cardType
							}
						}
					}
				}
			`

			const activeWallet = await mediator.call('apiFetch', activeWalletQuery, {})
			return {
				cards: activeWallet?.session?.customer?.activeWallet || [],
				creditCardModal: klona(defaultCreditCardModalState),
			}
		},
		activate({ domApi: ractive }) {
			ractive.on('addCardToWalletTokenSuccess', async(_context, cardInfo) => {
				if (cardInfo.cardType === 'amex') {
					alert('American Express cards are not currently supported. Please use a Visa, Mastercard, or Discover card.')
				} else {
					await ractive.addCardToWallet(cardInfo)
				}
			})

			ractive.on('editCardInWalletTokenSuccess', async(_context, cardInfo) => {
				if (cardInfo.cardType === 'amex') {
					alert('American Express cards are not currently supported. Please use a Visa, Mastercard, or Discover card.')
				} else {
					await ractive.updateCardInWallet(cardInfo)
				}
			})

			ractive.on('addCardToWalletTokenError', (_context, error) => {
				alert(error?.message || 'An unknown error occurred while adding your card to your wallet. Please try again later.')
			})

			ractive.on('editCardInWalletTokenError', (_context, error) => {
				alert(error?.message || 'An unknown error occurred while editing your card in your wallet. Please try again later.')
			})

			ractive.on('deleteCard', async(_context, event, cardId) => {
				event?.stopPropagation()

				if (confirm('Are you sure you want to remove this card from your account?')) {
					const deactivateCardInCustomerWalletMutation = `#graphql
					mutation DeactivateCardInCustomerWallet($deactivateCardInCustomerWalletId: PositiveInt!) {
						deactivateCardInCustomerWallet(id: $deactivateCardInCustomerWalletId) {
							id
						}
					}
				`

					const { deactivateCardInCustomerWallet } = await mediator.call('apiFetch', deactivateCardInCustomerWalletMutation, { deactivateCardInCustomerWalletId: cardId })
					const cardIndex = ractive.get('cards').findIndex(card => card.id === deactivateCardInCustomerWallet.id)
					ractive.splice('cards', cardIndex, 1)
				}
			})

			ractive.on('editCard', (_context, _event, cardId) => {
				ractive.openCreditCardModal(true, cardId)
			})
		},
	})
}
