export default {
    "name": "EditZone",
    "kind": "HoudiniMutation",
    "hash": "5d28ad2f5dfe8273a9bf64b1aec204582adf13131f93573acf4a90ee631b521d",

    "raw": `mutation EditZone($updateZone: UpdateZone!, $regionIds: [PositiveInt!]) {
  updateZone(updateZone: $updateZone, regionIds: $regionIds) {
    id
    description
    name
    state
    regions {
      id
      name
    }
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "updateZone": {
                "type": "Zone",
                "keyRaw": "updateZone(regionIds: $regionIds, updateZone: $updateZone)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "description": {
                            "type": "String",
                            "keyRaw": "description",
                            "nullable": true,
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "state": {
                            "type": "String",
                            "keyRaw": "state",
                            "visible": true
                        },

                        "regions": {
                            "type": "Region",
                            "keyRaw": "regions",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "updateZone": "UpdateZone",
            "regionIds": "PositiveInt"
        },

        "types": {
            "UpdateZone": {
                "description": "String",
                "id": "PositiveInt",
                "name": "String",
                "state": "String"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=b5bd6c9ebd08ebc70db91dc9a2862b5a8921a07e013060753f10f57d4fc0bc42";