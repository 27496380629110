export default {
    "name": "CouponQuery",
    "kind": "HoudiniQuery",
    "hash": "4dc2906c65645b1698bdbd944a2e980134c0055963ac35d5420d058073474ad6",

    "raw": `query CouponQuery($filter: CouponFilter, $orderBy: [CouponOrderBy!]) {
  coupons(filter: $filter, orderBy: $orderBy) {
    id
    code
    description
    validFrom
    validTo
    numberOfTimesUsed
    maximumTimesUsed
    usesPerUser
    serviceId
    priceAdjustment
    isPercentage
    active
    firstJobOnly
    isValid
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "coupons": {
                "type": "Coupon",
                "keyRaw": "coupons(filter: $filter, orderBy: $orderBy)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "code": {
                            "type": "NonEmptyString",
                            "keyRaw": "code",
                            "visible": true
                        },

                        "description": {
                            "type": "String",
                            "keyRaw": "description",
                            "visible": true
                        },

                        "validFrom": {
                            "type": "DateTime",
                            "keyRaw": "validFrom",
                            "visible": true
                        },

                        "validTo": {
                            "type": "DateTime",
                            "keyRaw": "validTo",
                            "nullable": true,
                            "visible": true
                        },

                        "numberOfTimesUsed": {
                            "type": "NonNegativeInt",
                            "keyRaw": "numberOfTimesUsed",
                            "visible": true
                        },

                        "maximumTimesUsed": {
                            "type": "NonNegativeInt",
                            "keyRaw": "maximumTimesUsed",
                            "nullable": true,
                            "visible": true
                        },

                        "usesPerUser": {
                            "type": "NonNegativeInt",
                            "keyRaw": "usesPerUser",
                            "nullable": true,
                            "visible": true
                        },

                        "serviceId": {
                            "type": "NonNegativeInt",
                            "keyRaw": "serviceId",
                            "nullable": true,
                            "visible": true
                        },

                        "priceAdjustment": {
                            "type": "String",
                            "keyRaw": "priceAdjustment",
                            "visible": true
                        },

                        "isPercentage": {
                            "type": "Boolean",
                            "keyRaw": "isPercentage",
                            "visible": true
                        },

                        "active": {
                            "type": "Boolean",
                            "keyRaw": "active",
                            "visible": true
                        },

                        "firstJobOnly": {
                            "type": "Boolean",
                            "keyRaw": "firstJobOnly",
                            "visible": true
                        },

                        "isValid": {
                            "type": "Boolean",
                            "keyRaw": "isValid",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "filter": "CouponFilter",
            "orderBy": "CouponOrderBy"
        },

        "types": {
            "CouponFilter": {
                "active": "Boolean",
                "firstJobOnly": "Boolean",
                "isPercentage": "Boolean",
                "maximumTimesUsed": "NonNegativeInt",
                "numberOfTimesUsed": "NonNegativeInt",
                "priceAdjustment": "NonEmptyString",
                "serviceId": "NonNegativeInt",
                "valid": "Boolean",
                "validFrom": "DateTime",
                "validTo": "DateTime"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=0b44adb4a300439e825ff9d2410c184a5056f9c4a83fc1121512de680cb61a22";