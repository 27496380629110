export default {
    "name": "LoadRegionHelperProviders",
    "kind": "HoudiniQuery",
    "hash": "6b9dc3d22e3d9d5d25c91dfa141265dbbe98df2a517ff68f96ed2b8a4cddbe41",

    "raw": `query LoadRegionHelperProviders($filter: ProviderFilter, $startDate: Date, $endDate: Date, $regionId: PositiveInt) {
  providers(filter: $filter) {
    data {
      id
      profilePictureFile {
        id
        path
      }
      userAccount {
        email
        mobile
        status
        fullName
        authorizedRegions {
          id
        }
        lastLoginDate
        id
      }
      providerMetricData(
        startDate: $startDate
        endDate: $endDate
        regionId: $regionId
      ) {
        lastJobDate
        numberOfJobsCompleted
        numberOfJobsCompletedInRegion
        paidOut
        paidOutInRegion
      }
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "providers": {
                "type": "ProviderResponse",
                "keyRaw": "providers(filter: $filter)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Provider",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "profilePictureFile": {
                                        "type": "FileEntity",
                                        "keyRaw": "profilePictureFile",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "path": {
                                                    "type": "String",
                                                    "keyRaw": "path",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "userAccount": {
                                        "type": "UserAccount",
                                        "keyRaw": "userAccount",

                                        "selection": {
                                            "fields": {
                                                "email": {
                                                    "type": "EmailAddress",
                                                    "keyRaw": "email",
                                                    "visible": true
                                                },

                                                "mobile": {
                                                    "type": "PhoneNumber",
                                                    "keyRaw": "mobile",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "status": {
                                                    "type": "UserAccountStatus",
                                                    "keyRaw": "status",
                                                    "visible": true
                                                },

                                                "fullName": {
                                                    "type": "String",
                                                    "keyRaw": "fullName",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "authorizedRegions": {
                                                    "type": "Region",
                                                    "keyRaw": "authorizedRegions",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "lastLoginDate": {
                                                    "type": "Date",
                                                    "keyRaw": "lastLoginDate",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "providerMetricData": {
                                        "type": "ProviderMetricData",
                                        "keyRaw": "providerMetricData(endDate: $endDate, regionId: $regionId, startDate: $startDate)",

                                        "selection": {
                                            "fields": {
                                                "lastJobDate": {
                                                    "type": "Date",
                                                    "keyRaw": "lastJobDate",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "numberOfJobsCompleted": {
                                                    "type": "NonNegativeInt",
                                                    "keyRaw": "numberOfJobsCompleted",
                                                    "visible": true
                                                },

                                                "numberOfJobsCompletedInRegion": {
                                                    "type": "NonNegativeInt",
                                                    "keyRaw": "numberOfJobsCompletedInRegion",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "paidOut": {
                                                    "type": "String",
                                                    "keyRaw": "paidOut",
                                                    "visible": true
                                                },

                                                "paidOutInRegion": {
                                                    "type": "String",
                                                    "keyRaw": "paidOutInRegion",
                                                    "nullable": true,
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "filter": "ProviderFilter",
            "startDate": "Date",
            "endDate": "Date",
            "regionId": "PositiveInt"
        },

        "types": {
            "ProviderFilter": {
                "homeRegionIds": "PositiveInt",
                "regionId": "PositiveInt",
                "search": "String",
                "serviceIds": "PositiveInt",
                "status": "ProviderStatus"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=34e822d3140508b158073f23d9d8989750a319f4643b6d94651d927924ac9f34";