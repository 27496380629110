import template from './tag.ractive.html'
import formatCurrency from 'utility/format/format-currency'
import { toDisplay as percentToDisplay } from 'utility/format/format-percent'
import { toSave as percentToSave } from 'utility/format/format-percent'
import { klona } from 'klona'
import { tagPricingTypeList } from 'utility/tag-pricing-types'
import { tagTypeList } from 'utility/tag-type-list'

//Ractive components
import makeInput from '@isoftdata/input'
import makeTable from '@isoftdata/table'
import makeButton from '@isoftdata/button'
import makeModal from '@isoftdata/modal'
import makeSelect from '@isoftdata/select'
import makeCurrencyInput from '@isoftdata/currency-input'
import makeCheckbox from '@isoftdata/checkbox'
import makeTextArea from '@isoftdata/textarea'

const tagPricingField = `#graphql
	id
	priceAdjustment
	pricingType
	serviceId
	service {
		name
	}
	postPriceAdjustment
`

const defaultTagModalState = Object.freeze({
	code: null,
	name: null,
	description: '',
	prompt: '',
	entityType: 'RESIDENCE',
	show: false,
	errorMessage: '',
})

const defaultTagPricingModalState = Object.freeze({
	id: null,
	serviceId: '',
	priceType: 'FLAT',
	priceAdjustment: null,
	serviceTypesList: [],
	postPriceAdjustment: false,
	isEditing: false,
	show: false,
	errorMessage: '',
})

export default function({ mediator, stateRouter }) {
	stateRouter.addState({
		name: 'app.admin.tag',
		route: 'tag',
		template: {
			template,
			components: {
				itInput: makeInput({ twoway: true, lazy: false }),
				itTable: makeTable(),
				itButton: makeButton(),
				itModal: makeModal(),
				itSelect: makeSelect({ twoway: true, lazy: false }),
				itCurrencyInput: makeCurrencyInput({ twoway: true }),
				itCheckbox: makeCheckbox(),
				itTextArea: makeTextArea({ twoway: true, lazy: false }),
			},
			computed: {
				computedTagPricings() {
					const selectedTagId = this.get('selectedTagId')
					if (!selectedTagId) {
						return []
					}
					const tag = this.get('tags').find(tag => tag.id === selectedTagId)

					return tag.tagPricings.map(tagPricing => {
						return {
							...tagPricing,
							serviceName: tagPricing?.service?.name,
							formattedPrice: tagPricing?.pricingType === 'PERCENT' ? `${percentToDisplay(tagPricing?.priceAdjustment)}%` : formatCurrency(tagPricing?.priceAdjustment),
						}
					})
				},
			},
			selectTag(tagId) {
				const selectedTagId = this.get('selectedTagId')
				this.set('selectedTagId', selectedTagId === tagId ? null : tagId)
			},
			openTagModal() {
				this.set({
					tagModal: {
						...klona(defaultTagModalState),
						show: true,
					},
				})
				this.find('#tagCode')?.focus?.()
			},
			async openTagPricingModal(modalState = {}) {
				const selectedTagId = this.get('selectedTagId')
				const tag = this.get('tags').find(tag => tag.id === selectedTagId)
				const services = this.get('allServices')

				const availableServices = services.reduce((acc, service) => {
					const isServiceAlreadyAdded = tag?.tagPricings?.find(tagPricing => tagPricing.serviceId === service.id)
					if (!isServiceAlreadyAdded) {
						acc.push(service)
					}
					return acc
				}, [])

				await this.set({
					tagPricingModal: {
						...klona(defaultTagPricingModalState),
						...klona(modalState),
						serviceTypesList: modalState.isEditing ? services : availableServices,
						show: true,
					},
				})

				this.find('#tagPricingModalTypeOfServiceSelect')?.focus?.()

				this.observe('tagPricingModal.pricingType', () => {
					this.set('tagPricingModal.priceAdjustment', 0)
				}, { init: false })
			},
			async openEditTagPricingModal(tagPricingId) {
				const selectedTagId = this.get('selectedTagId')
				const selectedTag = this.get('tags').find(tag => tag.id === selectedTagId)
				const tagPricing = selectedTag.tagPricings.find(tagPricing => tagPricing.id === tagPricingId)

				await this.openTagPricingModal({
					id: tagPricing?.id,
					serviceId: tagPricing?.serviceId,
					pricingType: tagPricing?.pricingType,
					priceAdjustment: tagPricing?.pricingType === 'PERCENT' ? percentToDisplay(tagPricing?.priceAdjustment || '0') : tagPricing?.priceAdjustment,
					postPriceAdjustment: tagPricing?.postPriceAdjustment,
					isEditing: true,
				})

				this.find('#tagPricingModalPricingTypeSelect')?.focus?.()
			},
			async addNewTag({ code, name, description, prompt, entityType }) {
				const newTagMutation = `#graphql
					mutation NewTag($tag: NewTag!) {
						newTag(tag: $tag) {
							id
							code
							name
							description
							prompt
							entityType
							tagPricings {
								${tagPricingField}
							}
						}
					}
				`

				try {
					const { newTag } = await mediator.call('apiFetch', newTagMutation, {
						tag: {
							code,
							name,
							description,
							prompt,
							entityType,
						},
					})

					this.upsert('tags', 'id', newTag)
					this.set({
						tagModal: klona(defaultTagModalState),
					})
				} catch (err) {
					console.log(err)
					this.set('tagModal.errorMessage', err.message)
				}
			},
			async saveTagPricing({ id, serviceId, pricingType, priceAdjustment, postPriceAdjustment }) {
				const selectedTagId = this.get('selectedTagId')
				const tagIndex = this.get('tags').findIndex(tag => tag.id === selectedTagId)
				try {
					let savedTagPricing
					if (this.get('tagPricingModal.isEditing')) {
						const editTagPricingMutation = `#graphql
							mutation EditTagPricing($tagPricing: EditTagPricing!) {
								editTagPricing(tagPricing: $tagPricing) {
									${tagPricingField}
								}
							}
						`
						const { editTagPricing } = await mediator.call('apiFetch', editTagPricingMutation, {
							tagPricing: {
								tagPricingId: id,
								pricingType,
								priceAdjustment: pricingType === 'PERCENT' ? percentToSave(priceAdjustment) : priceAdjustment.toString(),
								postPriceAdjustment,
							},
						})
						savedTagPricing = editTagPricing
						this.upsert(`tags[${tagIndex}].tagPricings`, 'id', savedTagPricing)
					} else {
						const newTagPricingMutation = `#graphql
							mutation NewTagPricing($tagPricing: NewTagPricing!) {
  								newTagPricing(tagPricing: $tagPricing) {
									${tagPricingField}
								}
							}
						`

						const { newTagPricing } = await mediator.call('apiFetch', newTagPricingMutation, {
							tagPricing: {
								tagId: this.get('selectedTagId'),
								serviceId,
								pricingType,
								priceAdjustment: pricingType === 'PERCENT' ? percentToSave(priceAdjustment) : priceAdjustment.toString(),
								postPriceAdjustment,
							},
						})
						savedTagPricing = newTagPricing
						this.push(`tags[${tagIndex}].tagPricings`, savedTagPricing)
					}
					await this.set({
						//close the modal and remove all data to the default state
						tagPricingModal: klona(defaultTagPricingModalState),
					})
				} catch (err) {
					console.error(err)
					this.set('tagPricingModal.show', true)
					this.set('tagPricingModal.errorMessage', err.message)
				}
			},
			async deleteTag(tagId, tagName) {
				if (confirm(`Are you sure you want to delete the "${tagName}" tag?`)) {
					const removeTagMutation = `#graphql
					mutation DeleteTag($tagId: PositiveInt!) {
						deleteTag(tagId: $tagId)
					}
				`

					try {
						await mediator.call('apiFetch', removeTagMutation, { tagId })

						//find the index of the tag in the tags array and remove it
						const tagIndex = this.get('tags').findIndex(tag => tag.id === tagId)
						this.splice('tags', tagIndex, 1)
					} catch (err) {
						console.error(err)
					}
				}
			},
			async deleteTagPricing(tagPricingId, serviceName, formattedPrice) {
				if (confirm(`Are you sure you want to delete the ${formattedPrice} price for "${serviceName}"?`)) {
					const deleteTagPricingMutation = `#graphql
					mutation DeleteTagPricing($tagPricingId: PositiveInt!) {
						deleteTagPricing(tagPricingId: $tagPricingId)
					}
				`

					try {
						await mediator.call('apiFetch', deleteTagPricingMutation, { tagPricingId })

						//find the index of the tag in the tags array and remove it
						const selectedTagId = this.get('selectedTagId')
						const tagIndex = this.get('tags').findIndex(tag => tag.id === selectedTagId)
						const tagPricingIndex = this.get(`tags.${tagIndex}.tagPricings`).findIndex(tagPricing => tagPricing.id === tagPricingId)
						this.splice(`tags[${tagIndex}].tagPricings`, tagPricingIndex, 1)
					} catch (err) {
						console.error(err)
					}
				}
			},
		},
		async resolve(_data, _parameters) {
			const tagQuery = `#graphql
                query Tags {
                    tags {
                        data {
							id
							code
							name
							description
							prompt
							entityType
							tagPricings {
								${tagPricingField}
							}
                        }
                    }
                }
            `

			const servicesQuery = `#graphql
				query Query($pagination: PaginatedInput) {
					services(pagination: $pagination) {
						data {
							id
							name
						}
					}
				}
			`

			const [{ tags }, { services }] = await Promise.all([
				mediator.call('apiFetch', tagQuery),
				mediator.call('apiFetch', servicesQuery, {
					pagination: {
						pageNumber: 1,
						pageSize: 0,
					},
				}),
			])

			return {
				tags: tags?.data,
				selectedTagId: null,
				tagModal: klona(defaultTagModalState),
				tagPricingModal: klona(defaultTagPricingModalState),
				sortedTags: [],
				tagTypeList,
				allServices: services?.data,
				tagPricingTypeList,
			}
		},
		activate(_activateContext) {
			//const { domApi: ractive } = activateContext
		},
	})
}
