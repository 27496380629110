<script lang="ts">
	import { createZone, editZone, loadZones, type ZoneManagementZone, type ZoneManagementRegion, type ZoneManagementState, type ZoneModalType } from 'utility/zones-helper'
	import ZoneModal from './ZoneModal.svelte'

	import Table, { Td } from '@isoftdata/svelte-table'
	import Button from '@isoftdata/svelte-button'
	import Select from '@isoftdata/svelte-select'
	import type { CreateZone$input, EditZone$input } from '$houdini/index'
	import { getContext } from 'svelte'
	import type { Mediator } from 'services/api-fetch'

	const mediator = getContext<Mediator>('mediator')

	export let zones: ZoneManagementZone[]
	export let regions: ZoneManagementRegion[] // Temporary until all regions are forced to have a zone
	export let states: ZoneManagementState[]

	let filteredZones: ZoneManagementZone[] = zones

	let stateFilter: string | null = null

	$: statesInUse = states.filter(state => zones.some(zone => zone.state === state.stateAbbreviation))

	let selectedZone: ZoneManagementZone | null = null

	let zoneModal: ZoneModalType = {
		show: false,
		state: null,
		name: '',
		description: '',
		selectedRegionIds: [],
		editing: false,
	}

	function openAddZoneModal() {
		zoneModal = {
			show: true,
			state: null,
			name: '',
			description: '',
			selectedRegionIds: [],
			editing: false,
		}
	}

	function openEditZoneModal(zone: ZoneManagementZone) {
		zoneModal = {
			show: true,
			state: zone.state,
			name: zone.name,
			description: zone.description,
			selectedRegionIds: zone.regions?.map(region => region.id) ?? [],
			editing: true,
		}
	}

	async function addZone() {
		if (!zoneModal.state || !zoneModal.name) {
			let missingFields: string[] = []
			if (!zoneModal.state) {
				missingFields.push('State')
			}
			if (!zoneModal.name) {
				missingFields.push('Name')
			}
			if (missingFields.length > 0) {
				mediator.call('showMessage', { message: `${missingFields.toString()}`, time: 5000, heading: 'Missing Required Fields' })
			}
			throw new Error('Missing Required Fields')
		}
		const newZoneInput: CreateZone$input = {
			newZone: {
				name: zoneModal.name,
				description: zoneModal.description,
				state: zoneModal.state,
			},
			regionIds: zoneModal.selectedRegionIds,
		}
		await createZone(newZoneInput)
		const reloadedZones = await loadZones()
		zones = reloadedZones
		filteredZones = reloadedZones
		stateFilter = null
		zoneModal.show = false
	}

	async function updateZone() {
		if (!selectedZone) {
			return
		}
		const updateZoneInput: EditZone$input = {
			updateZone: {
				id: selectedZone.id,
				name: zoneModal.name,
				description: zoneModal.description,
				state: zoneModal.state,
			},
			regionIds: zoneModal.selectedRegionIds,
		}
		await editZone(updateZoneInput)
		const reloadedZones = await loadZones()
		zones = reloadedZones
		filteredZones = reloadedZones
		stateFilter = null
		zoneModal.show = false
	}

	function filterZones() {
		if (stateFilter) {
			filteredZones = zones.filter(zone => zone.state === stateFilter)
		} else {
			filteredZones = zones
		}
	}
</script>

<Table
	responsive
	filterEnabled
	rows={filteredZones}
	columns={[
		{ property: 'name', name: 'Name' },
		{ property: 'description', name: 'Description' },
		{ property: 'state', name: 'State' },
	]}
	filterPlaceholder="Search Zones"
	parentStyle="max-height: 70vh; overflow-y: auto;"
	let:row
>
	<svelte:fragment slot="header">
		<div class="form-row align-items-end">
			<div class="col-12 col-md-2">
				<Select
					showLabel={false}
					showEmptyOption
					emptyText="All States"
					bind:value={stateFilter}
					placeholder="Filter State"
					on:change={filterZones}
				>
					{#each statesInUse as state}
						<option value={state.stateAbbreviation}>{state.stateName}</option>
					{/each}
				</Select>
			</div>
		</div>
	</svelte:fragment>
	<svelte:fragment slot="no-rows">
		<tr>
			<td colspan="3">No Zones Found</td>
		</tr>
	</svelte:fragment>
	<tr
		class="cursor-pointer"
		class:table-dark={selectedZone?.id === row.id}
		on:click={() => {
			selectedZone = selectedZone?.id === row.id ? null : row
		}}
	>
		<Td property="name">{row.name}</Td>
		<Td property="description">{row.description}</Td>
		<Td property="state">{row.state}</Td>
	</tr>
	{#if selectedZone?.id === row.id && row.regions}
		<td colspan="3">
			<ul
				class="list-group colspan-3"
				style="max-height: 500px; overflow-y: auto;"
			>
				{#each row.regions as region}
					<li class="list-group-item">
						{region.name}
					</li>
				{:else}
					<li class="list-group-item">No Regions Assigned to this Zone</li>
				{/each}
			</ul>
		</td>
	{/if}
</Table>

<div class="card-footer mt-3">
	<Button
		iconClass={selectedZone ? 'pencil' : 'plus'}
		color="primary"
		on:click={() => {
			if (selectedZone) {
				openEditZoneModal(selectedZone)
			} else {
				openAddZoneModal()
			}
		}}
	>
		{selectedZone ? 'Edit' : 'Add'} Zone
	</Button>
	<span>Orphaned Regions: {regions.filter(region => !region.zoneId).length}</span>
</div>
<ZoneModal
	title={zoneModal.editing ? 'Edit Zone' : 'Add Zone'}
	confirmButtonText={zoneModal.editing ? 'Edit Zone' : 'Add Zone'}
	{regions}
	{states}
	{zones}
	bind:zoneModal
	on:confirm={zoneModal.editing ? updateZone : addZone}
/>
