<script lang="ts">
	import Button from '@isoftdata/svelte-button'
	import Modal from '@isoftdata/svelte-modal'
	import Input from '@isoftdata/svelte-input'
	import { changeUserPassword, updateUserAccountInfo, type UserAccountSession, updateUserDOB } from 'utility/profile-helper'
	import type { CustomerResidence } from 'utility/graphql/load-customer-residences'
	import type { WritableDeep } from 'type-fest'

	import UserProfile from 'components/UserProfile.svelte'
	import CustomerAddresses from 'components/CustomerAddresses.svelte'

	import sessionStore from 'stores/session'

	import { slide } from 'svelte/transition'

	export let userAccount: WritableDeep<UserAccountSession>
	export let isProvider: boolean
	export let isCustomer: boolean
	export let customerResidences: CustomerResidence[]

	let defaultUserAccount: UserAccountSession = userAccount
	let alertState: { message: string; class: string } = { message: '', class: '' }
	let editProfileMode: boolean = false
	let isSavingProfile: boolean = false
	let changePasswordModalShown: boolean = false
	let dateOfBirthChanged: boolean = false
	let dobSaved: boolean = false

	let currentPassword: string = ''
	let newPassword: string = ''
	let newPasswordConfirm: string = ''

	let oldMobilePhoneNumber: string = ''
	let oldFirstName: string = ''
	let oldLastName: string = ''

	$: changePasswordErrorAlert = newPassword !== newPasswordConfirm ? `Your passwords don't match!` : ''

	function showChangePasswordModal() {
		currentPassword = ''
		newPassword = ''
		newPasswordConfirm = ''
		changePasswordModalShown = true
		const currentPasswordInputElement = document.getElementById('currentPassword')
		if (currentPasswordInputElement) {
			currentPasswordInputElement.focus()
		}
	}

	function enableProfileEdit() {
		editProfileMode = true
		isSavingProfile = false

		oldMobilePhoneNumber = userAccount.mobile || ''
		oldFirstName = userAccount.firstName
		oldLastName = userAccount.lastName

		defaultUserAccount = userAccount
	}

	async function saveProfile() {
		isSavingProfile = true
		const { id, firstName, lastName, mobile } = userAccount
		let dob: Date | null | undefined
		if (isProvider && dateOfBirthChanged) {
			dob = userAccount.provider?.dateOfBirth
		}

		try {
			const userAccountInfo = {
				id,
				firstName,
				lastName,
				mobile,
			}

			if (userAccountInfo.mobile === '') {
				userAccountInfo.mobile = null
			}

			//We did validation here at one point on the mobile number but its handled in the user profile component now

			const updatedAccountInfo = await updateUserAccountInfo(userAccountInfo)

			sessionStore.update((session: any) => {
				session.userAccount = updatedAccountInfo
				return session
			})

			if (isProvider && dateOfBirthChanged && dob && userAccount.provider) {
				await updateUserDOB(dob, userAccount.provider.id)
				dobSaved = true
				dateOfBirthChanged = false // reset the flag in case the user changes something else
			}

			oldMobilePhoneNumber = ''
			oldFirstName = ''
			oldLastName = ''

			editProfileMode = false
			isSavingProfile = false
		} catch (err: unknown) {
			const error = err as Error
			console.error(error)
			alert(error.message)
			cancelEdit()
		}
	}

	function cancelEdit() {
		userAccount.mobile = oldMobilePhoneNumber
		userAccount.firstName = oldFirstName
		userAccount.lastName = oldLastName
		if (isProvider && userAccount.provider) {
			userAccount.provider.dateOfBirth = null
		}
		editProfileMode = false
		userAccount = defaultUserAccount
	}

	async function confirmChangePassword(currentPassword: any, newPassword: any, newPasswordConfirm: any, e: SubmitEvent & { currentTarget: EventTarget & HTMLFormElement }) {
		e.preventDefault()
		if (newPassword !== newPasswordConfirm) {
			return
		}

		try {
			if (!userAccount.recoveryEmail) {
				throw new Error('You must have a recovery email to change your password.')
			}
			await changeUserPassword(newPassword, currentPassword, userAccount.recoveryEmail)
			changePasswordModalShown = false
			alertState.message = 'Password changed successfully!'
			alertState.class = 'success'
			setTimeout(() => {
				alertState.message = ''
			}, 5000)
		} catch (err: unknown) {
			const error = err as Error
			console.error(error)
		} finally {
			changePasswordModalShown = false
		}
	}
</script>

<div
	class="d-flex m-3"
	class:justify-content-between={!editProfileMode}
	class:justify-content-end={editProfileMode}
>
	{#if !editProfileMode}
		<Button
			outline
			color="primary"
			size="sm"
			icon={{
				icon: 'key',
			}}
			on:click={showChangePasswordModal}
			disabled={!userAccount.recoveryEmail}>Change Password</Button
		>
	{/if}
	<div>
		{#if !editProfileMode}
			<Button
				color="primary"
				size="sm"
				icon={{
					icon: 'pen',
				}}
				on:click={enableProfileEdit}
				disabled={!userAccount.recoveryEmail}>Edit Profile</Button
			>
		{:else}
			<Button
				color="primary"
				size="sm"
				icon={{
					icon: 'save',
				}}
				on:click={saveProfile}
				disabled={isSavingProfile || !userAccount.recoveryEmail}>Save</Button
			>
			<Button
				outline
				class="ml-2"
				color="danger"
				size="sm"
				icon={{
					icon: 'xmark',
				}}
				on:click={cancelEdit}
				disabled={isSavingProfile || !userAccount.recoveryEmail}>Cancel</Button
			>
		{/if}
	</div>
</div>

<div class="p-3">
	{#if alertState?.message}
		<div
			class="alert alert-{alertState?.class} mb-2"
			role="alert"
			in:slide={{ duration: 200 }}
			out:slide={{ duration: 200 }}
		>
			{alertState?.message}
		</div>
	{/if}
	<UserProfile
		disableEmailField
		{userAccount}
		disableOtherField={!editProfileMode}
		showProfilePicture={isProvider}
		isEditing={editProfileMode}
		dobChangedSaved={dobSaved}
		on:DOBChanged={() => {
			dateOfBirthChanged = true
		}}
	/>
	<div class="mt-4">
		{#if isCustomer}
			<CustomerAddresses
				selectableAddresses={false}
				bind:customerResidences
			/>
		{/if}
	</div>
</div>

<Modal
	title="Change Password"
	show={changePasswordModalShown}
	confirmButtonSize="sm"
	cancelButtonSize="sm"
	confirmButtonColor="primary"
	cancelButtonColor="danger"
	cancelButtonText="Cancel"
	modalSize="sm"
	confirmButtonType="submit"
	confirmButtonFormId="changePassword"
	confirmButtonDisabled={(changePasswordErrorAlert !== '' && newPassword !== newPasswordConfirm) || !currentPassword || !newPassword || !newPasswordConfirm}
	on:backdrop={() => (changePasswordModalShown = false)}
	on:close={() => (changePasswordModalShown = false)}
>
	<form
		id="changePassword"
		on:submit={e => confirmChangePassword(currentPassword, newPassword, newPasswordConfirm, e)}
	>
		<div class="form-row">
			<div class="col-md-12">
				<div class="form-row">
					<div class="col-md-12">
						<Input
							required
							id="currentPassword"
							label="Current Password"
							bind:value={currentPassword}
							type="password"
							validation={{
								value: currentPassword,
							}}
						/>
					</div>
					<div class="col-md-12">
						<Input
							label="New Password"
							bind:value={newPassword}
							type="password"
							required
							validation={{
								value: newPassword,
							}}
						/>
					</div>
					<div class="col-md-12">
						<Input
							label="Confirm New Password"
							bind:value={newPasswordConfirm}
							type="password"
							required={!!newPassword}
							validation={{
								value: newPasswordConfirm,
							}}
						/>
					</div>
				</div>
			</div>
		</div>
		{#if changePasswordErrorAlert}
			<div
				class="alert alert-danger mb-0 mt-2"
				role="alert"
			>
				{changePasswordErrorAlert}
			</div>
		{/if}
	</form>
</Modal>
