export default {
    "name": "MakeCoupon",
    "kind": "HoudiniMutation",
    "hash": "a61a5222c558736b0c0d81802f173ab05e1aad58eff88788f04210cb36d38d84",

    "raw": `mutation MakeCoupon($newCoupon: NewCoupon!) {
  newCoupon(newCoupon: $newCoupon) {
    id
    code
    description
    validFrom
    validTo
    numberOfTimesUsed
    maximumTimesUsed
    usesPerUser
    serviceId
    priceAdjustment
    isPercentage
    active
    firstJobOnly
    isValid
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "newCoupon": {
                "type": "Coupon",
                "keyRaw": "newCoupon(newCoupon: $newCoupon)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "code": {
                            "type": "NonEmptyString",
                            "keyRaw": "code",
                            "visible": true
                        },

                        "description": {
                            "type": "String",
                            "keyRaw": "description",
                            "visible": true
                        },

                        "validFrom": {
                            "type": "DateTime",
                            "keyRaw": "validFrom",
                            "visible": true
                        },

                        "validTo": {
                            "type": "DateTime",
                            "keyRaw": "validTo",
                            "nullable": true,
                            "visible": true
                        },

                        "numberOfTimesUsed": {
                            "type": "NonNegativeInt",
                            "keyRaw": "numberOfTimesUsed",
                            "visible": true
                        },

                        "maximumTimesUsed": {
                            "type": "NonNegativeInt",
                            "keyRaw": "maximumTimesUsed",
                            "nullable": true,
                            "visible": true
                        },

                        "usesPerUser": {
                            "type": "NonNegativeInt",
                            "keyRaw": "usesPerUser",
                            "nullable": true,
                            "visible": true
                        },

                        "serviceId": {
                            "type": "NonNegativeInt",
                            "keyRaw": "serviceId",
                            "nullable": true,
                            "visible": true
                        },

                        "priceAdjustment": {
                            "type": "String",
                            "keyRaw": "priceAdjustment",
                            "visible": true
                        },

                        "isPercentage": {
                            "type": "Boolean",
                            "keyRaw": "isPercentage",
                            "visible": true
                        },

                        "active": {
                            "type": "Boolean",
                            "keyRaw": "active",
                            "visible": true
                        },

                        "firstJobOnly": {
                            "type": "Boolean",
                            "keyRaw": "firstJobOnly",
                            "visible": true
                        },

                        "isValid": {
                            "type": "Boolean",
                            "keyRaw": "isValid",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "newCoupon": "NewCoupon"
        },

        "types": {
            "NewCoupon": {
                "code": "NonEmptyString",
                "description": "String",
                "firstJobOnly": "Boolean",
                "isPercentage": "Boolean",
                "maximumTimesUsed": "NonNegativeInt",
                "priceAdjustment": "String",
                "serviceId": "NonNegativeInt",
                "usesPerUser": "NonNegativeInt",
                "validFrom": "DateTime",
                "validTo": "DateTime"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=ccf7bc4770d4ec452394b1b5e3383b58825d90e047af4b7843ab92052d7ac5c1";