import template from './manage-job.ractive.html'
import { getFailedPaymentJobCount, getAllPassedScheduledTimeJobClaimCount } from 'utility/admin-job-alerts'
import makeNavTabBar from '@isoftdata/nav-tab-bar-component'

export default function({ mediator, stateRouter }) {
	stateRouter.addState({
		name: 'app.admin.manage-jobs',
		route: 'manage-jobs',
		defaultChild: 'job',
		querystringParameters: [ 'orderBy' ],
		defaultParameters: {
			orderBy: 'ID_DESC',
		},
		template: {
			template,
			components: {
				navTabBar: makeNavTabBar(stateRouter),
			},
			updateChildState(initialCount, newCount, childStateIndex) {
				if (initialCount !== newCount) {
					this.set({ showRefreshHeader: true })
				} else {
					this.set({ showRefreshHeader: false })
				}
	
				if (newCount) {
					this.set(`childStates.${childStateIndex}.icon`, 'circle-exclamation text-danger')
				} else {
					this.set(`childStates.${childStateIndex}.icon`, '')
				}
			},
		},
		async resolve(_data, _parameters) {
			let adminJobAlertIcon
			let failedJobCount = 0
			let passedScheduledTimeJobClaimCount = 0

			try {
				const [failedJobCountRes, passedScheduledTimeJobClaimCountRes] = await Promise.all([
					getFailedPaymentJobCount(),
					getAllPassedScheduledTimeJobClaimCount(),
				])
				if (failedJobCountRes > 0 || passedScheduledTimeJobClaimCountRes > 0) {
					adminJobAlertIcon = 'circle-exclamation text-danger'
				}
				failedJobCount = failedJobCountRes
				passedScheduledTimeJobClaimCount = passedScheduledTimeJobClaimCountRes
			} catch (error) {
				console.error('Failed to get alert job count', error)
			}
			
			return {
				childStates: [
					{
						title: 'Failed Payment Jobs',
						stateName: 'app.admin.manage-jobs.failed-payment-job',
						icon: adminJobAlertIcon,
					},
					{
						title: 'Jobs',
						stateName: 'app.admin.manage-jobs.job',
					},
				],
				showRefreshHeader: true,
				failedJobCount,
				passedScheduledTimeJobClaimCount,
			}
		},
		activate(activateContext) {
			const { domApi: ractive } = activateContext
			const initialFailedJobCount = ractive.get('failedJobCount')
			const initialPassedScheduledTimeJobClaimCount = ractive.get('passedScheduledTimeJobClaimCount')

			const adminJobAlertUnsub = ractive.get('adminJobAlertStore').subscribe(newAdminJobAlert => {
				const newFailedJobCount = newAdminJobAlert.failedJobCount
				const newPassedScheduledTimeJobClaimCount = newAdminJobAlert.passedScheduledTimeJobClaimCount

				ractive.updateChildState(initialFailedJobCount, newFailedJobCount, 0)
				ractive.updateChildState(initialPassedScheduledTimeJobClaimCount, newPassedScheduledTimeJobClaimCount, 1)

				ractive.set({
					failedJobCount: newFailedJobCount,
					passedScheduledTimeJobClaimCount: newPassedScheduledTimeJobClaimCount,
				})
			})

			stateRouter.on('stateChangeEnd', () => {
				ractive.set('showRefreshHeader', false)
			})

			activateContext.on('destroy', () => {
				adminJobAlertUnsub?.()
			})
		},
	})
}
