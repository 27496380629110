<script lang="ts">
    import { v4 as uuidv4 } from '@lukeed/uuid'
	import { createEventDispatcher, onMount } from 'svelte'
    import { getLabelForMarker, type GoogleMapsPin, type PlaceResultWithSelected } from 'utility/google-maps-helper';
    export let uuid: string = uuidv4()
    export let places: PlaceResultWithSelected[] = []
    export let bounds: google.maps.LatLngBounds | null = null
    export let pins: GoogleMapsPin[] = []
    export let mapStyle = 'height: 500px;'
    export let anchorInputToMap = false
    export let initWithCurrentLocation = false
    let infoWindow: google.maps.InfoWindow
    let map: google.maps.Map

    const dispatch = createEventDispatcher()

    $: if (places.length > 0) {
        updatePlaces(places)
    }

    $: selectedPlace = places.find(place => place.selected)

    export function removeAllPins(selectedPlaceId?: number) {
        for (const { id, marker } of pins) {
            if (selectedPlaceId && id === selectedPlaceId) {
                continue
            }
            marker.map = null
        }
        pins = pins
    }
    export function bounceMarker(lat: number, lng: number) {
        pins.forEach(({marker, animation}) => {
            if (marker.position?.lat === lat && marker.position?.lng === lng) {
                animation = google.maps.Animation.BOUNCE
                setTimeout(() => {
                    animation = null
                }, 500)
            }
        })
    }

    export function showInfoWindow(markerPosition: { position: { latitude: number | undefined; longitude: number | undefined } }) {
        const { latitude, longitude } = markerPosition.position
        const pinData = pins.find(({ marker}) => {
            return marker.position?.lat === latitude && marker.position?.lng === longitude
        })
        if (!pinData) {
            console.error('No pin data found for marker', markerPosition)
        }

        infoWindow.setContent(`<div class="h6">${pinData?.marker.title}</div>`)
        infoWindow.setPosition(pinData?.marker.position!)
        infoWindow.open({
            anchor: pinData?.marker,
            map,
            shouldFocus: false,
        })
    }

    export function hideInfoWindow() {
        infoWindow.close()
    }
    export async function updatePlaces(places: PlaceResultWithSelected[]) {
        if (!Array.isArray(places)) {
            return
        }

        const { AdvancedMarkerElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary // This is how google wants it done
        let selectedPlaceId: number | undefined = undefined
        if (selectedPlace) {
            selectedPlaceId = selectedPlace.id
        }
        removeAllPins(selectedPlaceId)
        const localBounds = new google.maps.LatLngBounds()

        let localPins: GoogleMapsPin[] = []
        places.forEach((place, index) => {
            const position = place.geometry?.location
            if (!position) {
                console.error('No position for place', place)
            }

            let glyphLabel = document.createElement('div')
            glyphLabel.setAttribute('style', 'color: white; font-size: 17px;')
            glyphLabel.classList.add('classname')
            glyphLabel.innerText = getLabelForMarker(index)

            const pinElementOptions: google.maps.marker.PinElementOptions = {
                glyph: glyphLabel,
                background: place.selected ? '#78c2ad' : null,
                borderColor: place.selected ? '#78c2ad' : null,
            }
            let iconImage = new google.maps.marker.PinElement(pinElementOptions)
            
            const marker = new AdvancedMarkerElement({
                map,
                title: place.name,
                position,
                content: iconImage.element,
            })

            localPins.push({
                marker,
                id: place.id!,
                animation: null,
            })

            marker.addListener('click', ({ latLng }) => {
                const clickedMarker = places.find(place => {
                    const localPosition = place.geometry?.location
                    return localPosition?.lat() === latLng.lat() && localPosition?.lng() === latLng.lng()
                })
                const markerPosition = {
                    position: {
                        latitude: clickedMarker?.geometry?.location?.lat(),
                        longitude: clickedMarker?.geometry?.location?.lng(),
                    }
                }
                if (clickedMarker) {
                    dispatch('markerClicked', clickedMarker.id)
                }
                showInfoWindow(markerPosition)
            })
            if (place?.geometry?.viewport) {
                localBounds.union(place.geometry.viewport)
            } else if (place?.geometry?.location) {
                localBounds.extend(place.geometry.location)
            }
            localBounds.extend(marker.position!)
        })
        map.setCenter(localBounds.getCenter())
        map.fitBounds(localBounds)
        pins = localPins
        if (pins.length === 1) {
            map.setZoom(18)
            map.setCenter(pins[0].marker.position!)
        }
    }
    async function initMap(): Promise<void> {
        const defaultCenter = { lat: 40.8136, lng: -96.7026 } // Lincoln, NE
        
        const { Map, InfoWindow } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary // This is how google wants it done
        try {
            // New Google maps api requires a mapId hosted on google cloud which is how Points of interest are toggled now
            // https://console.cloud.google.com/google/maps-apis/studio/styles/dc6349b31d4882b0?project=lawn-hiro&supportedpurview=project
            map = new Map(document.getElementById(`places-map-${uuid}`) as HTMLElement
            , {
                zoom: 18,
                center: defaultCenter,
                mapTypeId: 'hybrid',
                mapId: 'db00108a21380f76', 
                zoomControl: false,
                scaleControl: false,
                streetViewControl: true,
                rotateControl: false,
            })

        infoWindow = new InfoWindow()

        if (anchorInputToMap) {
            const input = document.getElementById('pac-input') as HTMLInputElement
            map.controls[google.maps.ControlPosition.TOP_LEFT].push(input)
        }
        map.addListener('bounds_changed', () => {
            const getBounds = map.getBounds()
            if (getBounds) {
                bounds = getBounds
            }
        })
        if (initWithCurrentLocation && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                if (position?.coords) {
                    map.setCenter({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    })
                }
            
            })
        }
        const listener = google.maps.event.addListener(map, 'idle', () => {
            if (pins.length === 1 && pins[0].marker.position) {
                if (map.getZoom()! < 18) {
                    map.setZoom(18)
                    map.setCenter(pins[0].marker.position)
                }
            }
            google.maps.event.removeListener(listener)
        })
        if (places.length > 1) {
            updatePlaces(places)
        }
        } catch (error) {
            console.error(error)
        }
    }
    onMount(() => {
        initMap()
    })
</script>


<div class="card">
	<div
		id="places-map-{uuid}"
		style={mapStyle}
	>
	</div>
</div>

<style>
	#description {
		font-family: Roboto;
		font-size: 15px;
		font-weight: 300;
	}

	#infowindow-content {
		display: none;
	}

	:global(#infowindow-content .title) {
			font-weight: bold;
		}

	:global(#map #infowindow-content) {
		display: inline;
	}

	#title {
		color: #fff;
		background-color: #4d90fe;
		font-size: 25px;
		font-weight: 500;
		padding: 6px 12px;
	}
</style>
