import template from './job.ractive.html'
import { parseISO as parseISODate, startOfDay, endOfDay } from 'date-fns'
import jobStatusMap from 'utility/job-status-map'
import formatDuration from 'utility/format/format-duration'
import getComputedJob from 'utility/get-computed-job'
import { rangeFromDates, datesFromRange } from '@isoftdata/utility-date-time'
import formatJobFileData from 'utility/format/format-job-file-data'
import { klona } from 'klona'

//Ractive components
import makeInput from '@isoftdata/input'
import makeTable from '@isoftdata/table'
import makeSelect from '@isoftdata/select'
import makeDateRange from '@isoftdata/date-range'
import makeButton from '@isoftdata/button'
import makeModal from '@isoftdata/modal'
import makeCheckbox from '@isoftdata/checkbox'
import makeTextArea from '@isoftdata/textarea'
import makeJobTimePicker from 'components/job-time-picker'
import makeJobClaimInfo from 'components/job-claim-info'

let jobStatusFilterList = [
	{ value: 'PENDING,CLAIMED,COMPLETED', label: 'Pending, Scheduled, or Completed' },
	{ value: 'PENDING,CLAIMED', label: 'Pending or Scheduled' },
	{ value: 'EXPIRED,COMPLETED', label: 'Expired or Completed' },
	...Object.entries(jobStatusMap).map(([ value, obj ]) => {
		return { value, label: obj.label }
	}),
]

const pageSizeList = [
	{ value: 10, label: '10' },
	{ value: 25, label: '25' },
	{ value: 50, label: '50' },
	{ value: 100, label: '100' },
	{ value: 0, label: 'All' },
]

const defaultCancelJobModalState = Object.freeze({
	confirmation: false,
	jobAddress: null,
	jobService: null,
	reason: '',
	jobId: null,
	errorMessage: '',
	show: false,
})

const dateField = new Map([
	[
		'creationDate', {
			from: {
				field: 'createdFrom',
				formatter: date => startOfDay(parseISODate(date)),
			},
			to: {
				field: 'createdTo',
				formatter: date => endOfDay(parseISODate(date)),
			},
		},
	],
	[
		'requestedSchedule', {
			from: {
				field: 'requestedScheduleFrom',
			},
			to: {
				field: 'requestedScheduleTo',
			},
		},
	],
])

const absoluteDateTimeFormatter = date => new Intl.DateTimeFormat('en-US', { dateStyle: 'short', timeStyle: 'short' }).format(date)
const currencyFormatter = amount => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(`${amount}`)

const jobQueryData = `#graphql
	id
	jobStatus
	created
	requestedSchedule
	totalElapsedTime
	customerId
	completed
	serviceScheduleId
	customerFirstJob
	customer {
		id
		userAccount {
			fullName
			email
			mobile
			status
		}
	}
	residenceId
	residence {
		id
		region {
			id
			name
		}
		longitude
		latitude
		zip
		street
		state
		estimatedLawnSquareFootage
		effectiveLawnSquareFootage
		country
		city
		googleMapsPlaceId
	}
	jobClaims {
		provider {
			userAccount {
				fullName
			}
			profilePictureFile {
				path
			}
		}
		providerId
		jobClaimStatus
		scheduledAt
		isLate
	}
	transaction {
		cost
		total
		tax
		subtotal
	}
	service {
		name
	}
`

const DEFAULT_RANGE = 'Last 7 Days'

const orderByColumnMap = {
	id: 'ID',
	requestedSchedule: 'REQUESTED_SCHEDULE',
	completed: 'COMPLETED',
}

const orderByColumnMapInverse = {
	ID: 'id',
	REQUESTED_SCHEDULE: 'requestedSchedule',
	COMPLETED: 'completed',
}

function getOrderBy(column = 'id', direction = 'DESC') {
	return `${orderByColumnMap[column] ?? 'ID'}_${direction.toUpperCase() ?? 'DESC'}`
}

function getSortColumn(orderBy, columns) {
	const column = orderBy.replace(/_DESC|_ASC/, '')
	const property = orderByColumnMapInverse[column] ?? 'id'
	return columns.find(column => column.property === property)
}

export default ({ mediator, stateRouter }) => {
	stateRouter.addState({
		name: 'app.admin.manage-jobs.job',
		route: 'job',
		querystringParameters: [ 'jobStatus', 'fromDate', 'toDate', 'dateType', 'pageSize', 'pageNumber', 'orderBy' ],
		defaultParameters: {
			dateType: 'creationDate',
			fromDate: () => datesFromRange(DEFAULT_RANGE).from,
			toDate: () => datesFromRange(DEFAULT_RANGE).to,
			pageSize: 50,
			pageNumber: 1,
			orderBy: 'ID_DESC',
		},
		template: {
			template,
			components: {
				itInput: makeInput({ twoway: true, lazy: false }),
				itTable: makeTable({
					methods: {
						columnClicked(column, direction) {
							const orderBy = getOrderBy(column.property, direction)
							stateRouter.go('app.admin.manage-jobs.job', { orderBy, pageNumber: 1 }, { inherit: true })
						},
					},
					stickyHeader: true,
				}),
				itSelect: makeSelect({ twoway: true, lazy: false }),
				itDateRange: makeDateRange(),
				itButton: makeButton(),
				itModal: makeModal(),
				itCheckbox: makeCheckbox(),
				itTextArea: makeTextArea({ twoway: true, lazy: false }),
				jobTimePicker: makeJobTimePicker(),
				jobClaimInfo: makeJobClaimInfo(mediator),
			},
			computed: {
				computedJobs() {
					return this.get('jobs').map(job => {
						const computedJob = getComputedJob(job)
						const activeJobClaim = job.jobClaims?.find(({ jobClaimStatus }) => jobClaimStatus === 'ACCEPTED' || jobClaimStatus === 'IN_PROGRESS')
						const scheduledAt = activeJobClaim?.scheduledAt ? parseISODate(activeJobClaim.scheduledAt) : null

						return {
							...computedJob,
							created: absoluteDateTimeFormatter(computedJob.created),
							requestedScheduleFormatted: new Intl.DateTimeFormat('en-US').format(computedJob.requestedSchedule),
							completedFormatted: computedJob.completed ? absoluteDateTimeFormatter(computedJob.completed) : '',
							activeJobClaim: activeJobClaim ? {
								...activeJobClaim,
								scheduledAt,
								scheduledAtFormatted: activeJobClaim.scheduledAt ? absoluteDateTimeFormatter(scheduledAt) : '',
								inProgress: activeJobClaim.jobClaimStatus === 'IN_PROGRESS',
							} : null,
							formattedElapsedTime: formatDuration(job.totalElapsedTime),
							customerName: job?.customer?.userAccount?.fullName || '',
							customerEmail: job?.customer?.userAccount?.email || '',
							customerStatus: job?.customer?.userAccount?.status || '',
							customerPhone: job?.customer?.userAccount?.mobile || '',
							formattedResidence: `${job?.residence?.street}\n${job?.residence?.city}`,
							squareFootage: job?.residence?.effectiveLawnSquareFootage || job?.residence?.estimatedLawnSquareFootage,
							formattedSquareFootage: new Intl.NumberFormat('en-US').format(job?.residence?.effectiveLawnSquareFootage || job?.residence?.estimatedLawnSquareFootage),
							cost: job?.transaction?.cost ? currencyFormatter(job?.transaction?.cost) : '',
							subtotal: job?.transaction?.subtotal ? currencyFormatter(job?.transaction?.subtotal) : '',
							tax: job?.transaction?.tax ? currencyFormatter(job?.transaction?.tax) : '',
							total: job?.transaction?.total ? currencyFormatter(job?.transaction?.total) : '',
							serviceName: job.service.name,
							isRecurringSchedule: !!job?.serviceScheduleId,
						}
					})
				},
				selectedJob() {
					return this.get('computedJobs').find(({ id }) => id === this.get('selectedJobId'))
				},
			},
			// This function will probably be used to do the cancellation on jobs, might still need modification
			/*
			editJob(jobId) {
				const job = this.get('jobs').find(job => job.id === jobId)
				const validJobHours = getValidJobDates()

				this.set({
					editJobModal: {
						show: true,
						job: klona(job),
						validJobHours,
						jobDateTime: null,
					},
				})
			},
			*/
			async selectJob(id) {
				if (this.get('selectedJobId') === id) {
					this.set({ selectedJobId: null })
					return
				}
				const jobPhotoFilesQuery = `#graphql
					query Job($jobId: PositiveInt!) {
						job(id: $jobId) {
							files {
								id
								fileId
								rank
								public
								imageFileType
								file{
									id
									name
									created
									updated
									hash
									path
									type
									mimeType
								}
							}
						}
					}
				`

				try {
					const { job } = await mediator.call('apiFetch', jobPhotoFilesQuery, {
						jobId: id,
					})

					const jobs = this.get('jobs')
					const jobIndex = jobs.findIndex(job => job.id === id)

					this.set(`jobs.${jobIndex}.files`, formatJobFileData(job.files))
				} catch (error) {
					console.log(error)
					alert('Error: Something went wrong while fetching job information.')
				}

				this.set({ selectedJobId: id })
			},
			async makeJobAvailable(jobId) {
				if (jobId && confirm('Are you sure you want to move this job back to available?\n\nAll active claims for this job will be cancelled.')) {
					const releaseJobFromClaimsQuery = `#graphql
						mutation ReleaseJobFromClaims($jobId: Float!) {
							releaseJobFromClaims(jobId: $jobId) {
								${jobQueryData}
							}
						}
					`

					const { releaseJobFromClaims: job } = await mediator.call('apiFetch', releaseJobFromClaimsQuery, { jobId })
					this.upsert('jobs', 'id', job)
				}
			},
			async cancelJob(modalState) {
				const jobId = modalState?.jobId
				const reason = modalState?.reason ?? null
				if (modalState.jobId) {
					const cancelJobQuery = `#graphql
						mutation Mutation($cancelJobInput: CancelJobInput!) {
							cancelJob(cancelJobInput: $cancelJobInput) {
								${jobQueryData}
							}
						}
					`

					const { cancelJob: job } = await mediator.call('apiFetch', cancelJobQuery, { cancelJobInput: { jobId, reason } })
					this.upsert('jobs', 'id', job)
					this.set({
						cancelJobModal: {
							...klona(defaultCancelJobModalState),
							show: false,
						},
						selectedJobId: null,
					})
				}
			},
			async openCancelJobModal(jobId, jobAddress, jobService) {
				await this.set({
					cancelJobModal: {
						...klona(defaultCancelJobModalState),
						jobId,
						jobAddress,
						jobService,
						show: true,
					},
				})
				this.find('#reasonForCancellationTextArea').focus()
			},
		},
		async resolve(data, { jobStatus, fromDate, toDate, dateType, pageSize, pageNumber, orderBy }) {
			const jobsQuery = `#graphql
				query Jobs($filter: JobFilter, $pagination: PaginatedInput, $orderBy: [JobOrderBy!]) {
					jobs(filter: $filter, pagination: $pagination, orderBy: $orderBy) {
						data {
							${jobQueryData}
						}
						info {
							totalPages
							totalItems
							pageSize
							pageNumber
						}
					}
				}
			`

			const providersQuery = `#graphql
				query Providers {
					providers {
						data {
							id
							userAccount {
								fullName
							}
							status
						}
					}
				}
			`

			let filter = {}
			if (jobStatus && jobStatus !== 'FIRSTJOB') {
				filter.statuses = jobStatus
			}

			if (jobStatus === 'FIRSTJOB') {
				filter.customerFirstJobOnly = true
			}

			if (fromDate) {
				filter[dateField.get(dateType).from.field] = dateField.get(dateType).from.formatter?.(fromDate) || fromDate
			}

			if (toDate) {
				filter[dateField.get(dateType).to.field] = dateField.get(dateType).to.formatter?.(toDate) || toDate
			}

			const [ jobsRes, providerListRes ] = await Promise.all([
				mediator.call('apiFetch', jobsQuery, {
					pagination: {
						pageNumber: parseInt(pageNumber, 10) || 1,
						pageSize,
					},
					filter,
					orderBy,
				}),
				mediator.call('apiFetch', providersQuery, {}),
			])

			const jobTableColumns = [
				{ property: 'id', name: 'Job ID' },
				{ property: 'jobStatus', name: 'Status', sortType: false },
				{ property: 'residence[region][name]', name: 'Region', sortType: false },
				{ property: 'customerName', name: 'Customer Name', sortType: false },
				{ property: 'customerEmail', name: 'Customer Email', sortType: false },
				{ property: 'serviceName', name: 'Service', sortType: false },
				{ property: 'created', name: 'Created', sortType: false },
				{ property: 'requestedSchedule', name: 'Requested Schedule' },
				{ property: 'completed', name: 'Completed' },
				{ property: 'formattedResidence', name: 'Address', sortType: false },
				{ property: 'squareFootage', name: 'sq. ft.', sortType: false },
				{ property: 'cost', name: 'Cost', sortType: false },
				{ property: 'total', name: 'Total', sortType: false },
				{ property: 'totalElapsedTime', name: 'Elapsed Time', sortType: false },
			]

			return {
				jobs: jobsRes?.jobs?.data || [],
				totalPages: jobsRes?.jobs?.info?.totalPages || 1,
				providerList: providerListRes?.providers?.data?.filter?.(provider => provider.status === 'APPROVED') || [],
				sortedJobs: [],
				jobStatusMap,
				cancellableJobStatus: [ 'PENDING', 'CLAIMED', 'CLAIMABLE' ],
				releasableJobStatus: [ 'CLAIMED' ],
				jobStatusFilter: Array.isArray(jobStatus) ? jobStatus.join(',') : (jobStatus ?? null),
				jobStatusFilterList,
				pageSizeList,
				editJobModal: {
					job: {},
					show: false,
				},
				selectedJobId: null,
				dateType,
				dates: {
					from: fromDate || null,
					to: toDate || null,
				},
				pageSize: pageSize || 50,
				pageNumber: parseInt(pageNumber, 10) || 1,
				range: rangeFromDates(fromDate, toDate) || 'Custom',
				totalItems: jobsRes?.jobs?.info?.totalItems || 0,
				sortDirection: orderBy.split('_')?.slice(-1)?.[0] || 'DESC',
				sortColumn: getSortColumn(orderBy, jobTableColumns),
				orderBy,
				jobTableColumns,
			}
		},
		activate(activateContext) {
			const { domApi: ractive } = activateContext

			const { cancel: cancelJobStatusFilterObserver } = ractive.observe('jobStatusFilter', jobStatus => {
				stateRouter.go(null, { jobStatus: jobStatus ? jobStatus.split(',') : null }, { inherit: true, replace: true })
			}, { init: false })

			const { cancel: cancelDatesObserver } = ractive.observe('dates', ({ from, to }) => {
				stateRouter.go(null, { fromDate: from, toDate: to, pageNumber: 1 }, { inherit: true, replace: true })
			}, { init: false })

			const { cancel: cancelDateTypeObserver } = ractive.observe('dateType', dateType => {
				stateRouter.go(null, { dateType, pageNumber: 1 }, { inherit: true, replace: true })
			}, { init: false })

			const { cancel: cancelPageSizeObserver } = ractive.observe('pageSize', pageSize => {
				stateRouter.go(null, { pageSize, pageNumber: 1 }, { inherit: true, replace: true })
			}, { init: false })

			ractive.on('itTable.paginationPageChange', ({ pageNumber }) => {
				stateRouter.go(null, { pageNumber }, { inherit: true })
			})

			activateContext.on('destroy', () => {
				cancelJobStatusFilterObserver()
				cancelDatesObserver()
				cancelDateTypeObserver()
				cancelPageSizeObserver()
			})
		},
	})
}
