export default {
    "name": "LoadZones",
    "kind": "HoudiniQuery",
    "hash": "a47e6eb0f6c4d632d8ee07542a0a60831b2394deb91fb1eb65efcf02cded1300",

    "raw": `query LoadZones($pagination: PaginatedInput, $filter: ZoneFilter) {
  zones(pagination: $pagination, filter: $filter) {
    data {
      id
      name
      state
      description
      regions {
        id
        name
      }
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "zones": {
                "type": "ZoneResponse",
                "keyRaw": "zones(filter: $filter, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Zone",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "state": {
                                        "type": "String",
                                        "keyRaw": "state",
                                        "visible": true
                                    },

                                    "description": {
                                        "type": "String",
                                        "keyRaw": "description",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "regions": {
                                        "type": "Region",
                                        "keyRaw": "regions",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "pagination": "PaginatedInput",
            "filter": "ZoneFilter"
        },

        "types": {
            "PaginatedInput": {
                "pageNumber": "PositiveInt",
                "pageSize": "NonNegativeInt"
            },

            "ZoneFilter": {
                "id": "PositiveInt",
                "name": "NonEmptyString",
                "state": "NonEmptyString"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=2e19b13aad11a44b9a5537c869c26cb6c010f25ae260fbeefa1a67c135bb8426";