export default {
    "name": "LoadProviderManagement",
    "kind": "HoudiniQuery",
    "hash": "5ca1291d4f734ca5baebc7f4ff71bbfe5b03c953b4ab64a967526f619d7a5dae",

    "raw": `query LoadProviderManagement($ProviderPagination: PaginatedInput) {
  regions(pagination: {pageNumber: 1, pageSize: 0}) {
    data {
      id
      name
      status
    }
  }
  services(pagination: {pageNumber: 1, pageSize: 0}) {
    data {
      id
      name
      codeName
      description
      active
    }
  }
  providers(pagination: $ProviderPagination) {
    data {
      id
      homeRegion {
        id
        name
        status
      }
      city
      country
      county
      state
      street
      zip
      profilePictureFile {
        id
        path
      }
      status
      latestOnboardingApplication {
        id
        status
      }
      userAccount {
        id
        firstName
        lastName
        fullName
        email
        created
        mobile
        status
        authorizedRegions {
          id
          name
          status
        }
      }
      authorizedServices {
        id
        service {
          id
          name
          codeName
          description
          active
        }
      }
    }
    info {
      totalItems
      totalPages
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "regions": {
                "type": "RegionResponse",
                "keyRaw": "regions(pagination: {pageNumber: 1, pageSize: 0})",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Region",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "status": {
                                        "type": "RegionStatus",
                                        "keyRaw": "status",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "services": {
                "type": "ServiceResponse",
                "keyRaw": "services(pagination: {pageNumber: 1, pageSize: 0})",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Service",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "codeName": {
                                        "type": "String",
                                        "keyRaw": "codeName",
                                        "visible": true
                                    },

                                    "description": {
                                        "type": "String",
                                        "keyRaw": "description",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "active": {
                                        "type": "Boolean",
                                        "keyRaw": "active",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "providers": {
                "type": "ProviderResponse",
                "keyRaw": "providers(pagination: $ProviderPagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Provider",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "homeRegion": {
                                        "type": "Region",
                                        "keyRaw": "homeRegion",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "status": {
                                                    "type": "RegionStatus",
                                                    "keyRaw": "status",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "city": {
                                        "type": "String",
                                        "keyRaw": "city",
                                        "visible": true
                                    },

                                    "country": {
                                        "type": "String",
                                        "keyRaw": "country",
                                        "visible": true
                                    },

                                    "county": {
                                        "type": "String",
                                        "keyRaw": "county",
                                        "visible": true
                                    },

                                    "state": {
                                        "type": "String",
                                        "keyRaw": "state",
                                        "visible": true
                                    },

                                    "street": {
                                        "type": "String",
                                        "keyRaw": "street",
                                        "visible": true
                                    },

                                    "zip": {
                                        "type": "String",
                                        "keyRaw": "zip",
                                        "visible": true
                                    },

                                    "profilePictureFile": {
                                        "type": "FileEntity",
                                        "keyRaw": "profilePictureFile",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "path": {
                                                    "type": "String",
                                                    "keyRaw": "path",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "status": {
                                        "type": "ProviderStatus",
                                        "keyRaw": "status",
                                        "visible": true
                                    },

                                    "latestOnboardingApplication": {
                                        "type": "ProviderApplication",
                                        "keyRaw": "latestOnboardingApplication",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "status": {
                                                    "type": "ProviderApplicationStatus",
                                                    "keyRaw": "status",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "userAccount": {
                                        "type": "UserAccount",
                                        "keyRaw": "userAccount",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "firstName": {
                                                    "type": "String",
                                                    "keyRaw": "firstName",
                                                    "visible": true
                                                },

                                                "lastName": {
                                                    "type": "String",
                                                    "keyRaw": "lastName",
                                                    "visible": true
                                                },

                                                "fullName": {
                                                    "type": "String",
                                                    "keyRaw": "fullName",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "email": {
                                                    "type": "EmailAddress",
                                                    "keyRaw": "email",
                                                    "visible": true
                                                },

                                                "created": {
                                                    "type": "DateTime",
                                                    "keyRaw": "created",
                                                    "visible": true
                                                },

                                                "mobile": {
                                                    "type": "PhoneNumber",
                                                    "keyRaw": "mobile",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "status": {
                                                    "type": "UserAccountStatus",
                                                    "keyRaw": "status",
                                                    "visible": true
                                                },

                                                "authorizedRegions": {
                                                    "type": "Region",
                                                    "keyRaw": "authorizedRegions",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "name": {
                                                                "type": "String",
                                                                "keyRaw": "name",
                                                                "visible": true
                                                            },

                                                            "status": {
                                                                "type": "RegionStatus",
                                                                "keyRaw": "status",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "authorizedServices": {
                                        "type": "ProviderService",
                                        "keyRaw": "authorizedServices",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "service": {
                                                    "type": "Service",
                                                    "keyRaw": "service",

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "name": {
                                                                "type": "String",
                                                                "keyRaw": "name",
                                                                "visible": true
                                                            },

                                                            "codeName": {
                                                                "type": "String",
                                                                "keyRaw": "codeName",
                                                                "visible": true
                                                            },

                                                            "description": {
                                                                "type": "String",
                                                                "keyRaw": "description",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "active": {
                                                                "type": "Boolean",
                                                                "keyRaw": "active",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "info": {
                            "type": "PageInfo",
                            "keyRaw": "info",

                            "selection": {
                                "fields": {
                                    "totalItems": {
                                        "type": "Int",
                                        "keyRaw": "totalItems",
                                        "visible": true
                                    },

                                    "totalPages": {
                                        "type": "Int",
                                        "keyRaw": "totalPages",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "ProviderPagination": "PaginatedInput"
        },

        "types": {
            "PaginatedInput": {
                "pageNumber": "PositiveInt",
                "pageSize": "NonNegativeInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=c9351e7ce7224e20a899c6fb24f6da5b79d0db6e0406180b5905175b2de6eaa5";