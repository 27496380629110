<script lang="ts">
	import { getContext, onDestroy } from 'svelte'
	import { slide } from 'svelte/transition'
	import { addDays, addHours, endOfDay } from 'date-fns'

	import Checkbox from '@isoftdata/svelte-checkbox'
	import Button from '@isoftdata/svelte-button'
	import CollapsibleCard from '@isoftdata/svelte-collapsible-card'

	import JobTimePicker from 'components/JobTimePicker.svelte'
	import FirstOrderBadge from 'components/FirstOrderBadge.svelte'
	import DirectionButtons from 'components/DirectionButtons.svelte'
	import AvailableJobsList from './AvailableJobsList.svelte'
	import GooglePlacesMap from 'components/GooglePlacesMap.svelte'

	import type { GoogleMapsPin, PlaceResultWithSelected } from 'utility/google-maps-helper'
	import { claimJobHelper, loadFormattedJobsWithmarkers, type AvailableJobsRegion, type AvailableJobsService, type FormattedJob, type JobsWithmarkers } from 'utility/available-jobs-helper'
	import getValidJobDates from 'utility/get-valid-job-dates'
	import type { Mediator, PromiseType } from 'types/common'
	import type { ProviderMediatorProviders } from '../provider'
	import type { ProviderStatus$options } from '$houdini/graphql'

	const mediator = getContext<Mediator<ProviderMediatorProviders>>('mediator')

	type ClaimJobConfirmation = {
		residence: FormattedJob['residence'] | undefined
		jobId: number | undefined
		timeRange: string
		residenceTags: Exclude<FormattedJob['residence']['answers'], null>[number]['tag'][]
		jobTags: FormattedJob['answers']
		jobHoursLoading: boolean
		jobDateTime: Date
		jobDay: string
		validJobHours: PromiseType<ReturnType<typeof getValidJobDates>> | undefined
		customerFirstJob: boolean
	}

	export let providerStatus: ProviderStatus$options
	export let providerRegionsList: AvailableJobsRegion[]
	export let serviceFilterList: AvailableJobsService[]
	export let selectedServiceIds: number[]
	export let selectedRegionIds: number[]
	export let jobs: JobsWithmarkers[]
	export let jobPlaces: PlaceResultWithSelected[]

	let jobPins: GoogleMapsPin[] = []
	let selectedJobId: number | null
	let selectedJob: JobsWithmarkers | undefined
	let bounds: google.maps.LatLngBounds | null = new google.maps.LatLngBounds()
	let gMap: GooglePlacesMap
	let serviceFilterShown = false
	let showRegionsFilter = false
	let isLoadingJobs = false
	let isClaimingJob = false
	let scheduledTime: Date | null = null

	let claimJobConfirmation: ClaimJobConfirmation = {
		residence: undefined,
		jobId: undefined,
		timeRange: '',
		residenceTags: [],
		jobTags: [],
		jobHoursLoading: false,
		jobDateTime: new Date(),
		jobDay: '',
		validJobHours: undefined,
		customerFirstJob: false,
	}

	async function jobClicked(e: CustomEvent) {
		const job = jobs.find(job => job.id === e.detail)
		if (selectedJob?.id !== e.detail) {
			selectedJob = job
			selectedJobId = e.detail
		} else {
			gMap.hideInfoWindow()
			selectedJob = undefined
			selectedJobId = null
		}
		if (!job || !job.residence.latitude || !job.residence.longitude) return
		gMap.showInfoWindow({
			position: {
				latitude: job.residence.latitude,
				longitude: job.residence.longitude,
			},
		})
		const cardElement = document.getElementById(`job-${e.detail}`)
		if (cardElement) {
			cardElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
		}
		const startDate = job.requestedSchedule.getTime() > Date.now() ? job.requestedSchedule : addHours(new Date(), 1)
		const endDate = addDays(endOfDay(startDate), job.service.maximumDaysToPerform)
		const relativeTimeRange = job.service.serviceOffer?.find(offer => offer.regionId === job.residence.region.id)?.timeRange

		if (!relativeTimeRange) return

		const residenceTags = job.residence?.answers?.map(answer => answer.tag) ?? []
		const validJobHours = await getValidJobDates(job.residence.latitude, job.residence.longitude, startDate, endDate, relativeTimeRange)

		claimJobConfirmation = {
			residence: job.residence,
			jobId: job.id,
			timeRange: relativeTimeRange,
			residenceTags,
			jobTags: job.answers,
			jobHoursLoading: false,
			jobDateTime: validJobHours.days[Object.keys(validJobHours.days)?.[0]]?.[0]?.date,
			jobDay: validJobHours.days[Object.keys(validJobHours.days)?.[0]]?.[0]?.relativeDay,
			validJobHours,
			customerFirstJob: job.customerFirstJob,
		}

		jobPlaces = jobPlaces.map(place => {
			place.selected = place.id === selectedJobId
			return place
		})
	}

	onDestroy(() => {
		gMap?.removeAllPins?.()
	})

	async function claimJob(e: SubmitEvent & { currentTarget: EventTarget & HTMLFormElement }) {
		e.preventDefault()

		if (!selectedJobId) return

		const selectedJobAddOns = selectedJob?.answers?.map?.(answer => `- ${answer.answer}`).join('\n') ?? null
		const startJobConfirmText = `Are you sure you want to claim this job? \n\nYou will be responsible for completing all add on services: \n${selectedJobAddOns}`

		if (!selectedJobAddOns || confirm(startJobConfirmText)) {
			try {
				isClaimingJob = true
				const claimJobRes = await claimJobHelper(selectedJobId, claimJobConfirmation.jobDateTime)
				if (!claimJobRes) {
					throw new Error('Failed to claim job')
				}
				scheduledTime = claimJobRes.scheduledAt
				const jobToUpdateIndex = jobs.findIndex(job => job.id === claimJobRes.job.id)
				if (jobToUpdateIndex < 0) {
					throw new Error('Job not found')
				}
				jobs[jobToUpdateIndex] = {
					...jobs[jobToUpdateIndex],
					jobStatus: claimJobRes.job.jobStatus,
				}
				jobs = jobs
				selectedJobId = claimJobRes.job.id
				selectedJob = jobs[jobToUpdateIndex]
			} catch (error) {
				console.error(error)
			} finally {
				isClaimingJob = false
				mediator.call('jobWasClaimed')
			}
		}
	}

	async function loadJobs() {
		// Using a load variable because we do some formatting/mapping on the result before it gets set
		isLoadingJobs = true

		try {
			const loadedJobs = await loadFormattedJobsWithmarkers(selectedServiceIds, selectedRegionIds)
			jobs = loadedJobs
		} catch (error) {
			console.error(error)
		} finally {
			isLoadingJobs = false
		}
	}

	async function handleFilterChange(targetId: number, filterType: 'service' | 'region') {
		if (filterType === 'service') {
			if (selectedServiceIds.includes(targetId)) {
				selectedServiceIds = selectedServiceIds.filter(id => id !== targetId)
			} else {
				selectedServiceIds = [...selectedServiceIds, targetId]
			}
		} else if (filterType === 'region') {
			if (selectedRegionIds.includes(targetId)) {
				selectedRegionIds = selectedRegionIds.filter(id => id !== targetId)
			} else {
				selectedRegionIds = [...selectedRegionIds, targetId]
			}
		}
		await loadJobs()
	}
</script>

<div class="row m-3">
	{#if providerStatus === 'APPROVED'}
		<div class="col-12 col-md-6 col-lg-6 col-xl-3 p-0">
			{#if serviceFilterList.length > 0}
				<CollapsibleCard
					entireHeaderToggles
					headerText="Filter By Service"
					cardHeaderClass="card-header d-flex justify-content-between"
					cardClass="mb-2"
					bind:bodyShown={serviceFilterShown}
				>
					<div class="form-row">
						{#each serviceFilterList as service}
							<div class="col-12 col-md-6">
								<Checkbox
									id={`service-${service.codeName}`}
									label={service.name}
									checked={selectedServiceIds.includes(service.id)}
									disabled={selectedServiceIds.includes(service.id) && selectedServiceIds.length === 1}
									on:change={() => handleFilterChange(service.id, 'service')}
								/>
							</div>
						{/each}
					</div>
				</CollapsibleCard>
				<CollapsibleCard
					entireHeaderToggles
					headerText="Filter By Region"
					cardHeaderClass="card-header d-flex justify-content-between"
					cardClass="mt-2 mb-2"
					bind:bodyShown={showRegionsFilter}
				>
					<div class="form-row">
						{#each providerRegionsList as region}
							<div class="col-12 col-md-6">
								<Checkbox
									id={`region-${region.id}`}
									label={region.name}
									checked={selectedRegionIds.includes(region.id)}
									disabled={selectedRegionIds.includes(region.id) && selectedRegionIds.length === 1}
									on:change={() => handleFilterChange(region.id, 'region')}
								/>
							</div>
						{/each}
					</div>
				</CollapsibleCard>

				{#if isLoadingJobs}
					<div class="d-flex justify-content-center text-center">
						<i class="fa-solid fa-spinner fa-spin fa-3x"></i>
					</div>
				{:else}
					<AvailableJobsList
						bind:jobs
						bind:selectedJobId
						on:jobClicked={jobClicked}
					>
						{#if selectedJob?.jobStatus === 'CLAIMABLE'}
							<form
								id="claimJob"
								on:submit={e => {
									claimJob(e)
								}}
							>
								<div
									in:slide={{ duration: 100 }}
									out:slide={{ duration: 100 }}
								>
									<div class="d-flex justify-content-between align-items-end flex-wrap">
										{#if claimJobConfirmation.residenceTags.length > 0}
											<div class="mb-2">
												<span>Residence Details:</span>
												<div>
													{#each claimJobConfirmation.residenceTags as tag}
														<span class="badge badge-pill badge-primary mr-1">{tag.name}</span>
													{/each}
												</div>
											</div>
											<hr class="w-100 m-0" />
										{/if}
										{#if claimJobConfirmation.jobTags && claimJobConfirmation.jobTags.length > 0}
											<div class="mb-2">
												<span>Job Details:</span>
												<div>
													{#each claimJobConfirmation.jobTags as tag}
														<span class="badge badge-pill badge-primary mr-1">{tag.answer}</span>
													{/each}
												</div>
											</div>
											<hr class="w-100 m-0" />
										{/if}
										<div class="w-100 mt-1">
											<JobTimePicker
												disabled={claimJobConfirmation.jobHoursLoading}
												bind:selectedDateTime={claimJobConfirmation.jobDateTime}
												bind:validJobHours={claimJobConfirmation.validJobHours}
												bind:timeRange={claimJobConfirmation.timeRange}
												bind:jobDay={claimJobConfirmation.jobDay}
											/>
										</div>
										{#if selectedJob.specialInstructions}
											<div class="w-100 text-smaller">
												<div class="alert alert-danger mt-2 mb-2">
													<div class="font-weight-bold alert-heading">Customer Special Instructions</div>
													{selectedJob.specialInstructions}
												</div>
											</div>
											<hr class="w-100 m-1" />
										{/if}
										<div class="mt-2">
											<DirectionButtons residence={selectedJob.residence} />
										</div>
										<div>
											<Button
												color="primary"
												size="lg"
												class="btn"
												type="submit"
												isLoading={isClaimingJob}
												disabled={isClaimingJob || !claimJobConfirmation.jobDateTime || selectedJob.jobStatus !== 'CLAIMABLE'}
											>
												Claim Job
											</Button>
										</div>
										{#if claimJobConfirmation.customerFirstJob}
											<FirstOrderBadge />
										{/if}
									</div>
								</div>
							</form>
						{:else if selectedJob?.jobStatus === 'CLAIMED'}
							<div
								in:slide={{ duration: 100 }}
								out:slide={{ duration: 100 }}
							>
								<span>This job is scheduled for {scheduledTime?.toLocaleString()}.</span>
							</div>
						{/if}
					</AvailableJobsList>
				{/if}
			{:else}
				<div class="mt-2">You currently don't have any authorized services. Please contact info@lawnhiro.com for more information.</div>
			{/if}
		</div>
		{#if jobs.length}
			<div class="col-12 col-md-6 col-lg-6 col-xl-9 p-0 mt-3 mt-md-0 pl-md-3">
				<GooglePlacesMap
					bind:places={jobPlaces}
					{bounds}
					mapStyle="height: 60vh; min-height: 200px;"
					initWithCurrentLocation={false}
					bind:pins={jobPins}
					on:markerClicked={jobClicked}
					bind:this={gMap}
				/>
			</div>
		{:else}
			<h1 class="text-center w-100 mt-5">No jobs available</h1>
		{/if}
	{:else if providerStatus === 'PENDING_APPROVAL'}
		<div class="alert alert-warning">
			<h4 class="alert-heading">
				<i class="fas fa-exclamation-triangle"></i>
				&nbsp;
				<span> Your account is still pending approval. </span>
			</h4>
			<p class="mb-0">Please complete the onboarding application to get approved.</p>
		</div>
	{:else if providerStatus === 'DISABLED'}
		<div class="alert alert-danger">
			<h4 class="alert-heading">
				<i class="fas fa-exclamation-triangle"></i>
				&nbsp;
				<span> Your account has been disabled. </span>
			</h4>
			<p class="mb-0">Please contact LawnHiro support for more information.</p>
		</div>
	{/if}
</div>
