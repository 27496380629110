import { getSession } from 'stores/session'
import component from './AvailableJobs.svelte'

import type { AppContext } from 'types/common'
import { LoadProviderAvailableServicesHelper, LoadProviderUserRegionsHelper, loadFormattedJobsWithmarkers, type JobsWithmarkers } from 'utility/available-jobs-helper'
import type { LoadProviderAvailableServices$result, LoadProviderUserRegions$result } from '$houdini/index'

export default ({ stateRouter }: AppContext) => {
	stateRouter.addState({
		name: 'app.provider.available-jobs',
		route: 'available-jobs',
		// The only external parameter we really need are date for an email, the rest are for filtering so I can make them Ids
		querystringParameters: ['date'],
		defaultParameters: {
			date: new Date().toLocaleDateString(),
		},
		template: {
			component,
			svelte: true,
			options: {},
		},
		async resolve(_data, _parameters) {
			const session = getSession()
			const provider = session.provider
			const userAccountId = session.userAccountId

			if (!provider || !userAccountId) {
				return Promise.reject({
					redirectTo: {
						name: 'login',
					},
				})
			}

			let jobsWithMarkers: JobsWithmarkers[] = []
			let providerServices: Exclude<LoadProviderAvailableServices$result['provider']['authorizedServices'], null>[number]['service'][] = []
			let providerRegionsList: Exclude<LoadProviderUserRegions$result['getUserRegions'], null>[number]['region'][] = []
			let filterByServiceIds: number[] = []
			let filterByRegionIds: number[] = []
			if (provider.status === 'APPROVED') {
				// If the provider is approved, we can load the jobs and related data
				providerServices = (await LoadProviderAvailableServicesHelper(provider.id)).map(service => service.service)
				providerRegionsList = (await LoadProviderUserRegionsHelper(userAccountId)).map(region => region.region) || []
				filterByServiceIds = providerServices.map(service => service.id)
				filterByRegionIds = providerRegionsList.map(region => region.id)
				jobsWithMarkers = await loadFormattedJobsWithmarkers(filterByServiceIds, filterByRegionIds)
			}

			const { LatLng } = (await google.maps.importLibrary('core')) as google.maps.CoreLibrary

			const jobPlaces = jobsWithMarkers.map(job => {
				if (!job.residence.latitude || !job.residence.longitude) {
					return {
						id: job.id,
						geometry: undefined,
						name: job.residence.street,
						selected: false,
					}
				} else {
					return {
						id: job.id,
						geometry: {
							location: new LatLng(job.residence.latitude, job.residence.longitude),
						},
						name: job.residence.street,
						selected: false,
					}
				}
			})

			return Promise.resolve({
				providerStatus: provider.status,
				providerRegionsList,
				serviceFilterList: providerServices,
				selectedServiceIds: filterByServiceIds,
				selectedRegionIds: filterByRegionIds,
				jobs: jobsWithMarkers,
				jobPlaces,
			})
		},
	})
}
