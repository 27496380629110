export default {
    "name": "CreateZone",
    "kind": "HoudiniMutation",
    "hash": "a16491c5d7c8f67fb75e39212b6eaa28424e768b3e84b8d6ebc5b97801ef6fb0",

    "raw": `mutation CreateZone($newZone: NewZone!, $regionIds: [PositiveInt!]) {
  newZone(newZone: $newZone, regionIds: $regionIds) {
    id
    description
    name
    state
    regions {
      id
      name
    }
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "newZone": {
                "type": "Zone",
                "keyRaw": "newZone(newZone: $newZone, regionIds: $regionIds)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "description": {
                            "type": "String",
                            "keyRaw": "description",
                            "nullable": true,
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "state": {
                            "type": "String",
                            "keyRaw": "state",
                            "visible": true
                        },

                        "regions": {
                            "type": "Region",
                            "keyRaw": "regions",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "newZone": "NewZone",
            "regionIds": "PositiveInt"
        },

        "types": {
            "NewZone": {
                "description": "String",
                "name": "NonEmptyString",
                "state": "NonEmptyString"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=585f41432dbf670d6112b027de2de4c93a515b0a7e13f1b33f0a73b9786ff4e9";