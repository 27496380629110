export default {
    "name": "LoadRegionHelperSpecificRegion",
    "kind": "HoudiniQuery",
    "hash": "57db5e1b55d9e0e8bab24d44e4bf471006aa349a0eccbf2bca09412877ed09a4",

    "raw": `query LoadRegionHelperSpecificRegion($regionId: PositiveInt!) {
  region(id: $regionId) {
    id
    name
    description
    latitude
    longitude
    timeZone
    status
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "region": {
                "type": "Region",
                "keyRaw": "region(id: $regionId)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "description": {
                            "type": "String",
                            "keyRaw": "description",
                            "visible": true
                        },

                        "latitude": {
                            "type": "Latitude",
                            "keyRaw": "latitude",
                            "visible": true
                        },

                        "longitude": {
                            "type": "Longitude",
                            "keyRaw": "longitude",
                            "visible": true
                        },

                        "timeZone": {
                            "type": "TimeZone",
                            "keyRaw": "timeZone",
                            "nullable": true,
                            "visible": true
                        },

                        "status": {
                            "type": "RegionStatus",
                            "keyRaw": "status",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "regionId": "PositiveInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=2dfefb01161b0b2d28003a1a9eaa59a63f8ff117ed9b0a6a651af5ed4f4c38ac";