import { graphql, GetAdminAlertTransactionsCountStore, GetAdminAlertTransactionsStore, TransactionOrderBy, GetAdminAlertTransactions$result } from '$houdini'
import { getPassedScheduleTimeJobClaimCount } from './graphql/provider-job-claims-query'

const getAdminAlertTransactionsQuery: GetAdminAlertTransactionsStore = graphql`
	query GetAdminAlertTransactions($orderBy: [TransactionOrderBy!], $filter: AdminAlertTransactionFilter) {
		getAdminAlertTransactions(orderBy: $orderBy, filter: $filter) {
			data {
				id
				total
				paymentStatus
				invoiceStatus
				job {
					id
					service {
						name
					}
				}
				customer {
					userAccount {
						fullName
						email
					}
				}
				transactionPayments {
					created
					gatewayMessage
					transactionType
					transactionPaymentClassification
				}
			}
		}
	}
`

const getAdminAlertTransactionsCountQuery: GetAdminAlertTransactionsCountStore = graphql`
	query GetAdminAlertTransactionsCount($orderBy: [TransactionOrderBy!], $filter: AdminAlertTransactionFilter) {
		getAdminAlertTransactions(orderBy: $orderBy, filter: $filter) {
			info {
				totalItems
			}
		}
	}
`

export async function getFailedPaymentJobCount(): Promise<number> {
	const { data } = await getAdminAlertTransactionsCountQuery.fetch({
		variables: {
			filter: {
				resolved: false,
			},
			orderBy: [TransactionOrderBy.ID_DESC],
		},
	})
	if (!data) {
		throw new Error('No data returned')
	}
	return data.getAdminAlertTransactions.info.totalItems
}

type FailedPaymentJobData = GetAdminAlertTransactions$result['getAdminAlertTransactions']['data']

export async function getFailedPaymentJobs(getResolvedJobs: boolean | null): Promise<FailedPaymentJobData> {
	const { data } = await getAdminAlertTransactionsQuery.fetch({
		variables: {
			filter: {
				resolved: getResolvedJobs,
			},
			orderBy: [TransactionOrderBy.ID_DESC],
		},
	})
	if (!data) {
		throw new Error('No data returned')
	}
	return data.getAdminAlertTransactions.data
}

export async function getAllPassedScheduledTimeJobClaimCount(): Promise<number> {
	return await getPassedScheduleTimeJobClaimCount({})
}

export default getFailedPaymentJobs
