export type FormattedJobFileData = {
	file: {
		path: string
	}
	extensionPath: string
	path: string
}

export function formatJobFileData<T extends FormattedJobFileData>(files: T[]): FormattedJobFileData[] {
	return files.map(metadata => {
		const formattedData: FormattedJobFileData = {
			...metadata,
			...metadata.file,
			extensionPath: metadata.file.path,
			path: `__fileServerUrl__${metadata.file.path}`,
		}
		return formattedData
	})
}

export default formatJobFileData
