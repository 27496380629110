<script lang="ts">
	import Table, { Td, type Column } from '@isoftdata/svelte-table'
	import Button from '@isoftdata/svelte-button'
	import Select from '@isoftdata/svelte-select'
	import Input from '@isoftdata/svelte-input'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import CurrencyInput from '@isoftdata/svelte-currency-input'
	import Modal from '@isoftdata/svelte-modal'
	import { type FormattedCoupon, createCoupon, deactivateCoupon, formatCouponResponse, loadCoupons } from 'utility/coupon-helper'
	import type { CouponQuery$input, CouponServices$result, MakeCoupon$input } from '$houdini'
	import financialNumber from 'financial-number'

	const TODAY = new Date().toISOString().slice(0, 10)
	const couponStatusFilterList = [
		{ value: 'VALID_ONLY', label: 'Valid Only' },
		{ value: 'NOT_VALID_ONLY', label: 'Invalid Only' },
	]
	const couponColumns: Column[] = [
		{ property: 'code', name: 'Code' },
		{ property: 'service', name: 'Service' },
		{ property: 'description', name: 'Description' },
		{ property: 'validFrom', name: 'Valid From' },
		{ property: 'validTo', name: 'Valid To' },
		{ property: 'numberOfTimesUsed', name: 'Used' },
		{ property: 'maximumTimesUsed', name: 'Limit', sortType: 'ALPHA_NUM' },
		{ property: 'usesPerUser', name: 'Limit Per Customer', sortType: 'ALPHA_NUM' },
		{ property: 'priceAdjustment', name: 'Discount', sortType: 'ALPHA_NUM' },
		{ property: 'isValid', name: 'Status', title: 'Coupon Status' },
		{ property: 'action', name: '', sortType: false },
	]

	export let coupons: FormattedCoupon[] = []
	export let couponServices: CouponServices$result['services']['data'] = []
	export let couponStatusFilter: string | null = 'VALID_ONLY'

	let showModal = false
	let code = ''
	let serviceId = null
	let description = ''
	let isPercentage = false
	let priceAdjustment = ''
	let firstJobOnly = false
	let maximumTimesUsed = null
	let usesPerUser = null
	let validFrom = ''
	let validTo = ''
	let errorMessage = ''
	let couponTable: Table<FormattedCoupon>

	$: couponTable?.setColumnVisibility(['isValid'], !couponStatusFilter)
	$: couponTable?.setColumnVisibility(['action'], !couponStatusFilter || couponStatusFilter === 'VALID_ONLY')

	async function reloadCoupons() {
		const filter: CouponQuery$input['filter'] = {}
		if (couponStatusFilter) {
			filter.valid = couponStatusFilter === 'VALID_ONLY'
		}
		const reloadedCoupons = await loadCoupons({
			valid: filter.valid,
		})

		if (!reloadedCoupons) {
			return
		}

		coupons = formatCouponResponse(reloadedCoupons, couponServices)
	}

	function addCoupon() {
		showModal = true
		validFrom = new Date().toISOString().slice(0, 10)
		validTo = ''
		code = ''
		description = ''
		isPercentage = false
		priceAdjustment = ''
		firstJobOnly = false
		maximumTimesUsed = null
		usesPerUser = null
		errorMessage = ''
	}

	async function deactivateCouponHandler(coupon: FormattedCoupon) {
		if (confirm(`Are you sure you want to deactivate this coupon "${coupon.code}"?`)) {
			const deactivatedCoupon = await deactivateCoupon(coupon.id)
			if (deactivatedCoupon) {
				await reloadCoupons()
			}
		}
	}

	async function createCouponHandler() {
		try {
			const couponToCreate: MakeCoupon$input['newCoupon'] = {
				code,
				serviceId,
				description,
				isPercentage,
				priceAdjustment: isPercentage ? financialNumber(priceAdjustment).times('0.01').changePrecision(2).toString() : priceAdjustment,
				firstJobOnly,
				maximumTimesUsed,
				usesPerUser,
				validFrom: new Date(`${validFrom}T00:00:00`),
				validTo: validTo ? new Date(`${validTo}T23:59:59`) : null,
			}

			couponToCreate.validFrom?.setMilliseconds(0) // mysql doesn't do well with 3 digit milliseconds
			couponToCreate.validTo?.setMilliseconds(0) // mysql doesn't do well with 3 digit milliseconds

			if (couponToCreate.validTo && couponToCreate.validFrom && couponToCreate.validFrom > couponToCreate.validTo) {
				return alert('Valid To Date is before Valid From Date. Please enter a valid date range.')
			}

			const newCoupon = await createCoupon(couponToCreate)

			if (newCoupon) {
				const formattedNewCoupon = formatCouponResponse([newCoupon], couponServices)
				coupons.unshift(formattedNewCoupon[0])
				coupons = coupons
				showModal = false
			}
		} catch (error: any) {
			errorMessage = error.message
			console.error(error)
		}
	}
</script>

<div class="container-fluid mt-3">
	<div class="d-flex justify-content-end">
		<Button
			iconClass="plus"
			on:click={addCoupon}
		>
			Add New Coupon
		</Button>
	</div>
	<Table
		responsive
		filterEnabled
		showFilterLabel
		filterLabel="Filter Coupons"
		rows={coupons}
		columns={couponColumns}
		bind:this={couponTable}
		let:row
	>
		<svelte:fragment slot="header">
			<div class="form-row align-items-end">
				<div class="col-12 col-md-3">
					<Select
						label="Coupon Status"
						bind:value={couponStatusFilter}
						emptyText="Any Status"
						on:change={reloadCoupons}
					>
						{#each couponStatusFilterList as status}
							<option value={status.value}>{status.label}</option>
						{/each}
					</Select>
				</div>
			</div>
		</svelte:fragment>
		<tr>
			<Td
				class="font-weight-bold"
				property="code">{row.code}</Td
			>
			<Td property="service">{row.service}</Td>
			<Td property="description">{row.description}</Td>
			<Td property="validFrom">{row.validFrom.toLocaleDateString()}</Td>
			<Td property="validTo">{row.validTo?.toLocaleDateString() ?? 'N/A'}</Td>
			<Td property="numberOfTimesUsed">{row.numberOfTimesUsed}</Td>
			<Td property="maximumTimesUsed">{row.maximumTimesUsed ?? 'Unlimited'}</Td>
			<Td property="usesPerUser">
				{#if row.firstJobOnly}
					<span class="badge badge-pill badge-primary">First Job Only</span>
				{:else}
					{row.usesPerUser ?? 'Unlimited'}
				{/if}
			</Td>
			<Td property="priceAdjustment">{row.priceAdjustment}</Td>
			<Td property="isValid">
				{row.isValid ? 'Valid' : 'Invalid'}
			</Td>
			<Td property="action">
				{#if row.isValid}
					<Button
						size="sm"
						color="danger"
						iconClass="trash"
						on:click={() => deactivateCouponHandler(row)}
					>
						Deactivate
					</Button>
				{/if}
			</Td>
		</tr>
	</Table>
</div>

<Modal
	bind:show={showModal}
	title="Add New Coupon"
	cancelButtonText="Cancel"
	confirmButtonDisabled={!(code && validFrom && priceAdjustment)}
	on:confirm={createCouponHandler}
	on:close={() => (showModal = false)}
>
	<div class="form-row align-items-end">
		<div class="col-6">
			<Input
				autofocus
				id="couponCode"
				label="Coupon Code"
				bind:value={code}
				type="text"
				required
				spellcheck="false"
				validation={{
					value: code,
				}}
			/>
		</div>
		<div class="col-6">
			<Select
				label="Type of Service"
				bind:value={serviceId}
				emptyText="Any Service"
			>
				{#each couponServices as service}
					<option value={service.id}>{service.name}</option>
				{/each}
			</Select>
		</div>
		<div class="col-12">
			<Input
				label="Description"
				bind:value={description}
				type="text"
			/>
		</div>
		<div class="col-4">
			<Checkbox
				label="Is Percentage"
				bind:checked={isPercentage}
			/>
			{#if isPercentage}
				<div class="form-group mb-1">
					<label
						class="col-form-label font-weight-bold"
						for="discountPercentage"
						>*&nbsp;Discount Amount
					</label>
					<div class="input-group input-group-sm">
						<input
							id="discountPercentage"
							type="number"
							inputmode="decimal"
							class="form-control"
							placeholder="0"
							required
							bind:value={priceAdjustment}
						/>
						<div class="input-group-append">
							<span class="input-group-text">%</span>
						</div>
					</div>
				</div>
			{:else}
				<CurrencyInput
					label="Discount Amount"
					bind:value={priceAdjustment}
					imputMode="decimal"
					required
					validation={{
						value: priceAdjustment,
					}}
				/>
			{/if}
		</div>
		<div class="col-4">
			<Input
				label="Usage Limit"
				bind:value={maximumTimesUsed}
				type="number"
				required={false}
			/>
		</div>
		<div class="col-4">
			<Checkbox
				label="First Job Only"
				bind:checked={firstJobOnly}
			/>
			<Input
				label="Limit Per Customer"
				bind:value={usesPerUser}
				type="number"
				disabled={firstJobOnly}
			/>
		</div>
		<div class="col-6">
			<Input
				label="Valid From"
				class="form-control mt-1"
				labelParentClass="form-group mb-1"
				bind:value={validFrom}
				min={TODAY}
				type="date"
				required
				validation={{
					value: validFrom,
				}}
			/>
		</div>
		<div class="col-6">
			<Input
				label="Valid To"
				class="form-control mt-1"
				labelParentClass="form-group mb-1"
				bind:value={validTo}
				type="date"
			/>
		</div>
	</div>
	{#if errorMessage}
		<div
			style="opacity: 1;"
			class="signin-message-box text-center alert alert-danger"
			role="alert"
		>
			<i class="fas fa-exclamation-circle"></i> <strong style="font-size: x-small">{errorMessage}</strong>
		</div>
	{/if}
	<div class="mt-3">
		<span class="text-smaller font-weight-bold">* Bold fields are required</span>
	</div>
</Modal>
