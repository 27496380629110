export default {
    "name": "UserProfileSessionQuery",
    "kind": "HoudiniQuery",
    "hash": "9940e1d067ea27e5f579a6bc3cafaf6ff03bb2b19b30b9aa940d066ec8bbc8f4",

    "raw": `query UserProfileSessionQuery {
  session {
    user {
      id
      email
      firstName
      lastName
      fullName
      recoveryEmail
      mobile
      provider {
        id
        dateOfBirth
        profilePictureFile {
          id
          path
        }
      }
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "session": {
                "type": "Session",
                "keyRaw": "session",
                "nullable": true,

                "selection": {
                    "fields": {
                        "user": {
                            "type": "UserAccount",
                            "keyRaw": "user",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "email": {
                                        "type": "EmailAddress",
                                        "keyRaw": "email",
                                        "visible": true
                                    },

                                    "firstName": {
                                        "type": "String",
                                        "keyRaw": "firstName",
                                        "visible": true
                                    },

                                    "lastName": {
                                        "type": "String",
                                        "keyRaw": "lastName",
                                        "visible": true
                                    },

                                    "fullName": {
                                        "type": "String",
                                        "keyRaw": "fullName",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "recoveryEmail": {
                                        "type": "EmailAddress",
                                        "keyRaw": "recoveryEmail",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "mobile": {
                                        "type": "PhoneNumber",
                                        "keyRaw": "mobile",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "provider": {
                                        "type": "Provider",
                                        "keyRaw": "provider",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "dateOfBirth": {
                                                    "type": "Date",
                                                    "keyRaw": "dateOfBirth",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "profilePictureFile": {
                                                    "type": "FileEntity",
                                                    "keyRaw": "profilePictureFile",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "path": {
                                                                "type": "String",
                                                                "keyRaw": "path",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=a281eeea8a9c2f86a0ce5b0d346d70569cb6ab8250c77be83454ab342825c42a";