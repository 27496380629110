export default {
    "name": "DeactivateCoupon",
    "kind": "HoudiniMutation",
    "hash": "f54d501e9b6c0c008b4bb60731e7e8612fc9e707b5cbcfb850d2aa6f25792bc6",

    "raw": `mutation DeactivateCoupon($couponId: NonNegativeInt!) {
  deactivateCoupon(couponId: $couponId) {
    id
    code
    description
    validFrom
    validTo
    numberOfTimesUsed
    maximumTimesUsed
    usesPerUser
    serviceId
    priceAdjustment
    isPercentage
    active
    firstJobOnly
    isValid
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "deactivateCoupon": {
                "type": "Coupon",
                "keyRaw": "deactivateCoupon(couponId: $couponId)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "code": {
                            "type": "NonEmptyString",
                            "keyRaw": "code",
                            "visible": true
                        },

                        "description": {
                            "type": "String",
                            "keyRaw": "description",
                            "visible": true
                        },

                        "validFrom": {
                            "type": "DateTime",
                            "keyRaw": "validFrom",
                            "visible": true
                        },

                        "validTo": {
                            "type": "DateTime",
                            "keyRaw": "validTo",
                            "nullable": true,
                            "visible": true
                        },

                        "numberOfTimesUsed": {
                            "type": "NonNegativeInt",
                            "keyRaw": "numberOfTimesUsed",
                            "visible": true
                        },

                        "maximumTimesUsed": {
                            "type": "NonNegativeInt",
                            "keyRaw": "maximumTimesUsed",
                            "nullable": true,
                            "visible": true
                        },

                        "usesPerUser": {
                            "type": "NonNegativeInt",
                            "keyRaw": "usesPerUser",
                            "nullable": true,
                            "visible": true
                        },

                        "serviceId": {
                            "type": "NonNegativeInt",
                            "keyRaw": "serviceId",
                            "nullable": true,
                            "visible": true
                        },

                        "priceAdjustment": {
                            "type": "String",
                            "keyRaw": "priceAdjustment",
                            "visible": true
                        },

                        "isPercentage": {
                            "type": "Boolean",
                            "keyRaw": "isPercentage",
                            "visible": true
                        },

                        "active": {
                            "type": "Boolean",
                            "keyRaw": "active",
                            "visible": true
                        },

                        "firstJobOnly": {
                            "type": "Boolean",
                            "keyRaw": "firstJobOnly",
                            "visible": true
                        },

                        "isValid": {
                            "type": "Boolean",
                            "keyRaw": "isValid",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "couponId": "NonNegativeInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=f9d058da8644e125d1964f8480d87b97fc3736238acd7581a61edc1073ce7233";