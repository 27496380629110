import template from './job-time-picker.html'
import { v4 as uuid } from '@lukeed/uuid'
// import { getHours } from 'date-fns'

//Ractive components
import makeSelect from '@isoftdata/select'

export default function createJobTimePickerComponent() {
	return Ractive.extend({
		template,
		isolated: true,
		data() {
			return {
				id: uuid(),
				label: 'When will you perform this job?',
				selectedDateTime: '',
				validJobHours: {},
				disabled: false,
			}
		},
		components: {
			itSelect: makeSelect({ twoway: true, lazy: false }),
		},
		computed: {
			allValidJobDays() {
				return Object.keys(this.get('validJobHours')?.days || {})
			},
			selectedDayHours() {
				const selectedDay = this.get('jobDay')
				const validJobHours = this.get('validJobHours')

				return validJobHours?.days?.[selectedDay] || []
			},
		},
		attributes: {
			required: [ 'validJobHours', 'selectedDateTime', 'jobDay' ],
			optional: [ 'id', 'label', 'timeRange', 'disabled' ],
		},
	})
}
