import type { WritableDeep } from 'type-fest'
import type { AppContext } from 'types/common'
import type { Column } from '@isoftdata/svelte-table'
import { graphql, LoadAdminOnboardingApplications$result } from '$houdini'

import component from './Onboarding.svelte'

export type PaginationInfo = LoadAdminOnboardingApplications$result['onboardingApplications']['info']
export type Region = LoadAdminOnboardingApplications$result['regions']['data'][0]
export type Provider = LoadAdminOnboardingApplications$result['onboardingApplications']['data'][0]['provider']
export type OnboardingApplication = WritableDeep<
	LoadAdminOnboardingApplications$result['onboardingApplications']['data'][0] & {
		gustoInviteSent: boolean
		gustoAccountApproved: boolean
		providerFullName: string | null
		providerEmail: string
		providerPhoneNumber: string | null
		providerHomeRegion?: string
		providerAddress: string
	}
>

export default ({ mediator, stateRouter }: AppContext) => {
	stateRouter.addState({
		name: 'app.admin.provider.onboarding',
		route: 'onboarding',
		template: {
			svelte: true,
			component,
			options: {},
		},

		async resolve(_data, _parameters) {
			const pageSize = 1000 //This is by request of the PM
			const pageNumber = 1
			const { data } = await onboardingApplicationsQuery.fetch({
				variables: {
					servicesFilter: {
						allowNewApplication: true,
					},
					orderBy: ['ID_DESC'],
					pagination: {
						pageSize,
						pageNumber,
					},
				},
			})
			if (!data) {
				throw new Error('No data')
			}

			const computedColumns: Column[] = [
				{ property: 'created', name: 'Created', align: 'center' },
				{ property: 'status', name: 'Onboarding Status', align: 'center' },
				{ property: 'providerFullName', name: 'Name', minWidth: '150px' },
				{ property: 'providerEmail', name: 'Email', sortType: 'ALPHA_NUM' },
				{ property: 'providerPhoneNumber', name: 'Phone', sortType: false },
				{ property: 'providerHomeRegion', name: 'Home Region', align: 'center' },
				{ property: 'providerAddress', name: 'Address', sortType: 'ALPHA_NUM' },
				{ property: 'shirtSize', name: 'T-shirt', align: 'center' },
				{ property: 'hasLiabilityInsurance', name: 'Insurance', align: 'center' },
				{ property: 'acceptedProviderAgreement', name: 'Agreement', align: 'center' },
				{ property: 'transportation', name: 'Transportation', align: 'center', sortType: false },
				{ property: 'driversLicense', name: `Driver's License`, align: 'center', sortType: false },
			]

			const regions = data.regions.data
			const services = data.services.data
			const onboardingApplications = data.onboardingApplications.data.map(application => {
				return {
					...application,
					gustoInviteSent: application.status === 'SENT_HRIS_INVITE' || application.status === 'HRIS_ACCOUNT_APPROVED' || application.status === 'COMPLETED',
					gustoAccountApproved: application.status === 'HRIS_ACCOUNT_APPROVED' || application.status === 'COMPLETED',
					providerFullName: application.provider.userAccount.fullName,
					providerEmail: application.provider.userAccount.email,
					providerPhoneNumber: application.provider.userAccount.mobile,
					providerHomeRegion: application.provider.homeRegion?.name,
					providerAddress: `${application.provider.street} ${application.provider.city} ${application.provider.state} ${application.provider.zip}`,
				}
			})

			const providerPaginationInfo = data.onboardingApplications.info ?? { totalItems: 0, totalPages: 1, pageSize }

			// IMO, I think we should just get all active services and display them, no need to get from the union of all providers' services
			const serviceColumns: Column[] = services.map(service => {
				return {
					property: service.codeName,
					name: service.name,
					id: service.id,
					sortType: false,
					align: 'center',
					title: service.name,
				}
			})

			const actionColumns: Column[] = [
				{ property: 'inWaitlist', name: 'Waitlist', align: 'center' },
				{ property: 'review', name: 'Review', align: 'center', sortType: false },
				{ property: 'gustoInviteSent', name: 'Gusto Invited', align: 'center' },
				{ property: 'gustoAccountApproved', name: 'Gusto Approved', align: 'center' },
				{ property: 'activateProvider', name: 'Activate', align: 'center', sortType: false },
			]

			computedColumns.push(...serviceColumns, ...actionColumns)

			return {
				providerPaginationInfo,
				onboardingApplications,
				computedColumns,
				serviceColumns,
				pageSize,
				pageNumber,
				regions,
				services,
				cities: data.getUniqueOnboardingFilters.cities ?? [],
				states: data.getUniqueOnboardingFilters.states ?? [],
				zipCodes: data.getUniqueOnboardingFilters.zips ?? [],
			}
		},
	})
}

const onboardingApplicationsQuery = graphql(`
	query LoadAdminOnboardingApplications($servicesFilter: ServiceFilter, $applicationFilter: OnboardingApplicationFilter, $pagination: PaginatedInput, $orderBy: [ProviderApplicationOrderBy!]) {
		services(filter: $servicesFilter) {
			data {
				id
				name
				codeName
				description
				active
			}
		}
		regions(pagination: { pageNumber: 1, pageSize: 0 }) {
			data {
				id
				name
				status
			}
		}
		getUniqueOnboardingFilters {
			cities
			states
			zips
		}
		onboardingApplications: providerOnboardingApplications(filter: $applicationFilter, pagination: $pagination, orderBy: $orderBy) {
			...ProviderOnboardingApplicationFields
		}
	}
`)

graphql(`
	fragment ProviderOnboardingApplicationFields on ProviderOnboardingApplicationResponse {
		data {
			id
			providerId
			status
			created
			completed
			driversLicensePictureFile {
				path
			}
			driverLicenseStatus
			driverLicenseRejectMessage
			transportationPictureFile {
				path
			}
			transportationStatus
			transportationRejectMessage
			acceptedProviderAgreement
			hasLiabilityInsurance
			inWaitlist
			rejectMessage
			serviceApplications: providerServiceApplications {
				id
				serviceId
				status
				rejectMessage
				service {
					name
				}
				equipmentPictureFile {
					path
				}
			}
			provider {
				id
				status
				city
				country
				county
				state
				street
				zip
				shirtSize
				homeRegion {
					id
					name
					status
				}
				profilePictureFile {
					path
				}
				userAccount {
					id
					firstName
					lastName
					fullName
					email
					created
					mobile
				}
			}
		}
		info {
			totalItems
			totalPages
			pageSize
		}
	}
`)

export const updateProviderServiceAdminMutation = graphql(`
	mutation UpdateProviderServiceApplicationStatus($status: ProviderServiceApplicationStatus!, $providerServiceApplicationId: Float!, $rejectMessage: String) {
		updateProviderServiceApplicationStatus(status: $status, providerServiceApplicationId: $providerServiceApplicationId, rejectMessage: $rejectMessage) {
			id
			status
			rejectMessage
			providerApplicationId
			providerApplication {
				status
			}
		}
	}
`)

export const updateProviderTransportationStatusAdminMutation = graphql(`
	mutation UpdateProviderOnboardingApplicationTransportationStatus($status: PictureFileStatus!, $providerOnboardingApplicationId: PositiveInt!, $rejectMessage: String) {
		updateProviderOnboardingApplicationTransportationStatus(status: $status, providerOnboardingApplicationId: $providerOnboardingApplicationId, rejectMessage: $rejectMessage) {
			id
			status
			transportationStatus
			transportationRejectMessage
		}
	}
`)

export const updateProviderDriverLicenseStatusAdminMutation = graphql(`
	mutation UpdateProviderOnboardingApplicationDriversLicenseStatus($status: PictureFileStatus!, $providerOnboardingApplicationId: PositiveInt!, $rejectMessage: String) {
		updateProviderOnboardingApplicationDriversLicenseStatus(status: $status, providerOnboardingApplicationId: $providerOnboardingApplicationId, rejectMessage: $rejectMessage) {
			id
			status
			driverLicenseStatus
			driverLicenseRejectMessage
		}
	}
`)

export const updateProviderOnboardingApplicationStatusMutation = graphql(`
	mutation UpdateProviderOnboardingApplicationStatus($status: ProviderApplicationStatus!, $providerOnboardingApplicationId: PositiveInt!, $rejectMessage: String) {
		updateProviderOnboardingApplicationStatus(status: $status, providerOnboardingApplicationId: $providerOnboardingApplicationId, rejectMessage: $rejectMessage) {
			id
			status
			rejectMessage
		}
	}
`)

export const setProviderOnboardingApplicationWaitlistMutation = graphql(`
	mutation SetProviderApplicationWaitlist($moveToWaitlist: Boolean!, $providerOnboardingApplicationId: PositiveInt!) {
		setProviderApplicationWaitlist(moveToWaitlist: $moveToWaitlist, providerOnboardingApplicationId: $providerOnboardingApplicationId) {
			id
			inWaitlist
		}
	}
`)

export const onboardingApplicationQuery = graphql(`
	query ProviderOnboardingApplications($filter: OnboardingApplicationFilter, $pagination: PaginatedInput, $orderBy: [ProviderApplicationOrderBy!]) {
		providerOnboardingApplications(filter: $filter, pagination: $pagination, orderBy: $orderBy) {
			...ProviderOnboardingApplicationFields
		}
	}
`)

export const completeProviderOnboardingMutation = graphql(`
	mutation CompleteProviderOnboardingApplication($providerOnboardingApplicationId: PositiveInt!) {
		completeProviderOnboardingApplication(providerOnboardingApplicationId: $providerOnboardingApplicationId) {
			id
			completed
			status
		}
	}
`)
