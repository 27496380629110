/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import sessionStore from 'stores/session'
import type { ClientSession } from 'utility/login-and-save-session'
import type { Colors } from '@isoftdata/utility-bootstrap'
type FetchFunction = (query: string, variables: unknown, fetchOptions: FetchOptions) => Promise<unknown>

export interface Mediator<P extends MediatorProviders = MediatorProviders> {
	provide: <T extends keyof P>(name: T, fn: P[T]) => () => void
	call: <T extends keyof P>(name: T, ...args: Parameters<P[T]>) => ReturnType<P[T]>
}

// This is just the "global" providers that are available to all states.
// For "local" providers, consider handling the TS types within that state itself.
export interface MediatorProviders {
	showMessage: (options: { color?: Colors; time?: false | number; heading: string; message?: string; dismissable?: boolean }) => void
	hideMessage: () => void
	apiFetch: FetchFunction
	rawFetch: FetchFunction
	showError: (error: Error, options: Record<string, unknown>) => void
	getServiceWorkerRegistration: () => ServiceWorkerRegistration | null
}
export interface FetchData {
	apiUrl: string
	mediator: Mediator
}
export interface FetchOptions {
	method?: string
	headers?: Record<string, string>
}

interface HeaderStrings {
	[key: string]: string
}

/*interface GeneralObject {
	[key: string]: never
}*/

export default function ({ apiUrl, mediator }: FetchData): void {
	let session: ClientSession | Record<string, never> = {}
	sessionStore.subscribe(value => {
		session = value
	})
	async function rawFetch(query: string, variables: unknown, fetchOptions: FetchOptions = {}) {
		const headers = getNonNullishProps({
			'auth-token': session?.authToken ?? '',
			'apollographql-client-name': 'LawnHiroWeb',
			'apollographql-client-version': '__buildVersion__',
			'Content-Type': 'application/json',
			...(fetchOptions.headers ?? {}),
		})

		const res = await fetch(apiUrl, {
			method: fetchOptions.method ?? 'POST',
			headers,
			body: JSON.stringify({ query, variables }),
		})

		return await res.json()
	}

	async function gqlFetch(query: string, variables: unknown, fetchOptions: FetchOptions = {}) {
		const payload = await rawFetch(query, variables, fetchOptions)

		if (payload.error) {
			throw payload.error
		}
		if (payload.errors) {
			if (Array.isArray(payload.errors)) {
				throw payload.errors[0]
			}
			throw payload.errors
		}
		return payload.data
	}

	mediator.provide('rawFetch', rawFetch)

	mediator.provide('apiFetch', gqlFetch)
}

//http headers with values of null or undefined get mutated to string 'null' or 'undefined'
//this function will omit any props whos values are nullish from the return object
//TODO: move to a shared utility lib?
const getNonNullishProps = (obj: HeaderStrings): HeaderStrings => {
	const newObj: HeaderStrings = {}
	for (const key in obj) {
		if (!(obj[key] === undefined || obj[key] === null)) {
			newObj[key] = obj[key]
		}
	}

	return newObj
}
