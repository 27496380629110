import component from './Zone.svelte'

import { AppContext } from 'types/common'

import { loadZones, loadRegions, loadStates } from 'utility/zones-helper'

export default ({ stateRouter }: AppContext) => {
	stateRouter.addState({
		name: 'app.admin.zone',
		route: 'zone',
		template: {
			svelte: true,
			component,
			options: {},
		},
		async resolve(_data, _parameters) {
			//TODO: make final query load all the required data in one go rather than three
			const zones = await loadZones(undefined, {
				pageNumber: 1,
				pageSize: 0,
			})

			const regions = await loadRegions({
				pageNumber: 1,
				pageSize: 0,
			}) // This is temporary, and will be removed when all regions are no longer orphaned

			const states = await loadStates()

			return {
				zones,
				regions,
				states,
			}
		},
	})
}
