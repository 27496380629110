<script lang="ts">
	import type { SvelteAsr } from 'types/common'
	import toTitleCase from 'to-title-case'

	export let asr: SvelteAsr
	$: activeChildState = toTitleCase(asr.getActiveState().name.split('.').pop()) ?? ''
</script>

<div class="card mb-3">
	<div class="card-header">
		<div>
			<h3>
				<i class="fas fa-user-helmet-safety"></i>
				Admin
				{#if activeChildState}
					> {activeChildState}
				{/if}
			</h3>
		</div>
	</div>
	<div class="card-body">
		<uiView></uiView>
	</div>
</div>
