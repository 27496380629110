export default {
    "name": "RegionHelperRegionMetricData",
    "kind": "HoudiniFragment",
    "hash": "0e87e0511231e7e06f0947dcb32a8032256c1b67765851d257902e47d53a9f8d",

    "raw": `fragment RegionHelperRegionMetricData on RegionMetricData {
  numberOfActiveProviders
  numberOfQuotes
  numberOfJobsCompleted
  numberOfPerformingProviders
  conversion
  revenue
  __typename
}
`,

    "rootType": "RegionMetricData",
    "stripVariables": [],

    "selection": {
        "fields": {
            "numberOfActiveProviders": {
                "type": "NonNegativeInt",
                "keyRaw": "numberOfActiveProviders",
                "visible": true
            },

            "numberOfQuotes": {
                "type": "NonNegativeInt",
                "keyRaw": "numberOfQuotes",
                "visible": true
            },

            "numberOfJobsCompleted": {
                "type": "NonNegativeInt",
                "keyRaw": "numberOfJobsCompleted",
                "visible": true
            },

            "numberOfPerformingProviders": {
                "type": "NonNegativeInt",
                "keyRaw": "numberOfPerformingProviders",
                "visible": true
            },

            "conversion": {
                "type": "String",
                "keyRaw": "conversion",
                "visible": true
            },

            "revenue": {
                "type": "String",
                "keyRaw": "revenue",
                "visible": true
            },

            "__typename": {
                "type": "String",
                "keyRaw": "__typename",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    }
};

"HoudiniHash=a1382758e93f94324fff7d3105e07de5e6fda80a56c8283d5bfa0abf2b43194d";