export default {
    "name": "MakeServiceOffer",
    "kind": "HoudiniMutation",
    "hash": "834337539d9e341605ce640fd665b5be73cde8debdd3cf3798c3fc435bd95488",

    "raw": `mutation MakeServiceOffer($newServiceOffer: NewServiceOffer!) {
  newServiceOffer(newServiceOffer: $newServiceOffer) {
    ...RegionHelperServiceOfferData
    id
  }
}

fragment RegionHelperServiceOfferData on ServiceOffer {
  id
  active
  service {
    id
    name
  }
  regionId
  chargeMinimum
  chargeMinimum
  chargePerSquareFoot
  chargeFixed
  costPercent
  serviceId
  timeRange
  __typename
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "newServiceOffer": {
                "type": "ServiceOffer",
                "keyRaw": "newServiceOffer(newServiceOffer: $newServiceOffer)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "active": {
                            "type": "Boolean",
                            "keyRaw": "active",
                            "visible": true
                        },

                        "service": {
                            "type": "Service",
                            "keyRaw": "service",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "regionId": {
                            "type": "PositiveInt",
                            "keyRaw": "regionId",
                            "visible": true
                        },

                        "chargeMinimum": {
                            "type": "String",
                            "keyRaw": "chargeMinimum",
                            "visible": true
                        },

                        "chargePerSquareFoot": {
                            "type": "String",
                            "keyRaw": "chargePerSquareFoot",
                            "visible": true
                        },

                        "chargeFixed": {
                            "type": "String",
                            "keyRaw": "chargeFixed",
                            "nullable": true,
                            "visible": true
                        },

                        "costPercent": {
                            "type": "String",
                            "keyRaw": "costPercent",
                            "visible": true
                        },

                        "serviceId": {
                            "type": "PositiveInt",
                            "keyRaw": "serviceId",
                            "visible": true
                        },

                        "timeRange": {
                            "type": "String",
                            "keyRaw": "timeRange",
                            "visible": true
                        },

                        "__typename": {
                            "type": "String",
                            "keyRaw": "__typename",
                            "visible": true
                        }
                    },

                    "fragments": {
                        "RegionHelperServiceOfferData": {
                            "arguments": {}
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "newServiceOffer": "NewServiceOffer"
        },

        "types": {
            "NewServiceOffer": {
                "active": "Boolean",
                "chargeFixed": "String",
                "chargeMinimum": "String",
                "chargePerSquareFoot": "String",
                "regionId": "PositiveInt",
                "serviceId": "PositiveInt",
                "timeRange": "String"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=dae5e76f7c6aac73f70f2f8b7b619a585e04962abb496458bf3ef8153801c13e";