import loadCustomerResidences from 'utility/load-customer-residences'
// import formatCityStateZip from 'utility/format/format-city-state-zip'
import formatDateString from 'utility/format/format-date-string'
import formatCurrency from 'utility/format/format-currency'
import { toDisplay as percentToDisplay } from 'utility/format/format-percent'
import { subDays, differenceInDays } from 'date-fns'
import template from './new-order.ractive.html'
import gTagHelper from 'utility/gtag-helper'
import { klona } from 'klona'
import getMowingEndOfSeasonDate from 'utility/get-end-of-season-date'
import ractiveTransitionsSlide from 'ractive-transitions-slide'

//Ractive components
import makeModal from '@isoftdata/modal'
import makeButton from '@isoftdata/button'
import makeSelect from '@isoftdata/select'
import makeOrderScheduleSelection from 'components/order-schedule-selection'
import makeCustomerAddresses from 'components/customer-addresses'
import makeNewCardEntry from 'components/credit-card-entry'
import makeCardPicker from 'components/card-picker'
import makeInput from '@isoftdata/input'
import makeServiceSelection from 'components/select-service'
import makeCustomerQuoteOptions from 'components/customer-quote-options'

const TODAY = (new Date()).toISOString().slice(0, 10)

const defaultUpdateCreditCardModalState = {
	show: false,
	selectedCardId: null,
}

const defaultOrderPriceState = {
	subtotal: null,
	adjustment: null,
	total: null,
}

const couponQuery = `#graphql
	coupon {
		code
		priceAdjustment
		isPercentage
	}
`

const servicesQuery = `#graphql
	query Services($filter: ServiceFilter) {
		services(filter: $filter) {
			data {
				id
				name
				description
				warning
				codeName
				allowSubscription
				maximumDaysToPerform
			}
		}
	}
`

const serviceSchedulePeriodsQuery = `#graphql
	query ServiceSchedulePeriods($filter: ServiceSchedulePeriodFilter, $serviceId: PositiveInt) {
		serviceSchedulePeriods(filter: $filter) {
			data {
				id
				serviceId
				serviceSchedulePeriod
				status
				tag {
					id
					name
					tagPricingForService(serviceId: $serviceId) {
						id
						postPriceAdjustment
						priceAdjustment
						pricingType
						serviceId
						tagId
					}
				}
			}
		}
	}
`

const unansweredResidenceQuestionsQuery = `#graphql
	query UnansweredResidenceQuestions($residenceId: PositiveInt!, $serviceId: PositiveInt!) {
  		unansweredResidenceQuestions(residenceId: $residenceId, serviceId: $serviceId) {
			question
			answers {
				id
				answer
			}
			services {
				id
			}
		}
	}
`
const addOnsQuery = `#graphql
	query Questions($questionFilter: QuestionFilter, $serviceId: PositiveInt) {
		questions(questionFilter: $questionFilter) {
			data {
				id
				question
				description
				questionDataType
				answers {
					id
					answer
					tag {
						entityType
						tagPricingForService(serviceId: $serviceId) {
							id
							postPriceAdjustment
							priceAdjustment
							pricingType
							serviceId
						}
					}
				}
			}
		}
	}
`

const jobDupeCheckQuery = `#graphql
	query JobDupeCheck($requestedDate: Date!, $residenceId: PositiveInt!, $serviceId: PositiveInt!) {
		isJobServiceDuplicate(requestedDate: $requestedDate, residenceId: $residenceId, serviceId: $serviceId)
	}
`

export default ({ mediator, stateRouter }) => {
	async function loadPriceForSelectedResidence(residenceId, serviceId, answerIds, couponCode, schedulePeriodId, selectedAddOnAnswerIds) {
		const allAnswerIds = answerIds.concat(selectedAddOnAnswerIds ?? [])

		const priceQuoteForResidenceQuery = `#graphql
			query Residence($residenceId: PositiveInt!, $answerIds: [PositiveInt!], $serviceId: PositiveInt, $code: NonEmptyString, $schedulePeriodId: PositiveInt) {
				residence(id: $residenceId) {
					parcel {
						priceQuote(answerIds: $answerIds, serviceId: $serviceId, code: $code, schedulePeriodId: $schedulePeriodId) {
							error
							price
							cost
							adjustment
							total
							${couponCode ? couponQuery : ''}
						}
					}
				}
			}
		`

		const { residence: residencePriceWithTags } = await mediator.call('apiFetch', priceQuoteForResidenceQuery, {
			residenceId,
			serviceId,
			answerIds: allAnswerIds,
			code: couponCode,
			schedulePeriodId,
		})

		return residencePriceWithTags
	}

	stateRouter.addState({
		name: 'app.new-order',
		route: 'new-order',
		querystringParameters: [ 'residenceId', 'scheduleType' ],
		defaultParameters: {
			scheduleType: 'ASAP',
		},
		template: {
			template,
			transitions: {
				slide: ractiveTransitionsSlide,
			},
			components: {
				itModal: makeModal(),
				itButton: makeButton(),
				itSelect: makeSelect({ twoway: true, lazy: false }),
				orderScheduleSelection: makeOrderScheduleSelection(),
				customerAddresses: makeCustomerAddresses(mediator),
				newOrderCreditCardEntry: makeNewCardEntry(),
				newCardEntry: makeNewCardEntry(),
				cardPicker: makeCardPicker(),
				itInput: makeInput({ twoway: true, lazy: 200 }),
				serviceSelection: makeServiceSelection({ twoway: true, lazy: true }),
				customerQuoteOptions: makeCustomerQuoteOptions({ twoway: true, lazy: true }),
			},
			computed: {
				selectedService() {
					const selectedServiceId = this.get('selectedServiceId')
					return this.getServiceById(selectedServiceId)
				},
				selectedResidence() {
					const selectedResidenceId = this.get('selectedResidenceId')

					if (selectedResidenceId) {
						return this.getCustomerResidenceById(selectedResidenceId)
					}
				},
				displayDateForService() {
					const selectedDate = this.get('selectedDate')
					const scheduleSelection = this.get('scheduleSelection')

					return scheduleSelection === 'ASAP' ? new Date().toLocaleDateString() : formatDateString(selectedDate)
				},
				async isDuplicateRequestedJob() {
					const ractive = this
					const selectedResidenceId = ractive.get('selectedResidenceId')
					const selectedServiceId = ractive.get('selectedServiceId')
					const selectedDate = ractive.get('selectedDate')
					const scheduleSelection = ractive.get('scheduleSelection')

					if (selectedResidenceId && selectedServiceId) {
						return await ractive.checkForDuplicateServiceResidence(selectedServiceId, selectedResidenceId, (scheduleSelection === 'SPECIFIC_DAY') ? new Date(selectedDate).toISOString().substring(0, 10) : new Date().toISOString().substring(0, 10))
					}
					return false
				},
				selectedAddOnAnswerIds() {
					const ractive = this
					const selectedAddOnIds = ractive.get('selectedAddOnIds')
					const selectedServiceAddOns = ractive.get('selectedServiceAddOns')
					const filteredQuestions = selectedServiceAddOns.reduce((acc, question) => {
						if (selectedAddOnIds.includes(question.id)) {
							acc.push(question.answers[0].id)
						}
						return acc
					}, [])
					return filteredQuestions
				},
				orderSummary() {
					const ractive = this
					const scheduleSelection = this.get('scheduleSelection')
					const selectedRecurringSchedulePeriodId = ractive.get('selectedRecurringSchedulePeriodId')
					let orderPrice = ractive.get('orderPrice')
					const selectedAddOnIds = ractive.get('selectedAddOnIds')
					let selectedAddOn = selectedAddOnIds.map(id => ractive.getAddOnById(id))
					let adjustment = Number(orderPrice?.adjustment) ?? ''
					let errorMessage = ractive.get('errorMessage')

					if (adjustment > 0) {
						adjustment = `- ${formatCurrency(adjustment)}`
					} else if (adjustment < 0) {
						adjustment = formatCurrency(-adjustment)
					} else if (adjustment === 0) {
						adjustment = ''
					}

					const orderSummaryItemList = {
						service: { label: 'Service', value: ractive.get('selectedService.name') },
						addOns: { label: 'Add-Ons', value: selectedAddOn, hidden: !selectedAddOn.length },
						address: { label: 'Address', value: ractive.get('selectedResidence.street') },
						recurringSchedule: {},
						date: { label: 'Date', value: ractive.get('displayDateForService') },
						lastServiceDate: {},
						subTotal: { label: 'Subtotal', value: formatCurrency(orderPrice?.subtotal), currency: true, hidden: errorMessage },
						adjustment: { label: 'Discount Savings', value: adjustment, important: true, currency: true },
						total: { label: 'Total', value: formatCurrency(orderPrice?.total), important: true, currency: true, hidden: errorMessage },
					}

					if (scheduleSelection === 'RECURRING_SCHEDULE') {
						orderSummaryItemList.recurringSchedule = {
							label: 'Recurring',
							value: `Every ${ractive.getRecurringSchedulePeriodById(selectedRecurringSchedulePeriodId).serviceSchedulePeriod} days`,
						}
						orderSummaryItemList.date = {
							label: 'First Service Date',
							value: formatDateString(TODAY),
						}
						orderSummaryItemList.lastServiceDate = {
							label: 'Last Service Date',
							value: formatDateString(ractive.getLastServiceDate()),
						}
						orderSummaryItemList.adjustment.label = 'Recurring Savings'
					}

					return orderSummaryItemList
				},
				isNonZeroPriceChange() {
					const orderSummary = this.get('orderSummary')
					return orderSummary?.subTotal?.value !== orderSummary?.total?.value
				},
				servicesWithAddOns() {
					const allServices = this.get('availableServices')
					const selectedServiceId = this.get('selectedServiceId')
					const selectedServiceAddOns = this.get('selectedServiceAddOns')

					return allServices.map(service => {
						if (service.id === selectedServiceId) {
							const formattedAddOns = selectedServiceAddOns.map(addOn => {
								const pricingType = addOn?.answers[0]?.tag?.tagPricingForService?.pricingType
								const priceAdjustment = addOn?.answers[0]?.tag?.tagPricingForService?.priceAdjustment

								let formattedPriceAdjustment
								if (pricingType === 'FLAT') {
									formattedPriceAdjustment = `+${formatCurrency(priceAdjustment)}`
								} else if (pricingType === 'PERCENT') {
									formattedPriceAdjustment = `+${percentToDisplay(priceAdjustment)}%`
								} else {
									formattedPriceAdjustment = ''
								}

								return {
									...addOn,
									formattedLabel: `${addOn?.question} ${formattedPriceAdjustment}`,
								}
							})
							return {
								...service,
								addOns: formattedAddOns,
							}
						}
						return service
					})
				},
			},
			getServiceById(id) {
				return this.get('availableServices').find(({ id: serviceId }) => serviceId === id) || {}
			},
			getCustomerResidenceById(id) {
				return this.get('customerResidences').find(({ id: residenceId }) => residenceId === id) || null
			},
			getRecurringSchedulePeriodById(id) {
				return this.get('schedulePeriods').find(({ id: recurringSchedulePeriodId }) => recurringSchedulePeriodId === id) || {}
			},
			getAddOnById(id) {
				return this.get('selectedServiceAddOns').find(({ id: addOnId }) => addOnId === id) || {}
			},
			getLastServiceDate() {
				const scheduleStartDate = new Date(TODAY)
				const endOfSeasonDate = new Date(this.get('selectedServiceEndOfSeasonDate'))

				const remainingSeasonDays = differenceInDays(endOfSeasonDate, scheduleStartDate)

				const schedulePeriodId = this.get('selectedRecurringSchedulePeriodId')
				const scheduleInterval = this.getRecurringSchedulePeriodById(schedulePeriodId).serviceSchedulePeriod

				const daysPastEndOfSeason = remainingSeasonDays % (scheduleInterval)

				const finalServiceDate = subDays(endOfSeasonDate, daysPastEndOfSeason)
				return finalServiceDate.toISOString().slice(0, 10)
			},
			async openUpdateCreditCardModal(cardId) {
				await this.set({
					updateCreditCardModal: {
						...klona(defaultUpdateCreditCardModalState),
						selectedCardId: cardId,
						show: true,
					},
				})
			},
			async getSelectedResidenceAndServiceInfo(selectedResidence, selectedService) {
				// get the existing answers for the selected residence and service
				const selectedResidenceExistingAnswerIds = selectedResidence?.answers?.reduce((acc, answer) => {
					// filter down to the answer's question's services that has the selected service, since one question can be in multiple services
					const existingAnswerForSelectedService = answer?.question?.services?.filter(service => service?.id === selectedService.id)

					if (existingAnswerForSelectedService.length > 0) {
						acc.push(answer.id)
					}
					// only return the answer's ids
					return acc
				}, []) ?? []

				const { unansweredResidenceQuestions } = await mediator.call('apiFetch', unansweredResidenceQuestionsQuery, { residenceId: selectedResidence.id, serviceId: selectedService.id })

				let activeSchedulePeriods = []

				if (selectedService?.allowSubscription) {
					const { serviceSchedulePeriods } = await mediator.call('apiFetch', serviceSchedulePeriodsQuery, { filter: { serviceId: selectedService.id, status: 'ACTIVE' }, serviceId: selectedService.id })
					activeSchedulePeriods = serviceSchedulePeriods?.data
				}

				let addOns
				const { questions } = await mediator.call('apiFetch', addOnsQuery, {
					questionFilter: {
						serviceId: selectedService.id,
						questionDataType: 'BOOLEAN',
					},
					serviceId: selectedService.id,
				})

				if (questions?.data?.length > 0) {
					// sub services will only have one answer option, 'yes', so we can use the first one
					// if sub services are not answered, it will be answered as 'no'
					// like a checkbox, if it's not checked, it's false
					addOns = questions?.data?.filter(question => question.answers?.[0]?.tag?.entityType === 'JOB')
				}

				await this.set({
					selectedServiceAddOns: addOns || [],
					selectedAddOnIds: [],
					unansweredResidenceQuestions,
					selectedResidenceAnswerIds: [],
					errorMessage: '',
					selectedResidenceExistingAnswerIds,
					scheduleSelection: 'ASAP',
					schedulePeriods: activeSchedulePeriods || [],
					selectedRecurringSchedulePeriodId: activeSchedulePeriods?.[0]?.id || null,
				})

				if (unansweredResidenceQuestions.length === 0) {
					await this.getPriceQuote()
				} else {
					this.set({
						errorMessage: 'Please answer all questions before continuing.',
						orderPrice: klona(defaultOrderPriceState),
					})
					this.clearCouponEntry()
				}
			},
			async getPriceQuote(event) {
				const ractive = this
				event?.preventDefault?.()

				const residenceId = ractive.get('selectedResidenceId')
				const couponCode = ractive.get('couponCode') || null
				const serviceId = ractive.get('selectedServiceId')
				const selectedResidenceExistingAnswerIds = ractive.get('selectedResidenceExistingAnswerIds')
				const selectedResidenceAnswerIds = ractive.get('selectedResidenceAnswerIds')
				const answerIds = selectedResidenceExistingAnswerIds.concat(selectedResidenceAnswerIds)
				const selectedAddOnAnswerIds = ractive.get('selectedAddOnAnswerIds')
				const schedulePeriodId = ractive.get('scheduleSelection') === 'RECURRING_SCHEDULE' ? ractive.get('selectedRecurringSchedulePeriodId') : null

				if (residenceId && serviceId) {
					try {
						const residencePriceQuote = await loadPriceForSelectedResidence(residenceId, serviceId, answerIds, couponCode, schedulePeriodId, selectedAddOnAnswerIds)

						await ractive.set({
							orderPrice: {
								subtotal: residencePriceQuote?.parcel?.priceQuote?.price,
								adjustment: residencePriceQuote?.parcel?.priceQuote?.adjustment,
								total: residencePriceQuote?.parcel?.priceQuote?.total,
							},
						})

						if (residencePriceQuote?.parcel?.priceQuote?.coupon) {
							const isPercentage = residencePriceQuote?.parcel?.priceQuote?.coupon?.isPercentage
							const priceAdjustment = residencePriceQuote?.parcel?.priceQuote?.coupon?.priceAdjustment
							await ractive.set({
								appliedCoupon: {
									couponCode: residencePriceQuote?.parcel?.priceQuote?.coupon?.code,
									displayCouponDiscount: isPercentage ? `${priceAdjustment * 100 }%` : formatCurrency(`${priceAdjustment}`),
								},
							})
							ractive.find('#removeCoupon')?.focus?.()
							ractive.set({
								errorMessage: '',
							})
						}
					} catch (err) {
						console.error(err)
						ractive.set({
							errorMessage: err?.message,
							showRemoveCouponButton: true,
						})
					}
				} else {
					ractive.set({
						errorMessage: 'No address is selected',
						orderPrice: klona(defaultOrderPriceState),
					})
				}
			},
			async addCardToWallet(cardInfo) {
				//They want to save the card to their wallet for future use
				const addCardToCustomerWalletMutation = `#graphql
					mutation AddCardToCustomerWallet($newCard: NewCustomerCard!) {
						addCardToCustomerWallet(newCard: $newCard) {
							id
							lastFour
							expirationMonth
							expirationYear
							cardType
						}
					}
				`

				try {
					const { addCardToCustomerWallet } = await mediator.call('apiFetch', addCardToCustomerWalletMutation, {
						newCard: {
							lastFour: parseInt(cardInfo.cardLast4, 10),
							expirationMonth: parseInt(cardInfo.expiryMonth, 10),
							expirationYear: parseInt(cardInfo.expiryYear, 10),
							token: cardInfo.token,
							cardType: cardInfo.cardType,
						},
					})

					await this.push('cards', addCardToCustomerWallet)
					return addCardToCustomerWallet
				} catch (err) {
					console.error(err)
					alert(err?.message || 'An unknown error occurred while adding your card to your wallet. Please contact LawnHiro support.')
				}
			},
			async updateCardInWallet(cardInfo) {
				const editCardInCustomerWalletMutation = `#graphql
					mutation EditCardInCustomerWallet($cardInfo: editCustomerCard!) {
						editCardInCustomerWallet(cardInfo: $cardInfo) {
							id
							lastFour
							expirationMonth
							expirationYear
							cardType
						}
					}
				`
				const id = this.get('updateCreditCardModal.selectedCardId')

				try {
					const { editCardInCustomerWallet } = await mediator.call('apiFetch', editCardInCustomerWalletMutation, {
						cardInfo: {
							lastFour: parseInt(cardInfo.cardLast4, 10),
							expirationMonth: parseInt(cardInfo.expiryMonth, 10),
							expirationYear: parseInt(cardInfo.expiryYear, 10),
							token: cardInfo.token,
							cardType: cardInfo.cardType,
							id,
						},
					})

					await this.upsert('cards', 'id', editCardInCustomerWallet)
					await this.set({ updateCreditCardModal: klona(defaultUpdateCreditCardModalState) })
				} catch (err) {
					console.error(err)
					alert(err?.message || 'An unknown error occurred while adding your card to your wallet. Please contact LawnHiro support.')
				}
			},
			async createOrder(cardInfo, customerWalletId) {
				const ractive = this

				if (await ractive.get('isDuplicateRequestedJob')) {
					if (!confirm('You have already requested this type of service for this residence through a Subscription. Would you like to create a duplicate order?')) {
						return
					}
				}

				if (ractive.get('isProcessingJob')) {
					return //just ignore it here because they're already processing a job.
					//Hopefully this handles the cases where someone double clicks a button
				}
				/*
					heartland's fucking submit button is dumb as shit and lets you click it
					multiple times(no disabling) so we have to try handle the case where they clicked it multiple times
					so they at leasy don't get charged multiple times
				*/
				ractive.set({ 'isProcessingJob': true, 'token': cardInfo.token })

				const { selectedServiceId, scheduleSelection, selectedDate, selectedResidenceId, token, specialInstructions, couponCode, selectedResidenceAnswerIds, selectedAddOnAnswerIds } = ractive.get('')
				const answerIds = selectedResidenceAnswerIds.concat(selectedAddOnAnswerIds)

				const createJobMutation = `#graphql
					mutation CreateJob($newJob: NewJobRequest!) {
						createJob(newJob: $newJob) {
							id
							requestedSchedule
							serviceId
							residenceId
							jobStatus
							transaction {
								total
								transactionPayments {
									gatewayMessage
								}
							}
							specialInstructions
						}
					}
				`

				const newServiceScheduleMutation = `#graphql
					mutation NewServiceSchedule($serviceSchedule: NewServiceSchedule!) {
						newServiceSchedule(serviceSchedule: $serviceSchedule) {
							id
							nextJob
							residenceId
						}
					}
				`

				const updateAnswerToResidenceMutation = `#graphql
					mutation UpdateAnswerToResidence($updateResidenceAnswers: UpdateResidenceAnswers!) {
						updateAnswerToResidence(updateResidenceAnswers: $updateResidenceAnswers) {
							id
						}
					}
				`

				try {
					if (selectedResidenceAnswerIds.length > 0) {
						await mediator.call('apiFetch', updateAnswerToResidenceMutation, {
							updateResidenceAnswers: {
								residenceId: selectedResidenceId,
								answerIds: selectedResidenceAnswerIds,
							},
						})
					}

					let orderCreationResponse

					if (scheduleSelection === 'RECURRING_SCHEDULE') {
						const selectedSchedulePeriodId = ractive.get('selectedRecurringSchedulePeriodId')
						const { newServiceSchedule } = await mediator.call('apiFetch', newServiceScheduleMutation, {
							serviceSchedule: {
								serviceId: selectedServiceId,
								residenceId: selectedResidenceId,
								specialInstructions: specialInstructions.trim() || null,
								nextJob: TODAY,
								endOfSchedule: ractive.getLastServiceDate(),
								serviceSchedulePeriodId: selectedSchedulePeriodId,
								scheduleAnswerIds: selectedAddOnAnswerIds,
							},
						})

						orderCreationResponse = newServiceSchedule
					} else {
						const { createJob } = await mediator.call('apiFetch', createJobMutation, {
							newJob: {
								serviceId: selectedServiceId,
								residenceId: selectedResidenceId,
								requestedSchedule: scheduleSelection === 'ASAP' ? (new Date).toISOString().substring(0, 10) : selectedDate,
								...(customerWalletId ? { customerWalletId } : { token }), //if we have a customer wallet id, we don't need a token. Just one or the other is required
								specialInstructions: specialInstructions.trim() || null,
								couponCode,
								jobAnswerIds: answerIds,
							},
						})

						if (createJob.jobStatus === 'FAILED') {
							alert(createJob.transaction.transactionPayments[0].gatewayMessage)
							return
						} else {
							orderCreationResponse = createJob
						}
					}

					const priceGA = Number(orderCreationResponse?.transaction?.total) || ''
					const selectedService = ractive.get('selectedService')

					gtag('event', 'purchase', {
						currency: 'USD',
						value: priceGA,
						items: [{
							item_id: scheduleSelection === 'RECURRING_SCHEDULE' ?? selectedService.codeName,
							item_name: selectedService.name,
							currency: 'USD',
							index: 0,
							price: priceGA,
							quantity: 1,
						}],
					})

					if (scheduleSelection === 'RECURRING_SCHEDULE') {
						stateRouter.go('app.customer.recurring-order', { residenceId: orderCreationResponse.residenceId, scheduleType: 'RECURRING_SCHEDULE' })
					} else {
						//TODO: show some sort of dialog that the job can been created with some of the details
						stateRouter.go('app.order-created', { orderId: orderCreationResponse.id })
					}
				} catch (err) {
					if (err?.extensions?.code === 'INTERNAL_SERVER_ERROR') {
						alert('An unknown error occurred while creating your order. Please contact LawnHiro support.')
					} else {
						alert(err?.message || 'An unknown error occurred while creating your order. Please contact LawnHiro support.')
					}
					console.error(err)
				} finally {
					ractive.set({ isProcessingJob: false })
				}
			},
			async removeCoupon() {
				await this.set({
					appliedCoupon: null,
					couponCode: null,
					errorMessage: '',
					showRemoveCouponButton: false,
				})
				await this.getPriceQuote()
				this.find('#couponEntry')?.focus?.()
			},
			async startCouponEntry() {
				await this.set({
					couponCode: '',
				})
				this.find('#couponEntry')?.focus?.()
			},
			async clearCouponEntry() {
				await this.set({
					appliedCoupon: null,
					couponCode: null,
					showRemoveCouponButton: false,
				})
			},
			async checkForDuplicateServiceResidence(serviceId, residenceId, dateToCheck) { //This should be called specifically on the selected service and residence changes
				const duplicate = await mediator.call('apiFetch', jobDupeCheckQuery, {
					requestedDate: dateToCheck,
					serviceId,
					residenceId,
				})
				return duplicate.isJobServiceDuplicate //If there is a duplicate schedule for this service and residence that is active return true else return false
			},
		},
		async resolve(_data, parameters) {
			const session = JSON.parse(localStorage.getItem('session'))

			const activeWalletQuery = `#graphql
				query ActiveWallet {
					session {
						customer {
							activeWallet {
								id
								lastFour
								expirationMonth
								expirationYear
								cardType
							}
						}
					}
				}
			`

			// step 1: get the user's all available residences
			// step 2: get all the available services based on the first residence's region
			// step 3: get the existing tags on the first residence (we need the answers for those tags) by the first available service
			// step 4: get the price for that residence and service with the answerIds (from step 3) so that we can the exact price (with surcharge) for the job
			// (ignore the if there's a question that has not been answered yet)
			// step 5: get the questions for the first available service and the first residence that has not been answered yet

			let availableServices = []
			let selectedServiceId = null
			let errorMessage = ''

			// load all residences that the current user has, without a service selected
			const [ customerResidences, sessionActiveWallet ] = await Promise.all([
				loadCustomerResidences(mediator, session?.userAccountId),
				mediator.call('apiFetch', activeWalletQuery, {}),
			])

			if (customerResidences.length === 0) {
				errorMessage = 'Please add an address to see your price.'
			}

			let selectedResidence = null
			let selectedResidenceId = null
			if (parameters.residenceId) {
				selectedResidenceId = parseInt(parameters.residenceId, 10)
				selectedResidence = customerResidences.find(residence => residence.id === selectedResidenceId)
			} else if (customerResidences.length > 0) {
				const firstActiveResidence = customerResidences.find(residence => residence.region.status === 'ACTIVE')
				selectedResidenceId = firstActiveResidence?.id || null
				selectedResidence = firstActiveResidence || null
			}

			// get the services that are offered in the selected residence's region selectable services
			if (selectedResidence) {
				availableServices = (await mediator.call('apiFetch', servicesQuery, { filter: { regionId: selectedResidence.regionId } })).services?.data
				// we're loading these serially because we're using the first service from the array as the default
				// and we need to pass that service id to get the default price quote for the parcels/residences
				selectedServiceId = availableServices?.[0]?.id || null
			}

			let addOns
			const { questions } = await mediator.call('apiFetch', addOnsQuery, {
				questionFilter: {
					serviceId: selectedServiceId,
					questionDataType: 'BOOLEAN',
				},
				serviceId: selectedServiceId,
			})

			if (questions?.data?.length > 0) {
				// sub services will only have one answer option, 'yes', so we can use the first one
				// if sub services are not answered, it will be answered as 'no'
				// like a checkbox, if it's not checked, it's false
				addOns = questions?.data?.filter(question => question.answers?.[0]?.tag?.entityType === 'JOB')
			}

			let selectedServiceEndOfSeasonDate = null
			if (selectedServiceId === 1) {
				selectedServiceEndOfSeasonDate = getMowingEndOfSeasonDate(selectedServiceId)
			}

			let schedulePeriods = []

			if (availableServices?.[0]?.allowSubscription) {
				const { serviceSchedulePeriods } = await mediator.call('apiFetch', serviceSchedulePeriodsQuery, { filter: { serviceId: selectedServiceId, status: 'ACTIVE' }, serviceId: selectedServiceId })
				schedulePeriods = serviceSchedulePeriods?.data
			}

			// get the questions that has not been answered yet for the selected residence and service
			let initialUnansweredQuestions = []
			let selectedResidenceExistingAnswerIds = []
			let residencePriceWithTags
			if (selectedResidence !== null && selectedServiceId !== null) {
				// get the existing answers for the selected residence and service
				selectedResidenceExistingAnswerIds = selectedResidence?.answers.reduce((acc, answer) => {
					// filter down to the answer's question's services that has the selected service, since one question can be in multiple services
					const existingAnswerForSelectedService = answer?.question?.services?.filter(service => service?.id === selectedServiceId)

					if (existingAnswerForSelectedService.length > 0) {
						acc.push(answer.id)
					}
					// only return the answer's ids
					return acc
				}, []) ?? []

				const { unansweredResidenceQuestions } = await mediator.call('apiFetch', unansweredResidenceQuestionsQuery, { residenceId: selectedResidenceId, serviceId: selectedServiceId })
				if (unansweredResidenceQuestions.length !== 0) {
					errorMessage = 'Please answer all questions before continuing.'
				}
				initialUnansweredQuestions = unansweredResidenceQuestions

				// get the price quote for the selected residence and service with the residence existing tag answers (only ids)
				// the second last argument is coupon code, which we will not have it on initial load, so I just pass null
				if (parameters.scheduleType === 'RECURRING_SCHEDULE') {
					residencePriceWithTags = await loadPriceForSelectedResidence(selectedResidenceId, selectedServiceId, selectedResidenceExistingAnswerIds, null, schedulePeriods?.[0]?.id)
				} else {
					residencePriceWithTags = await loadPriceForSelectedResidence(selectedResidenceId, selectedServiceId, selectedResidenceExistingAnswerIds, null)
				}
			}

			return {
				customerResidences,
				selectedResidenceId, //select the first residence if there is one
				availableServices,
				selectedServiceId,
				selectedServiceAddOns: addOns || [],
				selectedAddOnIds: [],
				specialInstructions: '',
				scheduleSelection: parameters.scheduleType,
				schedulePeriods,
				selectedRecurringSchedulePeriodId: schedulePeriods?.[0]?.id || null,
				selectedDate: new Date().toISOString().substring(0, 10),
				unansweredResidenceQuestions: initialUnansweredQuestions || [],
				selectedResidenceAnswerIds: [],
				allQuestionsAnswered: initialUnansweredQuestions.length === 0,
				selectedResidenceExistingAnswerIds,
				appliedCoupon: null,
				couponCode: null,
				orderPrice: {
					...klona(defaultOrderPriceState),
					adjustment: errorMessage ? null : residencePriceWithTags?.parcel?.priceQuote?.adjustment,
					subtotal: errorMessage ? null : residencePriceWithTags?.parcel?.priceQuote?.price,
					total: errorMessage ? null : residencePriceWithTags?.parcel?.priceQuote?.total,
				},
				session,
				cards: sessionActiveWallet?.session?.customer?.activeWallet || [],
				selectedCardId: sessionActiveWallet?.session?.customer?.activeWallet?.[0]?.id || null,
				isAddingCardToWallet: false,
				saveCard: true,
				token: null,
				isProcessingJob: false,
				errorMessage,
				termsConditionModal: { show: false },
				updateCreditCardModal: klona(defaultUpdateCreditCardModalState),
				showRemoveCouponButton: false,
				selectedServiceEndOfSeasonDate,
			}
		},
		activate(activateContext) {
			const { domApi: ractive } = activateContext

			ractive.on('addressAdded', (_context, residence) => {
				//await ractive.loadCustomerResidences(ractive.get('selectedServiceId'))
				ractive.set({ 'selectedResidenceId': residence.id })
			})

			ractive.on('addressRemoved', (_context, { removedResidenceId, remainingResidences }) => {
				if (removedResidenceId === ractive.get('selectedResidenceId')) {
					ractive.set({ 'selectedResidenceId': remainingResidences[0]?.id || null })
				}
			})

			const { cancel: cancelSelectedServiceIdObserver } = ractive.observe('selectedServiceId', async serviceId => {
				const selectedService = ractive.get('availableServices').find(service => service.id === serviceId)
				const selectedResidenceId = ractive.get('selectedResidenceId')
				const selectedResidence = ractive.getCustomerResidenceById(selectedResidenceId)

				if (selectedResidence !== null) {
					await ractive.getSelectedResidenceAndServiceInfo(selectedResidence, selectedService)

					// google analytics
					const priceGA = ractive.get('orderPrice.total')
					gTagHelper('begin_checkout', {
						currency: 'USD',
						value: priceGA,
						items: [{
							item_id: selectedService.codeName,
							item_name: selectedService.name,
							currency: 'USD',
							index: 0,
							price: priceGA,
							quantity: 1,
						}],
					})
				} else {
					await ractive.set({
						availableServices: [],
						selectedServiceId: null,
						unansweredResidenceQuestions: [],
						selectedResidenceAnswerIds: [],
						errorMessage: 'Please enter a valid address.',
						selectedResidenceExistingAnswerIds: [],
						schedulePeriods: [],
						selectedRecurringSchedulePeriodId: '',
					})
				}
			}, { init: false })

			const { cancel: cancelSelectedResidenceIdObserver } = ractive.observe('selectedResidenceId', async residenceId => {
				const selectedResidence = ractive.getCustomerResidenceById(residenceId)
				if (selectedResidence !== null) {
					const { services: availableServices } = await mediator.call('apiFetch', servicesQuery, {
						filter: {
							regionId: selectedResidence.regionId,
						},
					})

					await ractive.set({
						availableServices: availableServices?.data,
						selectedServiceId: availableServices?.data?.[0]?.id || null,
					})

					await ractive.getSelectedResidenceAndServiceInfo(selectedResidence, availableServices?.data?.[0])
				} else {
					await ractive.set({
						availableServices: [],
						selectedServiceId: null,
						unansweredResidenceQuestions: [],
						selectedResidenceAnswerIds: [],
						errorMessage: 'Please add an address to see your price.',
						selectedResidenceExistingAnswerIds: [],
						schedulePeriods: [],
						selectedRecurringSchedulePeriodId: null,
					})
				}
			}, { init: false }) //Don't load it on init, resolve function will handle it

			const { cancel: cancelAllQuestionsAnsweredObserver } = ractive.observe('allQuestionsAnswered', allQuestionsAnswered => {
				// check whether all questions have been answered, if yes, get the quote
				// if not, don't get the quote and reset the error message, price quote and coupon entry
				if (allQuestionsAnswered) {
					ractive.set({ errorMessage: '' })
					ractive.getPriceQuote()
				} else {
					ractive.set({
						errorMessage: 'Please answer all questions before continuing.',
						orderPrice: klona(defaultOrderPriceState),
					})
					ractive.clearCouponEntry()
				}
			}, { init: false })

			const { cancel: cancelRecurringScheduleOptionObserver } = ractive.observe('scheduleSelection selectedRecurringSchedulePeriodId', () => {
				if (ractive.get('allQuestionsAnswered')) {
					ractive.set({ errorMessage: '' })
					ractive.clearCouponEntry()
					ractive.getPriceQuote()
				}
			}, { init: false })

			const { cancel: selectedAddOnAnswerIdsObserver } = ractive.observe('selectedAddOnAnswerIds', () => {
				ractive.clearCouponEntry()
				ractive.getPriceQuote()
			}, { init: false })

			ractive.on('openTermsConditionModal', () => {
				ractive.set('termsConditionModal.show', true)
			})

			// ractive.on('newOrderTokenError', (_context, error) => {
			// 	if (error && Array.isArray(error?.reasons)) {
			// 		const errorMessages = error.reasons.map(({ message }) => message).join('\n\n')
			// 		alert(errorMessages)
			// 	} else {
			// 		alert('There was an error processing your card. Please contact LawnHiro support.')
			// 	}
			// })

			// ractive.on('newOrderTokenSuccess', async(_context, cardInfo) => {
			// 	await ractive.addCardToWallet(cardInfo)
			// 	// const addCardToCustomerWallet = await ractive.addCardToWallet(cardInfo)

			// 	// if (addCardToCustomerWallet) {
			// 	// 	await ractive.createOrder(cardInfo, addCardToCustomerWallet.id)
			// 	// }
			// })

			ractive.on('editCardInWalletTokenSuccess', async(_context, cardInfo) => {
				if (cardInfo.cardType === 'amex') {
					alert('American Express cards are not currently supported. Please use a Visa, Mastercard, or Discover card.')
				} else {
					await ractive.updateCardInWallet(cardInfo)
				}
			})

			ractive.on('editCardInWalletTokenError', (_context, error) => {
				alert(error?.message || 'An unknown error occurred while editing your card in your wallet. Please make sure the information is valid and try again. If the problem persists, please contact Lawnhiro support.')
			})

			ractive.on('editCard', (_context, _event, cardId) => {
				ractive.openUpdateCreditCardModal(cardId)
			})

			ractive.on('newOrderTokenSuccess', async(_context, cardInfo) => {
				if (cardInfo.cardType === 'amex') {
					alert('American Express cards are not currently supported. Please use a Visa, Mastercard, or Discover card.')
				} else {
					const savedCard = await ractive.addCardToWallet(cardInfo)
					if (savedCard) {
						ractive.set({ selectedCardId: savedCard.id })
						await ractive.createOrder(cardInfo, savedCard.id)
					}
				}
				// const savedCard = await ractive.addCardToWallet(cardInfo)
				// if (savedCard) {
				// 	ractive.set({ selectedCardId: savedCard.id })
				// }
			})

			ractive.on('newOrderTokenError', (_context, error) => {
				alert(error?.message || 'An unknown error occurred while adding your card to your wallet. Please try again later.')
			})

			// ractive.on('deleteCard', async(_context, event, cardId) => {
			// 	event?.stopPropagation()

			// 	if (confirm('Are you sure you want to remove this card from your account?')) {
			// 		const deactivateCardInCustomerWalletMutation = `#graphql
			// 		mutation DeactivateCardInCustomerWallet($deactivateCardInCustomerWalletId: PositiveInt!) {
			// 			deactivateCardInCustomerWallet(id: $deactivateCardInCustomerWalletId) {
			// 				id
			// 			}
			// 		}
			// 	`

			// 		const { deactivateCardInCustomerWallet } = await mediator.call('apiFetch', deactivateCardInCustomerWalletMutation, { deactivateCardInCustomerWalletId: cardId })
			// 		const cardIndex = ractive.get('cards').findIndex(card => card.id === deactivateCardInCustomerWallet.id)
			// 		ractive.splice('cards', cardIndex, 1)
			// 		ractive.set({ selectedCardId: ractive.get('cards')?.[0]?.id || null })
			// 	}
			// })

			activateContext.on('destroy', () => {
				cancelSelectedServiceIdObserver()
				cancelSelectedResidenceIdObserver()
				selectedAddOnAnswerIdsObserver()
				cancelAllQuestionsAnsweredObserver()
				cancelRecurringScheduleOptionObserver()
			})
		},
	})
}
